/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useToggle from '../../Components/_utils/useToggle'
import Button from '../../Components/base/button/button'
import { Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from '../../Components/base/modal/modal'
import RequestForMeetingReviewCard from './RequestForMeetingReviewCard'

interface _RFMReviewsProps {
  closeModal: (e: 'YES' | "CLOSE") => void
  reviews: any
}

/**
 * A modal component for displaying reviews of a meeting.
 *
 * @param {object} props - The component props.
 * @param {function} props.closeModal - A callback function to close the modal.
 * @param {any} props.reviews - The reviews to display.
 * @return {JSX.Element} The JSX element representing the reviews modal.
 */
export default function RequestForMeetingReviews(props: _RFMReviewsProps) {
  const { closeModal, reviews } = props
  const { toggle } = useToggle()
  const { t } = useTranslation()
  const contentRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(false)

  /**
   * Handles the scroll event for the reviews container.
   * If the reviews container overflows and the user has scrolled down,
   * it shows a "Back to top" button.
   */
  const handleScroll = () => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const visibleHeight = contentRef.current.clientHeight;
      const scrollTop = contentRef.current.scrollTop;
      const shouldShowButton = contentHeight > visibleHeight && scrollTop > 0;
      setShowScrollButton(shouldShowButton);
    }
  };

  /**
   * Handles the button click event for the "Back to top" button.
   * Scrolls the reviews container to the top.
   */
  const handleButtonClick = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const contentCurrent = contentRef.current

    if (contentCurrent) {
      contentCurrent.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (contentCurrent) {
        contentCurrent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      closeOnClickOutside={false}
      animate={true}
      position='large'>
      <ModalCloseButton toggle={() => closeModal('YES')} />
      <ModalHeader keyId='titleReview'>
        <div className="text-[1.3rem] flex-1 font-medium">
          {t('reviews')}
        </div>
      </ModalHeader>
      <hr />
      <div ref={contentRef} className='max-h-[40rem] min-h-[10rem] w-full overflow-y-auto pl-2 mb-2'>
        <ModalBody>
          {React.Children.toArray(reviews?.map((review: any, index: number) =>
            <>
              <RequestForMeetingReviewCard
                review={review}
              />
              {index === reviews.length - 1 ? "" : <hr className='mr-4' />}
            </>
          ))}
        </ModalBody>
        <ModalFooter>
          {showScrollButton && (
            <Button id="btn_scrollToTop" data-testid="btn_scrollToTop" className='normal-case font-normal text-sm' onClick={handleButtonClick} size="md">
              {t('scrollToTop')}
            </Button>
          )}
        </ModalFooter>
      </div>
    </Modal>
  )
}
