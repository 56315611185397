/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { Menu, Transition } from '@headlessui/react'
import React from 'react'
import { useControlBarAccessHooks, useMuteAllHooks, useUnMuteAllHooks } from '../livekitHooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { ToolTip } from 'base/tooltip/tooltip'
import { useTranslation } from 'react-i18next'

/**
 * A customizable settings menu component that allows users to access various settings options.
 *
 * The component is only visible to hosts or co-hosts in the room and provides options to mute or unmute all participants.
 *
 * @return {JSX.Element|null} The settings menu component or null if the user is not a host or co-host.
 */

export default function CustomSettingsMenu() {
  const menuItemStyle = 'hover:bg-gray-200 px-2 py-1 rounded-md w-full text-left'
  const { muteAll } = useMuteAllHooks()
  const { unmuteAll } = useUnMuteAllHooks()
  const { isHostOrCoHostAvailableRoom } = useControlBarAccessHooks()
  const { t } = useTranslation()

  if (!isHostOrCoHostAvailableRoom) {
    return null
  }

  return (
    <Menu>
      <Menu.Button>
        <ToolTip tip={t('settings')}>
          <button
            id="btn_settings"
            className="py-3 px-4 rounded-full border font-medium bg-gray-200 text-gray-700  hover:bg-gray-50 transition-all text-sm"
          >
            {/* <Icon icon="SETTINGS" /> */}
            <FontAwesomeIcon icon={faGear} className="text-gray-500" />
          </button>
        </ToolTip>
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute bottom-14 z-50">
          <div className="flex flex-col gap-y-1 bg-white border border-gray-200 rounded-md p-2 text-black items-start justify-start">
            <Menu.Item>
              <button onClick={muteAll} className={menuItemStyle} id="btn_muteAll">
                {t('muteAll')}
              </button>
            </Menu.Item>
            <Menu.Item>
              <button onClick={unmuteAll} className={menuItemStyle} id="btn_unMuteAll">
                {t('unmuteAll')}
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
