/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {RefundCategory} from '../models/refundCategory.model';
import {allApis} from './allApis';

/**
 * Defines the endpoints for refund category API.
 *
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ getAllRefundCategory: query }} - An object containing the defined endpoints.
 */

export const refundCategoryApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getAllRefundCategory: builder.query<RefundCategory[], void>({
      query: () => 'refundCategory',
      // providesTags: ['Category']
    }),
  }),
});
export const {useGetAllRefundCategoryQuery} = refundCategoryApi;
