/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {
  WebCommandTypes,
  WebCommandMessage,
  Message,
  MessageType,
} from './socketTypes';
import {socketClient} from './socketio';

/**
 * Sends a web command message to the server via the socket client.
 *
 * @param {WebCommandTypes} type - The type of web command to send.
 * @param {string} fromUserId - The ID of the user sending the command.
 * @param {string} [toUserId] - The ID of the user receiving the command (optional).
 * @return {void}
 */

export const SendWebCommand = ({
  type,
  fromUserId,
  toUserId,
}: {
  type: WebCommandTypes;
  fromUserId: string;
  toUserId?: string;
}) => {
  const message = {} as Message;
  const webCommand = {} as WebCommandMessage;

  webCommand.fromUserId = fromUserId;
  webCommand.toUserId = toUserId;
  webCommand.type = type;

  message.message = webCommand;
  message.type = MessageType.WebCommand;

  socketClient.send(message);
};
