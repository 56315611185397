/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { Amplify } from 'aws-amplify';

/**
 * Configures AWS Amplify with the provided Cognito keys and link data.
 *
 * @param {any} cognitoKeys - An object containing Cognito configuration keys.
 * @param {string} linkData - A string used to construct redirect URLs.
 * @return {void}
 */

export function configureAWS(cognitoKeys: any, linkData: string, isSSOLogin?: boolean): Promise<void> {

  // Normal signup and Normal login and SSO signup
  const normalLoginScopes = ['phone', 'email', 'profile', 'openid'];

  // sso login(for signup user not created in Cognito)
  const ssoLoginScopes = [
    'phone',
    'email',
    'profile',
    'openid',
    'aws.cognito.signin.user.admin',
  ];

  Amplify.configure({
    Auth: {
      Cognito: {
        identityPoolId: cognitoKeys.identityPoolId,
        allowGuestAccess: true,

        userPoolClientId: cognitoKeys.userPoolClientId,
        userPoolId: cognitoKeys.userPoolId,

        loginWith: {
          oauth: {
            domain: cognitoKeys.domain,
            scopes: isSSOLogin ? ssoLoginScopes : normalLoginScopes,
            redirectSignIn: ['beemg://auth/login/'],
            redirectSignOut: ['beemg://auth/logout/'],
            responseType: 'token',
            providers: ['Google'],
          },
        },
      },
    },
  });

  return Promise.resolve();
}
