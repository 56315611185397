/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { PageTitle } from '../Components/pageTitle'
import { getCurrentUser } from '../Services/userReducer'
import { useAppSelector } from '../Store/hooks'
import Footer from './Footer'
import Header from './Header'

/**
 * A page component for the About page.
 *
 * This page displays information about BeeMG, its mission, and its purpose.
 *
 * The page includes a title, a description of the platform, its features, and a call to action to join.
 *
 * If the user is not logged in, the page also includes a footer with links to the login and sign up pages.
 *
 * @returns The About page component.
 */
function About() {

  const currentUser = useAppSelector(getCurrentUser)

  return (
    <div className='flex flex-col min-h-screen'>
      {!currentUser && <Header />}
      <div className="flex-grow">
        <PageTitle title='About BeeMG'>
          <div>
            <div className="px-5 pt-4">
              <div className="pl-5">
                <div className="pr-0 text-justify">
                Introducing BeeMG, an interactive Creator Economy platform that bridges the 
                gap between Creators and Collaborators for live audio and video events. With BeeMG, 
                Creators (hosts) can curate engaging experiences, discussions, and interactions for their 
                Collaborators (guests), who can subscribe for one-time access or through periodic fees. 
                </div>
              </div>
              <br></br>
              <div className="pl-5">
                <div className="pr-0 text-justify">
                Empowering Creators with essential tools, monetization options, and algorithms, 
                BeeMG facilitates meaningful connections with their audience. By leveraging the
                 platform's reach, Creators can foster strong communities and make a positive
                  global impact. Collaborations can manifest in various forms like joint projects, 
                  cross-promotions, sponsored content, or co-branded initiatives, opening doors to 
                  endless creative possibilities. 
                </div>
              </div>
              <br></br>
              <div className="pl-5">
                <div data-testid="txt_update" className="text-justify">
               
                Join BeeMG today to revolutionize the way Creators and Collaborators discover, 
                unite, collaborate, and create impactful live content together.
              
                </div>
              </div>
              <br></br>
            </div>
          </div>
        </PageTitle>
      </div>
      {!currentUser &&
        <div className="fixed bottom-0 w-full">
          <Footer />
        </div>
      }
    </div>
  );
}
export default About
