/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * Handles scrolling to the top of the page on route change.
 *
 * Scrolling to top is prevented if the new route is within the same page.
 * For example, if the user navigates from /s/events/my-events/1 to /s/events/my-events/2,
 * the page will not scroll to the top.
 *
 * Note that this hook uses setTimeout to wait for the browser to finish rendering
 * the new page before scrolling to the top. This is to prevent the page from
 * jumping to the top before the user can see the new content.
 *
 * @returns {JSX.Element} An empty JSX element.
 */
export default function ScrollToTop() {
  const [previousLocation, setPreviousLocation] = useState<string>();
  const { pathname } = useLocation();
  useEffect(() => {
    if (previousLocation !== pathname) {
      setTimeout(() => {
        if (pathname.includes("/s/events/my-events/") && previousLocation?.includes("/s/events/my-events/")) {
        } else {
          window.scrollTo(0, 0);
        }
        setPreviousLocation(pathname);
      }, 300);
    }
  }, [pathname]);

  return <></>;
}
