/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useSelector} from 'react-redux'
import Field from '../../Components/base/field/field'
import {getCurrentUser} from '../../Services/userReducer'
import {useNavigate} from 'react-router-dom'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from '../../Components'
import Avatar from '../../Components/base/avatar/avatar'
import { join } from 'path'

/**
 * A modal component that allows users to toggle their anonymous status.
 * It uses React hooks and Redux state to handle the anonymous toggle functionality.
 * 
 * @param {function} onToggleAnonymous - Callback function to handle toggle state changes.
 */
export const AnonymousToggleModal = forwardRef(({ onToggleAnonymous }:{ onToggleAnonymous: any}, ref) => {
  const currentUser = useSelector(getCurrentUser)
  const navigate = useNavigate()
  const {t} = useTranslation()
  

  const [joinAnonymous, setJoinAnonymous] = useState<boolean>( 
    currentUser?.anonymous?true:false,
  )
  
  useImperativeHandle(ref, () => ({  //Using this to reset the state of the component from the RFM(Parent component)
    resetState: () => {
      setJoinAnonymous(currentUser?.anonymous ? true : false);
    },
  }), [currentUser]);

  useEffect(() => {
    onToggleAnonymous(joinAnonymous) //Using this to send the state of the component to the RFM(Parent component)
  }, [joinAnonymous])

  return (
    <div className="flex flex-row mt-4 items-center text-sm font-normal text-gray-600">
      <Field
        type="checkbox"
        name="onlineList"
        id="input_onlineList"
        data-testid="btn_anonymousToggle"
        defaultChecked={joinAnonymous}
        onChange={(e: any) => {
          setJoinAnonymous(e.target.checked)
        }}
        key={joinAnonymous ? 'checked' : 'unchecked'}// Used this to rerender the component
      />

      <label
        htmlFor="input_onlineList"
        id="chk_otherPeopleList"
        data-testid="btn_JoinAnonymous">
        {t('joinAnonymous')}
      </label>
      
      <div className="flex ml-4">
        {!joinAnonymous && <Avatar size="x-small" images={currentUser.image} />}
        {joinAnonymous && (
          <Avatar size="x-small" imageURL={currentUser.avatar} />
        )}
        {joinAnonymous && !currentUser.anonymous && (
          <>
            <div className="ml-8 md:ml-0 mt-1.5">
              <Button
                size="sm"
                rounded
                color="join"
                onClick={() => navigate(`/s/profile`,{state:{isAnon:true}})}>
                <p
                  id="btn_anonymousProfile"
                  data-testid="btn_anonymousProfile"
                  className="text-xs md:text-sm ">
                  {t('updateAnonymousProfile')}
                </p>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
})
