/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { FocusToggle, ParticipantContextIfNeeded, useEnsureTrackRef } from '@livekit/components-react'
import React from 'react'
import { CustomAudioParticipantPlaceholder } from './CustomAudioParticipantPlaceholder'
import type { TrackReferenceOrPlaceholder } from '@livekit/components-core'
import { Participant, Track, TrackPublication } from 'livekit-client'

interface ParticipantTileProps {
  participant: Participant | undefined
  trackRef: TrackReferenceOrPlaceholder | undefined
  children?: React.ReactNode
  source?: Track.Source
  publication: TrackPublication | undefined
  isModerator: boolean
}

/**
 * A React component that represents an individual participant placeholder in a meeting.
 * It wraps the participant's content with a ParticipantContext and a FocusToggle.
 *
 * @param {ParticipantTileProps} props - The component props.
 * @param {TrackReferenceOrPlaceholder | undefined} props.trackRef - The track reference of the participant.
 * @param {React.ReactNode | undefined} props.children - The children elements to be rendered inside the participant placeholder.
 * @param {boolean} props.isModerator - Whether the participant is a moderator.
 * @return {JSX.Element} The rendered participant placeholder element.
 */

export default function IndividualParticipantPlaceholder(props: ParticipantTileProps) {
  const { trackRef, children, isModerator } = props
  const trackReference = useEnsureTrackRef(trackRef)

  return (
    <>
      <ParticipantContextIfNeeded participant={trackReference.participant}>
        {children ?? (
          <>
            <CustomAudioParticipantPlaceholder isModerator={isModerator} />
          </>
        )}
        <FocusToggle />
      </ParticipantContextIfNeeded>
    </>
  )
}
