/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import * as React from 'react'

type CustomGridContextTypes = {
  pinnedParticipants: string[]
  setPinnedParticipants: React.Dispatch<React.SetStateAction<string[]>>

  addPinnedParticipant: (pinnedParticipant: string[]) => void
  removePinnedParticipant: (pinnedParticipant: string) => void
  isPinnedParticipant: (pinnedParticipant: string) => boolean
  clearPinnedParticipants: () => void
}

/** @public */
export const CustomGridContext = React.createContext<CustomGridContextTypes | undefined>(undefined)

/**
 * Ensures that a CustomGridContext is provided via context.
 * If no context is provided, an error is thrown.
 *
 * @return {CustomGridContextTypes | undefined} The CustomGridContext if it exists, otherwise undefined.
 */

export function useCustomGridContext() {
  const ctx = React.useContext(CustomGridContext)
  if (!ctx) {
    throw Error('tried to access room context outside of livekit room component')
  }
  return ctx
}

/**
 * Returns the CustomGridContext if it exists, otherwise undefined.
 *
 * @return {CustomGridContextTypes | undefined} The CustomGridContext if it exists, otherwise undefined.
 */

export function useMaybeCustomGridContext() {
  return React.useContext(CustomGridContext)
}

/**
 * Creates a CustomGridContextProvider component that wraps its children with a CustomGridContext.
 * The CustomGridContext provides a state and functions to manage a list of pinned participants.
 *
 * @param {React.PropsWithChildren<{}>} props - The props for the provider, including the children to be wrapped.
 * @return {JSX.Element} The wrapped children with the CustomGridContext.
 */

export function CustomGridContextProvider({ children, ...props }: React.PropsWithChildren<{}>) {
  // const ctx = useCustomGridContext();
  const [pinnedParticipants, setPinnedParticipants] = React.useState<string[]>([])

  const addPinnedParticipant = (pinnedParticipant: string[]) => {
    // if (!pinnedParticipants.includes(pinnedParticipant)) {
    setPinnedParticipants([...pinnedParticipants, ...pinnedParticipant])
    // }
  }

  const removePinnedParticipant = (pinnedParticipant: string) => {
    setPinnedParticipants(pinnedParticipants.filter((p) => p !== pinnedParticipant))
  }

  // TODO remove this code
  const isPinnedParticipant = (pinnedParticipant: string) => {
    return pinnedParticipants.includes(pinnedParticipant)
  }

  const clearPinnedParticipants = () => {
    setPinnedParticipants([])
  }

  return (
    <CustomGridContext.Provider
      value={{
        pinnedParticipants,
        setPinnedParticipants,
        addPinnedParticipant,
        removePinnedParticipant,
        isPinnedParticipant,
        clearPinnedParticipants,
      }}
    >
      {children}
    </CustomGridContext.Provider>
  )
}
