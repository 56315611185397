/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../Store';
import {UserStatusMessage} from '../API/socketTypes';

interface OnlineUsersStateType {
  onlineUsers: UserStatusMessage[];
}

const initialStateValue: OnlineUsersStateType = {
  onlineUsers: [],
};

/**
 * Updates the list of online users in the state.
 *
 * @param {OnlineUsersStateType} state - The current state of online users.
 * @param {object} action - The action containing the user data to update.
 * @param {string} action.payload.userId - The ID of the user to update.
 * @param {boolean} action.payload.isOnline - The online status of the user.
 * @param {string} action.payload.lastSeen - The last seen timestamp of the user.
 *
 * @return {void}
 */

export const onlineUserSlice = createSlice({
  name: 'onlineUsers',
  initialState: initialStateValue,
  reducers: {
    resetOnlineUsers: () => initialStateValue,
    updateOnlineUsers: (state, action) => {
      const isExists = !!state.onlineUsers.find(
        value => value.userId === action.payload.userId,
      );

      if (!isExists) {
        state.onlineUsers.push(action.payload);
        return;
      }

      state.onlineUsers.forEach(value => {
        if (value.userId === action.payload.userId) {
          value.isOnline = action.payload?.isOnline;
          value.lastSeen = action.payload?.lastSeen;
        }
      });
    },
  },
});

export const getOnlineUsers = (state: RootState): UserStatusMessage[] =>
  state.onlineUsers.onlineUsers;
export const {resetOnlineUsers, updateOnlineUsers} = onlineUserSlice.actions;

export default onlineUserSlice.reducer;
