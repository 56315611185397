/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {format} from 'date-fns'
import {enGB} from 'date-fns/locale'

/**
 * Displays a formatted date.
 *
 * @param {{ displayDate: string | Date, dateFormat?: string }} props
 * @prop {string | Date} displayDate The date to format.
 * @prop {string} [dateFormat='MMM dd, yyyy'] The format of the date.
 * @returns {JSX.Element} A JSX element displaying the formatted date.
 */
export function DisplayFormattedDate({
  displayDate,
  dateFormat = 'MMM dd, yyyy',
}: {
  displayDate: string | Date
  dateFormat?: string
}) {
  // const { i18n } = useTranslation()
  const displayDateObj = new Date(displayDate)
  // const localeValue = i18n.language === 'en' ? enGB : ja;
  const localeValue = enGB
  return (
    <>
      {format(displayDateObj, dateFormat, {
        locale: localeValue
      })}
    </>
  )
}

/**
 * Displays a formatted month.
 *
 * @param {{ displayDate: string | Date, monthFormat?: string }} props
 * @prop {string | Date} displayDate The date to format.
 * @prop {string} [monthFormat='MMM'] The format of the month.
 * @returns {JSX.Element} A JSX element displaying the formatted month.
 */
export function DisplayFormattedMonth({
  displayDate,
  monthFormat = 'MMM',
}: {
  displayDate: string | Date;
  monthFormat?: string;
}) {
  // const { i18n } = useTranslation();
  const displayDateObj = new Date(displayDate);
   // const languageLocale = i18n.language === 'en' ? enGB : ja;
   const languageLocale = enGB;
  return (
    <>
      {format(displayDateObj, monthFormat, {
        locale: languageLocale,
      })}
    </>
  );
}

/**
 * Displays a formatted time.
 *
 * @param {{ displayFromTime: string, displayToTime?: string }} props
 * @prop {string} displayFromTime The time to format.
 * @prop {string} [displayToTime] The end time to format (if different from `displayFromTime`).
 * @returns {JSX.Element} A JSX element displaying the formatted time.
 */
export function DisplayFormattedTime({
  displayFromTime,
  displayToTime,
}: {
  displayFromTime: string
  displayToTime?: string
}) {
  // const { i18n } = useTranslation()
  const displayFromTimeObj = new Date(displayFromTime)
  const displayToTimeObj = displayToTime ? new Date(displayToTime) : null
 // const languageLocale = i18n.language === 'en' ? enGB : ja;
 const languageLocale = enGB;
  return (
    <>
      {format(displayFromTimeObj, 'h:mm a', {
        locale: languageLocale,
      })}
      {displayToTimeObj && (
        <>
          {' - '}
          {format(displayToTimeObj, 'h:mm a', {
            locale: languageLocale,
          })}
        </>
      )}
    </>
  )
}

/**
 * Displays a formatted date and time.
 *
 * @param {{ displayDate: string | Date, dateFormat?: string, displayFromTime: string, displayToTime?: string }} props
 * @prop {string | Date} displayDate The date to format.
 * @prop {string} [dateFormat='MMM dd, yyyy'] The format of the date.
 * @prop {string} displayFromTime The time to format.
 * @prop {string} [displayToTime] The end time to format (if different from `displayFromTime`).
 * @returns {JSX.Element} A JSX element displaying the formatted date and time.
 */
export function DisplayFormattedDateAndTime({
  displayDate,
  dateFormat = 'MMM dd, yyyy',
  displayFromTime,
  displayToTime,
}: {
  displayDate: string | Date
  dateFormat?: string
  displayFromTime: string
  displayToTime?: string
}) {
  // const { i18n } = useTranslation()
  const displayDateObj = new Date(displayDate)
  const displayFromTimeObj = new Date(displayFromTime)
  const displayToTimeObj = displayToTime ? new Date(displayToTime) : null
 // const languageLocale = i18n.language === 'en' ? enGB : ja;
 const languageLocale = enGB;
  return (
    <>
      {format(displayFromTimeObj, 'h:mm a', {
        locale: languageLocale,
      })}
      {displayToTimeObj && (
        <>
          {' - '}
          {format(displayToTimeObj, 'h:mm a', {
            locale: languageLocale,
          })}
        </>
      )}{' '}
      {format(displayDateObj, dateFormat, {
        locale: languageLocale,
      })}
    </>
  )
}

/**
 * Formats a date and time according to the given formats.
 *
 * @param {Date} dateTime The date and time to format.
 * @param {string} dateFormat The format of the date.
 * @param {string} timeFormat The format of the time.
 * @returns {string} A string containing the formatted date and time, separated by a space.
 */
export function FormatDateAndTime(
  dateTime: Date,
  dateFormat: string,
  timeFormat: string,
): string {
  // const { i18n } = useTranslation()
   // const languageLocale = i18n.language === 'en' ? enGB : ja;
  const languageLocale = enGB;
  const formattedDate = format(dateTime, dateFormat, {
    locale: languageLocale,
  })
  const formattedTime = format(dateTime, timeFormat, {
    locale: languageLocale,
  })

  return `${formattedDate} ${formattedTime}`
}
