/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useToggle from '../../Components/_utils/useToggle'
import { AccordionBorder, AccordionItem } from '../../Components/base/accordion/accordion'
import Button from '../../Components/base/button/button'
import NewIcon from '../../Components/base/icon/newIcons'
import { Modal, ModalBody, ModalFooter } from '../../Components/base/modal/modal'
import { toastSuccess } from '../../Components/toast'
import { useAttendingFreeEventHooks, useAttendingHooks, useMeetingJoinHooks } from '../../Hooks/AttendingHooks'
import { Meeting } from '../../models/meeting.model'
import CardImagePlaceHolder from '../EventsCard/CardImagePlaceHolder'
import CardTitle from '../EventsCard/CardTitle'
import { DisplayEventTypeIcons } from '../EventsCard/DisplayEventTypeIcons'
import { EventCard } from '../EventsCard/EventCard'
import TextLink from '../EventsCard/TextLink'
import { JoinButton } from './AttendingEventsJoinButton'
import { AttendingEventsRemainder } from './AttendingEventsRemainder'
import { StartMeetingButton } from './AttendingEventsStartMeetingButton'
import { DisplayFormattedDateAndTime } from '../../Components/formattedDateAndTime'
import { DelayDisplay } from '../../Components/DelayDisplay'

  /**
   * The FreeEvent component renders an event invitation for a free event.
   * The component renders an accordion component with an event card inside.
   * The event card contains the event title, from and to date, and icons for the event type.
   * The component also renders a button to accept or decline the event invitation.
   * The component uses the useAttendingFreeEventHooks and useMeetingJoinHooks to
   * manage the event invitation and the join time.
   * @param {Meeting} meeting - The meeting object.
   * @returns {JSX.Element} The FreeEvent component.
   */
export function FreeEvent({ meeting }: { meeting: Meeting }) {
  const { t } = useTranslation()
  const { toggle, isOpen } = useToggle()
  const { joinTime, remainderJoinTime, showStartMeeting, endedTime } = useMeetingJoinHooks({ meeting })
  const { handleFreeEventCancel, freeCancelSuccess } = useAttendingFreeEventHooks()
  const {attendingEventPageRefetch} = useAttendingHooks()
  
  const truncatedTitle = {
    meetingTitle: meeting.meetingDetails.title.length > 25 ? meeting.meetingDetails.title.substring(0, 25) + '...' : meeting.meetingDetails.title
  }

  useEffect(()=>{
    attendingEventPageRefetch()
  },[joinTime])
  
  useEffect(() => {
    if (freeCancelSuccess) {
      toastSuccess(t('cancelledSuccessfully'))
    }
  }, [freeCancelSuccess, t])

  return (
    <>
      <AccordionBorder>
        <AccordionItem toggle="panel-2" color="card">
          <EventCard keyId={meeting.id} link={`/s/meeting/${meeting.meetingDetails.id}`}>
            <EventCard.Image> <CardImagePlaceHolder images={meeting?.meetingDetails?.image} /></EventCard.Image>
            <EventCard.Title>
              <CardTitle keyId={meeting.id} key={meeting.meetingDetails.id} meetingTitle={truncatedTitle.meetingTitle} />
            </EventCard.Title>
            <EventCard.SubTitle>
               <DisplayFormattedDateAndTime displayFromTime={meeting.fromDateTime} displayToTime={meeting.toDateTime} displayDate={meeting.fromDateTime} />
               </EventCard.SubTitle>
            <EventCard.Icons><DisplayEventTypeIcons meetingDetail={meeting.meetingDetails} meeting={meeting} /></EventCard.Icons>
            <EventCard.Actions>
              <div className="grid grid-cols-6 w-full">
                <div className={`text-center justify-center items-center mx-auto col-span-5 w-20`} onClick={(e) => {
                  e.stopPropagation()
                }}><div>
                    {joinTime && !showStartMeeting && <JoinButton meeting={meeting} />}
                    {joinTime && showStartMeeting && <StartMeetingButton meeting={meeting} />}
                    <div className='w-full mr-5'>
                    {!joinTime && !endedTime && meeting.meetingParticipant[0] &&  meeting.meetingParticipant[0].isCohost !== true && <span id={`btn_cancelFreeEvent${meeting.id}`} className="text-red-500" data-testid={`btn_cancelFreeEvent${meeting.id}`} onClick={toggle}>
                      <DelayDisplay delayTime={1000}>
                        <>
                        <div className="flex flex-col items-center justify-center mx-auto px-14">
                              <NewIcon size="small" icon="CANCELEVENT" stroke='red' className="mb-1" />
                              <TextLink type="cancelRequest" className='block'>
                                {t('cancelEvent')}
                              </TextLink>
                            </div>
                        </>
                      </DelayDisplay>
                    </span>}
                    </div>
                  </div>
                </div>
                <div className='flex justify-end mx-auto'>
                  {(!joinTime || remainderJoinTime) && <AttendingEventsRemainder meeting={meeting} />}
                </div>
              </div>
            </EventCard.Actions>
          </EventCard>
        </AccordionItem>
      </AccordionBorder>
      <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
        <ModalBody>
          <span className="flex flex-col pl-2">
            <span className="text-2xl font-medium">
              {' '}
              {t('freeEventCancellation')}
            </span>
            <span className="py-2 pb-14">{meeting.meetingDetails.title}</span>
          </span>
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid="btn_ok"
            id="btn_ok"
            onClick={e => {
              toggle(e)
              handleFreeEventCancel(meeting.meetingParticipant[0].id)
            }}
            color="footerButton" autoFocus>
            {t(`cancelEvent`)}
          </Button>
            <Button id="btn_cancel" onClick={toggle} color="footerButton">{t(`close`)}</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
