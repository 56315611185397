/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import {
  IconDefinition,
  faArrowUp,
  faMicrophone,
  faMicrophoneSlash,
  faPause,
  faVideo,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { CaptureOptionsBySource, ToggleSource } from '@livekit/components-core'
import { useTrackToggle } from '@livekit/components-react'
import { Track } from 'livekit-client'
import React, { useEffect, useState } from 'react'
import {
  useGetIsLocalParticipantHostOrCohostHooks,
  useMuteAllHooks,
  usePermissionCheckHooks,
  useUnMuteAllHooks,
} from '../livekitHooks'
import { useCustomRoomContext } from '../room/CustomRoomContext'
import { ToolTip } from 'base/tooltip/tooltip'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { MeetingType } from 'Models/meeting.model'

/** @public */
export interface TrackToggleProps<T extends ToggleSource>
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onChange'> {
  source: T
  initialState?: boolean
  onChange?: (enabled: boolean) => void
  captureOptions?: CaptureOptionsBySource<T>
  isPipEnabled?: boolean
  meetingType: MeetingType
}

/**
 * Renders an icon with customizable styles and tooltip.
 *
 * @param {IconDefinition} icon - The icon to be displayed.
 * @param {boolean} isAllowed - Whether the icon is allowed or not.
 * @param {boolean} [isUnmute] - Whether the icon is in an unmute state.
 * @param {string} [tipName] - The tooltip name for the icon.
 * @param {boolean} [isPipEnabled] - Whether Picture-in-Picture is enabled.
 * @param {string} [id] - The ID of the icon.
 * @return {JSX.Element} The rendered icon component.
 */

export function DisplayIcon({
  icon,
  isAllowed,
  isUnmute,
  tipName,
  isPipEnabled,
  id,
}: {
  icon: IconDefinition
  isAllowed: boolean
  isUnmute?: boolean
  tipName?: string
  isPipEnabled?: boolean
  id?: string
}) {
  const getIconClasses = () => {
    if (icon === faArrowUp || icon === faPause) {
      return 'border-2 border-gray-500 p-1'
    }
    return ''
  }

  return (
    <>
      {icon && (
        <div
          className={`h-5 ${getIconClasses()} ${!isPipEnabled && tipName ? '' : 'p-0'} ${
            icon === faArrowUp || icon === faPause ? 'w-6 h-5 flex justify-center items-center' : ''
          }`}
        >
          {!isPipEnabled && tipName ? (
            <FontAwesomeIcon
              id={id}
              className={`inline-block ${
                icon === faArrowUp
                  ? 'text-gray-500 text-xs'
                  : isUnmute && !isAllowed
                  ? 'text-gray-600-700'
                  : isUnmute && isAllowed
                  ? 'text-black'
                  : !isUnmute && !isAllowed
                  ? 'text-gray-300'
                  : ''
              }`}
              icon={icon}
            />
          ) : (
            <FontAwesomeIcon
              id={id}
              className={`inline-block ${
                icon === faArrowUp
                  ? 'text-gray-500 text-xs'
                  : isUnmute && !isAllowed
                  ? 'text-gray-600-700'
                  : isUnmute && isAllowed
                  ? 'text-black'
                  : !isUnmute && !isAllowed
                  ? 'text-gray-300'
                  : 'text-yellow-500'
              }`}
              icon={icon}
            />
          )}
        </div>
      )}
    </>
  )
}

export function getSourceIcon(
  source: Track.Source,
  enabled: boolean,
  isUnmuteAllowed?: boolean,
  isPipEnabled?: boolean
) {
  switch (source) {
    case Track.Source.Microphone:
      return enabled ? (
        <>
          {' '}
          {
            <DisplayIcon
              id="btn_microphone"
              icon={faMicrophone}
              isAllowed={enabled}
              isUnmute={isUnmuteAllowed ?? false}
              tipName={t('mic')}
              isPipEnabled={isPipEnabled}
            />
          }
        </>
      ) : (
        <>
          {' '}
          {
            <DisplayIcon
              id="btn_microphone-slash"
              icon={faMicrophoneSlash}
              isAllowed={enabled}
              isUnmute={isUnmuteAllowed ?? false}
              tipName={t('mic')}
              isPipEnabled={isPipEnabled}
            />
          }
        </>
      )
    case Track.Source.Camera:
      return enabled ? (
        <>
          {' '}
          {
            <DisplayIcon
              id="btn_video"
              icon={faVideo}
              isAllowed={enabled}
              isUnmute={isUnmuteAllowed ?? false}
              tipName={t('camera')}
              isPipEnabled={isPipEnabled}
            />
          }
        </>
      ) : (
        <>
          {' '}
          {
            <DisplayIcon
              id="btn_video-slash"
              icon={faVideoSlash}
              isAllowed={enabled}
              isUnmute={isUnmuteAllowed ?? false}
              tipName={t('camera')}
              isPipEnabled={isPipEnabled}
            />
          }
        </>
      )
    case Track.Source.ScreenShare:
      return enabled ? (
        <>
          {' '}
          {
            <DisplayIcon
              icon={faPause}
              isAllowed={enabled}
              isUnmute={isUnmuteAllowed ?? false}
              tipName={t('screenShare')}
              isPipEnabled={isPipEnabled}
            />
          }
        </>
      ) : (
        <>
          {' '}
          {
            <DisplayIcon
              icon={faArrowUp}
              isAllowed={enabled}
              isUnmute={isUnmuteAllowed ?? false}
              tipName={t('screenShare')}
              isPipEnabled={isPipEnabled}
            />
          }
        </>
      )
    default:
      return undefined
  }
}

/**
 * A customizable track toggle component for LiveKit.
 *
 * @param {TrackToggleProps<T>} props - The properties for the track toggle component.
 * @return {JSX.Element} The track toggle component.
 */

export function CustomTrackToggle<T extends ToggleSource>({ meetingType, ...props }: TrackToggleProps<T>) {
  const { buttonProps, enabled, toggle } = useTrackToggle(props)
  buttonProps.className = props.className ?? buttonProps.className
  const { t } = useTranslation()

  const { isLocalParticipantHost, isLocalParticipantCohost } = useGetIsLocalParticipantHostOrCohostHooks()
  const { isAudioPermissionGrantedError, isVideoPermissionGrantedError } = usePermissionCheckHooks()

  const isHostOrCohostAvailableInRoom = isLocalParticipantHost || isLocalParticipantCohost

  const { isUnmuteAllowed, onMessage, isHostOrCohostAvailable } = useCustomRoomContext()

  const [toasterMessage, setToasterMessage] = useState('')
  const { isMutedAll } = useMuteAllHooks()
  const { isUnmutedAll } = useUnMuteAllHooks()
  const trackName =
    props.source === Track.Source.Microphone
      ? 'microphone'
      : props.source === Track.Source.ScreenShare
      ? 'screenShare'
      : 'camera'

  const handleToggle = () => {
    if (
      (!isHostOrCohostAvailableInRoom && !isUnmuteAllowed && meetingType !== MeetingType.ChatVideoRequest) ||
      (isMutedAll && !isUnmutedAll)
    ) {
      if (isHostOrCohostAvailable) {
        const message =
          props.source === Track.Source.ScreenShare
            ? t('toShareYourScreenKindlyRequestTheHostToUnmuteYou')
            : t('toSpeakRaiseYourHandAndWaitForTheHostToUnmuteYou')
        setToasterMessage(message)
      }
      return
    }
    const newEnabledState = !enabled
    toggle()
    if (props.onChange) {
      props.onChange(newEnabledState)
    }

    const action = newEnabledState ? 'On' : 'Off'
    const message = `${trackName}${action}`

    setToasterMessage(message)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setToasterMessage('')
    }, 3000)

    return () => clearTimeout(timeout)
  }, [toasterMessage])

  const handleAudioVideoDisable = () => {
    if (props.source === Track.Source.Microphone) {
      return isAudioPermissionGrantedError
    }
    if (props.source === Track.Source.Camera) {
      return isVideoPermissionGrantedError
    }
    return false
  }

  return (
    <>
      <ToolTip
        tip={
          props.source === Track.Source.Microphone
            ? t('mic')
            : props.source === Track.Source.Camera
            ? t('camera')
            : t('screenShare')
        }
      >
        <button {...buttonProps} onClick={handleToggle} disabled={handleAudioVideoDisable()}>
          {getSourceIcon(props.source, enabled, isUnmuteAllowed, props.isPipEnabled)}
          {props.children}
        </button>
      </ToolTip>
      {toasterMessage && onMessage && onMessage(`${t(toasterMessage)}`, 'info')}
    </>
  )
}
