/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import defaultImage from "../../../Assets/Images/beemg-icons-creator-camera-2023.svg";
import { Image } from "../../../models/image.model";
import { useGetSettingValue } from "../../../Services/settingReducer";
import Icon from "../icon/icon";
import { useAvatarColors } from '../../../Hooks/AvatarColorHook';

/**
 * Avatar component. Can be used to display an image, a character, or nothing at all.
 *
 * @param {Object} props - Component props
 * @param {string} [props.size=xx-small] - Size of the avatar. Can be xx-small, x-small, md-small, small, medium, large, x-large, payment, audioMeeting, videoMeeting or chatScreen
 * @param {Array<Image>} [props.images] - Array of images to display
 * @param {string} [props.imageURL] - URL of the image to display
 * @param {string} [props.avatar] - URL of the avatar to display
 * @param {string} [props.displayChar] - Character to display if no image is provided
 * @param {string} [props.icon] - Icon to display over the avatar
 * @param {string} [props.position] - Position of the icon. Can be top-right, top-left, bottom-right, bottom-left or top-right-fit
 * @param {boolean} [props.outline=false] - Whether to display an outline around the avatar
 * @param {string} [props.outlineColor] - Color of the outline
 * @param {boolean} [props.anonymous=false] - Whether to display the avatar as anonymous
 * @param {string} [props.className] - Additional CSS class to apply to the avatar
 */
const Avatar = ({ size, images, imageURL, avatar, displayChar, icon, position, outline = false, outlineColor, anonymous = false,className }
  : {
    size: 'xx-small' | 'x-small' | 'md-small' | 'small' | 'medium' | 'large' | 'x-large' | 'payment' | 'audioMeeting' | 'videoMeeting' | 'chatScreen',
    images?: Image[], imageURL?: string | undefined | null, displayChar?: string | null, avatar?: string, icon?: string, position?: string, outline?: boolean, outlineColor?: string, anonymous?: boolean,className?:string
  }) => {

  const baseImageURL = useGetSettingValue('IMAGE_URL');
  const [image, setImage] = useState<string>();
  const [webpImage, setWebpImage] = useState<string>();

  const { getBackground } = useAvatarColors();

  useEffect(() => {
    if (anonymous && avatar) {
      setImage(baseImageURL + '/' + avatar);
    } else if (imageURL) {
      setImage(baseImageURL + '/' + imageURL);
    } else if (!anonymous && images && images.length > 0 && images[0]?.imageName) {
      setImage(baseImageURL + '/' + images[0].imageName);
      setWebpImage(images[0]?.webpImageName ? baseImageURL + '/' + images[0]?.webpImageName : undefined);
    } else if (anonymous && !avatar) {
      setImage(undefined);
    } else if (!anonymous && (!images || images.length === 0)) {
      setImage(undefined);
    }
  }, [baseImageURL, imageURL, images, avatar, anonymous]);

  let defaultImageSize = 'w-7 h-7' // xx-small
  let sizeWidth = 'w-7 h-7' // xx-small
  let textSize = 'text-xs'

  if (size === 'xx-small') {
    textSize = 'text-2xl';
  }
  else if (size === 'x-small') {
    defaultImageSize = 'w-10 h-10'
    sizeWidth = 'w-10 h-10'
    textSize = 'text-3xl';
  } else if (size === 'md-small') {
    sizeWidth = 'w-12 h-12';
    defaultImageSize = 'w-12 h-12'
    textSize = 'text-4xl';
  } else if (size === 'small') {
    sizeWidth = 'w-16 h-16';
    textSize = 'text-5xl';
  } else if (size === 'medium') {
    sizeWidth = 'w-20 h-20';
    defaultImageSize = 'w-16 h-16';
    textSize = 'text-6xl';
  } else if (size === 'large') {
    sizeWidth = 'w-40 h-40';
    defaultImageSize = 'w-20 h-20';
    textSize = 'text-8xl';
  } else if (size === 'x-large') {
    sizeWidth = 'w-60 h-60';
    defaultImageSize = 'w-40 h-40';
    textSize = 'text-9xl';
  } else if (size === 'payment') {
    sizeWidth = 'w-28 h-28 md:w-36 md:h-36';
    defaultImageSize = 'w-36 h-36';
    textSize = 'text-6xl';
  } else if (size === 'audioMeeting') {
    sizeWidth = 'w-28 h-28';
    defaultImageSize = 'w-20 h-20';
    textSize = 'text-5xl';
  } else if (size === 'videoMeeting') {
    sizeWidth = 'w-20 h-20 sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20 xs:w-10 xs:h-10 ';
    defaultImageSize = 'w-16 h-16';
    textSize = 'text-6xl lg:text-6xl md:text-4xl sm:text-2xl xs:text-xl';
  } else if (size === 'chatScreen') {
    sizeWidth = 'w-[4rem] h-[4rem] sm:w-12 sm:h-12 md:w-[4rem] md:h-[4rem] lg:w-[4rem] lg:h-[4rem] xs:w-10 xs:h-10 ';
    defaultImageSize = 'w-[4rem] h-[4rem]';
    textSize = 'text-3xl lg:text-4xl md:text-2xl sm:text-xl xs:text-sm';
  }


  let iconPosition = 'absolute right-0 bottom-1';
  if (position === 'top-right') {
    iconPosition = 'absolute right-0 top-0';
  } else if (position === 'top-left') {
    iconPosition = 'absolute top-0';
  } else if (position === 'bottom-right') {
    iconPosition = 'absolute right-0 bottom-0';
  } else if (position === 'bottom-left') {
    iconPosition = 'absolute bottom-0';
  } else if (position === 'top-right-fit') {
    iconPosition = 'absolute right-3- top-0';
  }

  if (!image && displayChar) {
    const bgColor = getBackground(displayChar);

    return (
      <div className="flex">
        <div className="relative">
          {outline && <div id="vUserHost" className={`object-cover rounded-full ${sizeWidth} ${outline ? `outline outline-4 ${outlineColor}` : ''} `} style={{ backgroundColor: bgColor }}>
            <div className={`flex flex-row justify-center ${sizeWidth}`}>
              <div className={`flex flex-col justify-center font-semibold text-white ${textSize}`}>
                {displayChar?.toUpperCase().charAt(0)}
              </div>
            </div>
          </div>}
          {!outline && <div className={`object-cover outline-0 rounded-full ${sizeWidth}`} style={{ backgroundColor: bgColor }}>
            <div className={`flex flex-row justify-center ${sizeWidth}`}>
              <div className={`flex flex-col justify-center font-normal text-white ${textSize}`}>
                {displayChar?.toUpperCase().charAt(0)}
              </div>
            </div>
          </div>}
          {icon && iconPosition && <span className={iconPosition}>
            <Icon icon={icon} size="small" stroke="red" />
          </span>}
        </div>
      </div>
    )
  }

  return (
    <div className="flex ">
      <div className="relative  ">
        {image && <picture>
          {webpImage && <source srcSet={webpImage} type="image/webp" />}
          <source srcSet={image} type="image/png" />
          <img src={image} alt=""
            className={`object-cover rounded-full ${sizeWidth} ${outline ? ` outline outline-4 ${outlineColor}` : ''}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              if (image) {
                setImage(undefined)
              }
            }} />
        </picture>}
        {!image &&
          <div className={`object-cover rounded-full bg-gray-200 flex justify-center items-center ${sizeWidth} ${outline ? `outline outline-4 ${outlineColor}` : ''}`}>
            <img src={defaultImage} alt="" className={`  ${defaultImageSize} ${className}`} />
          </div>
        }
        {icon && iconPosition && <span className={iconPosition}>
          <Icon icon={icon} size="small" stroke="red" />
        </span>}
      </div>
    </div>
  )
};

Avatar.defaultProps = {
  size: 'small'
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['xx-small', 'x-small', 'md-small', 'small', 'medium', 'large', 'x-large', 'payment', 'audioMeeting', 'videoMeeting', 'chatScreen']),
  imageURL: PropTypes.string,
  icon: PropTypes.string,
  position: PropTypes.oneOf(['top-right', 'top-left', 'bottom-right', 'bottom-left'])
};

export default Avatar;
