/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

// import { Suspense, lazy } from 'react'
import {range} from 'lodash'
import {Control, Controller, Path} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
// import Loading from '../loading/loading'
import ReactDatePicker from 'react-datepicker';
import { enGB, ja } from 'date-fns/locale';
import { DisplayFormattedDate } from '../../formattedDateAndTime';

// const ReactDatePicker = lazy(() => import("react-datepicker"));

type Props<T> = {
  label: string
  name: Path<T>
  error?: string | any
  control: Control<any>
  timeIntervals?: number
  minDate?: Date
  maxDate?: Date
  placeholderText?: string
}

/**
 * A form component for date picker.
 *
 * This component uses `react-datepicker` with some customizations.
 * It takes a label, a name, a control, an optional error, a minDate, a maxDate, a
 * placeholderText and an optional timeIntervals.
 *
 * The component renders a label, a date picker and an optional error message.
 * The date picker is rendered as a controlled component with the given name.
 * The component also renders a custom header for the date picker.
 *
 * The custom header renders the year as a select element and two buttons to
 * navigate to the previous and next months.
 *
 * The component also renders an optional error message.
 *
 * @param {string} label The label for the date picker.
 * @param {string} name The name of the date picker.
 * @param {Control} control The control of the date picker.
 * @param {string} [error] An optional error message.
 * @param {Date} [minDate] An optional minimum date.
 * @param {Date} [maxDate] An optional maximum date.
 * @param {string} [placeholderText] An optional placeholder text.
 * @param {number} [timeIntervals=15] An optional number of time intervals.
 * @returns {JSX.Element}
 */

export const DatePicker = <T,>({
  label,
  name,
  control,
  error,
  minDate,
  maxDate,
  placeholderText,
  timeIntervals = 15,
}: Props<T>) => {
  const {t,i18n} = useTranslation()

  // const languageLocale = i18n.language === 'en' ? enGB : ja;
  const languageLocale = enGB;

  const style = {
    error: `ring-red-500 ring-1`,
    errorMessage: `text-sm text-red-500 mt-2 w-80`,
  }

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <div>
        <Controller
          control={control}
          name={name}
          render={({field: {onChange, value}}) => (
            // <Suspense fallback={<Loading />}>
              <ReactDatePicker
                dateFormat="yyyy-MM-dd"
                locale={languageLocale}
                showTimeSelect={false}
                minDate={minDate}
                maxDate={maxDate}
                showYearDropdown
                yearDropdownItemNumber={5}
                scrollableYearDropdown
                id="input_dateOfBirthType"
                onChange={(date: any, event) =>
                  onChange(date?.toISOString().split('T')[0] ?? '')
                }
                selected={value ? new Date(value) : null}
                placeholderText={placeholderText}
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                  changeYear,
                }) => (
                  <div className="flex items-center justify-between px-2 py-2">
                    <span className="text-lg text-gray-700">
                      <DisplayFormattedDate displayDate={date} dateFormat='MMMM'/>
                    </span>
                    <select
                      className="rounded-md py-0.5 mx-2 border-gray-300 drop-shadow-sm focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent"
                      value={date.getFullYear()}
                      onChange={({target: {value}}) =>
                        changeYear(parseInt(value))
                      }>
                      {range(1920, maxDate && maxDate?.getFullYear() + 1).map(
                        option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ),
                      )}
                    </select>
                    <div className="space-x-2">
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        type="button"
                        className={`
                            ${
                              prevMonthButtonDisabled &&
                              'cursor-not-allowed opacity-50'
                            }
                            inline-flex p-1 w-6 justify-center text-sm font-medium text-gray-400 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-offset-0 focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent
                          `}>
                        {' <'}
                      </button>
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        type="button"
                        className={`
                            ${
                              nextMonthButtonDisabled &&
                              'cursor-not-allowed opacity-50'
                            }
                            inline-flex p-1 w-6 justify-center text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-offset-0 focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent
                          `}>
                        {' >'}
                      </button>
                    </div>
                  </div>
                )}
              />
            // </Suspense>
          )}
        />
      </div>
      {error && (
        <div
          role="alert"
          data-testid="error_dob"
          id="error_dob"
          className={style.errorMessage}>
          {t(error)}
        </div>
      )}
    </>
  )
}
