/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect, useMemo, useState} from 'react';
import {FieldArrayWithId, useFieldArray, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {
  formatDateToTimezone,
  formatTimeToTimezone,
} from '../../src/Utils/datetime';
import {
  useCreateMeetingRequestMutation,
  useGetCountOfSendByUserQuery,
} from '../Services/meetingRequestApi';
import {useGetByCUserCurrencyQuery} from '../Services/setting.service';
import {useGetSettingValue} from '../Services/settingReducer';
import {useGetUserDetailsQuery} from '../Services/userApi';
import {getCurrentUser, getPreference} from '../Services/userReducer';
import {meetingRequestFormValidationSchema} from '../Utils/validation';
import {MeetingRequest, RequestType} from '../models/meetingRequest.model';
import {
  PaymentAccountActive,
  PaymentAccountType,
  Verified,
} from './../models/user.model';

interface SelectedDates {
  id: string;
  startDate: string;
  endDate: string;
}

//Replace
export interface InitialReport {
  reason: any;
}

export interface RequestForMeetingForm {
  isRequestAnonymous: boolean;
  details: string;
  participants: string | number;
  cost: string | number;
  minCost?: number;
  unitPrice: string | number;
  selectedDates: SelectedDates[];
  maximumParticipants: string;
  maxDuration?: number | string;
}

/**
 * Custom hook for handling request for meeting functionality.
 * It provides methods for handling form data, submitting meeting requests, and managing errors.
 *
 * @param {object} props - An object containing optional properties: hostId, field, and index.
 * @param {string} props.hostId - The ID of the meeting host.
 * @param {FieldArrayWithId<RequestForMeetingForm, 'selectedDates', 'id'>} props.field - A field array for managing selected dates.
 * @param {number} props.index - The index of the current field array item.
 * @return {object} An object containing various properties and methods for managing the request for meeting form.
 */

export const useRequestForMeetingHooks = ({
  hostId,
  field,
  index,
}: {
  hostId?: string;
  field?: FieldArrayWithId<RequestForMeetingForm, 'selectedDates', 'id'>;
  index?: number;
}) => {
  const {t} = useTranslation();

  const [verificationError, setVerificationError] = useState<string>('');
  const [meetingExists, setMeetingExists] = useState<boolean>(false);
  const [serverErrorMessages, setServerErrorMessages] = useState<string>();
  const currentUser = useSelector(getCurrentUser);
  const preference = useSelector(getPreference);
  const MAX_PARTICIPANTS = useGetSettingValue('MAX_PARTICIPANTS');
  const RFM_SCHEDULE_DURATION = useGetSettingValue('RFM_SCHEDULE_DURATION');

  const {data: hostData, isLoading: loadingHostData} = useGetUserDetailsQuery(
    hostId ?? '',
    {skip: !hostId},
  );
  const {data: minCost, isSuccess: minCostSuccess} =
    useGetByCUserCurrencyQuery();
  const {data: requestCount, isLoading: loadingRequestCount} =
    useGetCountOfSendByUserQuery(hostId ?? '', {skip: !hostId});
  const [
    sendRequest,
    {
      isSuccess: requestSentSuccess,
      isLoading: requestSentLoading,
      isError: requestSentError,
    },
  ] = useCreateMeetingRequestMutation<any>();

  const formatDate = (fromDateTime?: string) => {
    return `${formatDateToTimezone(fromDateTime)}  `;
  };
  const formatTime = (fromDateTime?: string, toDateTime?: string) => {
    return ` ${formatTimeToTimezone(fromDateTime)} ${t(
      'to',
    ).toLowerCase()} ${formatTimeToTimezone(toDateTime)} `;
  };

  const formMethods = useForm({
    defaultValues: {} as RequestForMeetingForm,
    mode: 'onChange',
    resolver: yupResolver(meetingRequestFormValidationSchema),
  });

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    register,
    reset,
    getValues,
    trigger,
    formState: {errors, isSubmitting, isDirty, isValid},
  } = formMethods;

  const {
    fields: dateOptionsFields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    control,
    name: 'selectedDates',
  });

  const errorMessage = useMemo(() => {
    if (
      index &&
      errors &&
      errors.selectedDates &&
      (errors.selectedDates as any).length > index &&
      errors.selectedDates[index] &&
      (errors.selectedDates[index]?.startDate as any)?.message
    ) {
      return (errors.selectedDates[index]?.startDate as any)?.message;
    }
    return '';
  }, [errors, index]);

  useEffect(() => {
    setValue('maximumParticipants', MAX_PARTICIPANTS);
    setValue('minCost', minCost);
    setValue('maxDuration', RFM_SCHEDULE_DURATION);
  }, [minCost, MAX_PARTICIPANTS, RFM_SCHEDULE_DURATION]);

  useEffect(() => {
    appendOption({} as any);
  }, []);

  useEffect(() => {
    if (hostData) {
      if (
        hostData?.emailVerified === Verified.NotComplete &&
        hostData?.phoneVerified === Verified.NotComplete &&
        hostData?.faceVerified === Verified.NotComplete
      ) {
        if (
          verificationError !==
          t('emailPhoneAndFaceVerificationNotCompletedForThisUser')
        ) {
          setVerificationError(
            t('emailPhoneAndFaceVerificationNotCompletedForThisUser'),
          );
        }
      } else if (
        hostData?.emailVerified === Verified.NotComplete &&
        hostData?.phoneVerified === Verified.NotComplete &&
        hostData?.faceVerified === Verified.Complete
      ) {
        if (
          verificationError !==
          t('emailAndPhoneVerificationNotCompletedForThisUser')
        ) {
          setVerificationError(
            t('emailAndPhoneVerificationNotCompletedForThisUser'),
          );
        }
      } else if (
        hostData?.emailVerified === Verified.NotComplete &&
        hostData?.phoneVerified === Verified.Complete &&
        hostData?.faceVerified === Verified.NotComplete
      ) {
        if (
          verificationError !==
          t('emailAndFaceVerificationNotCompletedForThisUser')
        ) {
          setVerificationError(
            t('emailAndFaceVerificationNotCompletedForThisUser'),
          );
        }
      } else if (
        hostData?.emailVerified === Verified.NotComplete &&
        hostData?.phoneVerified === Verified.Complete &&
        hostData?.faceVerified === Verified.Complete
      ) {
        if (
          verificationError !== t('emailVerificationNotCompletedForThisUser')
        ) {
          setVerificationError(t('emailVerificationNotCompletedForThisUser'));
        }
      } else if (
        hostData?.emailVerified === Verified.Complete &&
        hostData?.phoneVerified === Verified.NotComplete &&
        hostData?.faceVerified === Verified.NotComplete
      ) {
        if (
          verificationError !==
          t('phoneAndFaceVerificationNotCompletedForThisUser')
        ) {
          setVerificationError(
            t('phoneAndFaceVerificationNotCompletedForThisUser'),
          );
        }
      } else if (
        hostData?.emailVerified === Verified.Complete &&
        hostData?.phoneVerified === Verified.NotComplete &&
        hostData?.faceVerified === Verified.Complete
      ) {
        if (
          verificationError !== t('phoneVerificationNotCompletedForThisUser')
        ) {
          setVerificationError(t('phoneVerificationNotCompletedForThisUser'));
        }
      } else if (
        hostData?.emailVerified === Verified.Complete &&
        hostData?.phoneVerified === Verified.Complete &&
        hostData?.faceVerified === Verified.NotComplete
      ) {
        if (
          verificationError !== t('faceVerificationNotCompletedForThisUser')
        ) {
          setVerificationError(t('faceVerificationNotCompletedForThisUser'));
        }
      } else if (
        hostData?.paymentAccountActive === PaymentAccountActive.No &&
        hostData?.paymentAccountType === PaymentAccountType.Stripe
      ) {
        if (verificationError !== t('stripeIsNotActiveForThisUser')) {
          setVerificationError(t('stripeIsNotActiveForThisUser'));
        }
      } else if (
        hostData?.paymentAccountActive === PaymentAccountActive.No &&
        hostData?.paymentAccountType !== PaymentAccountType.Stripe &&
        currentUser.id !== hostId
      ) {
        if (verificationError !== t('paymentMethodIsNotActiveForThisUser')) {
          setVerificationError(t('paymentMethodIsNotActiveForThisUser'));
        }
      } else if (
        hostData?.paymentAccountActive === PaymentAccountActive.Yes &&
        hostData?.phoneVerified === Verified.Complete &&
        hostData?.emailVerified === Verified.Complete
      ) {
        if (verificationError !== '') {
          setVerificationError('');
        }
      }
    }
  }, [hostData]);

  const resetForm = () => {
    reset({
      selectedDates: [{}],
      details: '',
      participants: '',
      unitPrice: '',
      minCost: minCost,
    });
  };

  const submit = (formValues: RequestForMeetingForm) => {
    const meetingRequest = {} as MeetingRequest;
    meetingRequest.cost =
      Number(formValues.unitPrice) * Number(formValues.participants);
    meetingRequest.participants = Number(formValues.participants);
    meetingRequest.guest = hostData ? hostData : null;
    meetingRequest.details = formValues.details;
    meetingRequest.isRequestAnonymous = formValues.isRequestAnonymous
      ? formValues.isRequestAnonymous
      : false;

    if (formValues.selectedDates.length > 0) {
      meetingRequest.startAt1DateTime = formValues.selectedDates[0]?.startDate;
      meetingRequest.endAt1DateTime = formValues.selectedDates[0]?.endDate;
    }
    if (formValues.selectedDates.length > 1) {
      meetingRequest.startAt2DateTime = formValues.selectedDates[1]?.startDate;
      meetingRequest.endAt2DateTime = formValues.selectedDates[1]?.endDate;
    }
    if (formValues.selectedDates.length > 2) {
      meetingRequest.startAt3DateTime = formValues.selectedDates[2]?.startDate;
      meetingRequest.endAt3DateTime = formValues.selectedDates[2]?.endDate;
    }

    meetingRequest.requestType = RequestType.PaidRequest;

    const args = {
      request: meetingRequest,
      guest: hostData,
    };

    sendRequest(args).then((res: any) => {
      if (res.error) {
        // console.log('error', res.error)
        setServerErrorMessages(res.error.data.message);
      }
    });
  };

  return {
    errorMessage,
    watch,
    requestSentSuccess,
    verificationError,
    loadingHostData,
    loadingRequestCount,
    minCost,
    minCostSuccess,
    requestCount,
    control,
    handleSubmit,
    setValue,
    register,
    reset,
    errors,
    submit,
    preference,
    hostData,
    setVerificationError,
    getValues,
    trigger,
    isSubmitting,
    isDirty,
    isValid,
    dateOptionsFields,
    appendOption,
    removeOption,
    formatDate,
    formatTime,
    resetForm,
    MAX_PARTICIPANTS,
    meetingExists,
    setMeetingExists,
    RFM_SCHEDULE_DURATION,
    requestSentLoading,
    requestSentError,
    serverErrorMessages,
    setServerErrorMessages,
    formMethods,
  };
};
