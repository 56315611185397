/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {Socket, io} from 'socket.io-client';
import {setSocketConnected} from '../Services/socketReducer';
import {store} from '../Store';
import addSocketListeners from './socketListeners';
import {GetUserStatusMessage, Message, MessageType} from './socketTypes';

let socket: Socket;

class SocketClient {
  messageListners: {[key: string]: (message: Message) => void} = {};
  isUserAdded = false;

  /**
   * Establishes a connection with the specified URL using Socket.IO.
   *
   * @param {string} connectUrl - The URL to connect to.
   * @return {void} This function does not return anything.
   */

  connect(connectUrl: string) {
    socket = io(connectUrl, {
      autoConnect: false,
      transports: ['websocket', 'polling'],
      path: '/socket',
    });
    socket.connect();

    socket.on('connect', () => {
      console.log('Socket Connected.');
      store.dispatch(setSocketConnected(true));
      this._setupListeners();
      addSocketListeners();
      this.sendAddUser();
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected.');
      store.dispatch(setSocketConnected(false));
    });
  }

  /**
   * Sets up the event listeners for incoming socket messages.
   *
   * @return {void} This function does not return anything.
   */

  _setupListeners() {
    socket.on('message', (message: Message) => {
      const callback = this.messageListners[message.type];
      if (callback) {
        callback(message);
      } else {
        console.log('No callback for message type: ' + message.type);
      }
    });
  }

  /**
   * Adds a listener for a specific message type.
   *
   * @param {MessageType} key - The type of message to listen for.
   * @param {(message: Message) => void} callback - The function to call when a message of the specified type is received.
   * @return {void} This function does not return a value.
   */

  addListener(key: MessageType, callback: (message: Message) => void) {
    this.messageListners[key] = callback;
  }

  /**
   * Adds a listener for a specific message type.
   *
   * @param {MessageType} key - The type of message to listen for.
   * @param {(message: Message) => void} callback - The function to call when a message of the specified type is received.
   * @return {void} This function does not return a value.
   */

  removeListener(key: MessageType) {
    delete this.messageListners[key];
  }

  /**
   * Disconnects the socket client from the server.
   *
   * @return {void} This function does not return a value.
   */

  disconnect() {
    socket?.removeAllListeners();
    socket?.disconnect();
    socket?.close();
  }

  /**
   * Sends a message to add the current user to the socket connection.
   *
   * @return {void} This function does not return a value.
   */

  sendAddUser() {
    const {token, user} = store.getState();
    if (user?.currentUser?.id && token && !this.isUserAdded) {
      const message = {
        type: MessageType.AddUser,
        message: {
          userId: user?.currentUser?.id,
          token: token,
        },
      };
      this.send(message);
    }
  }

  /**
   * Retrieves the status of a user.
   *
   * @param {GetUserStatusMessage} {fromUserId, userId} - The message containing the IDs of the users involved in the status request.
   * @return {void} This function does not return a value.
   */

  getUserStatus({fromUserId, userId}: GetUserStatusMessage) {
    const message: Message = {
      type: MessageType.GetUserStatus,
      message: {
        fromUserId,
        userId,
      } as GetUserStatusMessage,
    };
    this.send(message);
  }

  /**
   * Sends a message to the server via the socket connection if it is connected.
   *
   * @param {Message} message - The message to be sent to the server.
   * @return {void} This function does not return a value.
   */

  send(message: Message) {
    if (socket?.connected) {
      socket?.send(message);
    }
  }
}

export const socketClient = new SocketClient();
