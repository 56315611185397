/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  useCheckCancellationRequestQuery,
  useCreateCancellationMutation,
} from '../Services/cancellationRequestApi';
import {ledgerApi, useRazorPaySuccessMutation} from '../Services/ledgerApi';
import {
  meetingApi,
  useGetAttendedMeetingDetailsQuery,
  useGetScheduledMeetingsDetailsQuery,
} from '../Services/meetingApi';
import {useGetAllRemainderQuery} from '../Services/meetingNotificationListApi';
import {
  meetingParticipantApi,
  useCancelParticipantFreeEventMutation,
} from '../Services/meetingParticipantApi';
import {MeetingRequest, Accept, Select} from '../models/meetingRequest.model';
import {
  meetingRequestApi,
  useCancelRequestMutation,
  useDeleteRequestAfterAcceptMutation,
  useDeleteRequestsMutation,
  useGetRequestsQuery,
  useRejectRequestMutation,
  useUpdateRequestMutation,
} from '../Services/meetingRequestApi';
import {yupResolver} from '@hookform/resolvers/yup';
import {format, isAfter, isBefore, subHours, subMinutes} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {WebCommandTypes} from '../API/socketTypes';
import {SendWebCommand} from '../API/webCommands';
import {useGetPaymentQuery} from '../Services/allOthersApi';
import {useBlockRequestMutation} from '../Services/blockedRequestApi';
import {
  useCreateRemainderMutation,
  useDeleteRemainderMutation,
  useGetMeetingNotificationTimeQuery,
} from '../Services/meetingNotificationRemainderApi';
import {getCurrentUser} from '../Services/userReducer';
import {useAppDispatch, useAppSelector} from '../Store/hooks';
import {isBetween} from '../Utils/datetime';
import {
  attendingEventsValidationSchema,
  cancellationFormValidationSchema,
  rejectReasonValidationSchema,
} from '../Utils/validation';
import {BlockedRequests} from '../models/blockedRequests.model';
import {Meeting} from '../models/meeting.model';
import {MeetingNotificationList} from '../models/meetingNotficationList.model';
import {MeetingNotificationRemainder} from '../models/meetingNotificationRemainder.model';
import beemgLogo from '../Assets/Images/beeMG_logo-icon-notext-04.svg';
import {InitialReport} from './RequestForMeetingHook';
import useRazorpay, {RazorpayOptions} from 'react-razorpay';
import {getIsMeetingEnded} from '../Services/livekitReducer';

/**
 * Custom React hook for managing attending events.
 *
 * Provides data and functions for handling scheduled and attended meetings.
 *
 * @return {object} An object containing scheduled data, attended data, error and loading states, and functions for filtering and refetching data.
 */

export const useAttendingHooks = () => {
  const isMeetingEnded = useAppSelector(getIsMeetingEnded);

  const {
    data: scheduledData,
    isSuccess: scheduledDataSuccess,
    isError: scheduledError,
    isLoading: scheduledLoading,
    refetch: attendingEventPageRefetch,
    isFetching: attendingEventPageRefetching,
  } = useGetScheduledMeetingsDetailsQuery();
  const {data: attendedData} = useGetAttendedMeetingDetailsQuery();

  const [scheduledDataEvents, setScheduledDataEvents] = useState<Meeting[]>([]);

  useEffect(() => {
    attendingEventPageRefetch();
  }, [isMeetingEnded]);

  const checkFilter = (filterList: 'latest' | 'scheduled') => {
    if (scheduledData) {
      if (filterList === 'scheduled') {
        setScheduledDataEvents(
          scheduledData?.filter(
            (meeting: Meeting) => meeting.request === 'Scheduled events',
          ),
        );
      } else if (filterList === 'latest') {
        setScheduledDataEvents(
          scheduledData?.filter(
            (meeting: Meeting) => meeting.request === 'Scheduled events',
          ),
        );
        const upcomingSorted = [...scheduledData].sort((a, b) => {
          return isAfter(new Date(a.fromDateTime), new Date(b.fromDateTime))
            ? -1
            : 1;
        });
        setScheduledDataEvents(upcomingSorted);
      }
    }
  };

  return {
    scheduledData,
    scheduledError,
    scheduledLoading,
    scheduledDataSuccess,
    attendedData,
    checkFilter,
    setScheduledDataEvents,
    scheduledDataEvents,
    attendingEventPageRefetch,
    attendingEventPageRefetching,
  };
};

/**
 * Custom React hook for managing attending event invitations.
 *
 * Provides a function to handle invite requests and a state variable to track the update success status.
 *
 * @return {object} An object containing the handleInviteRequest function and the updateSuccess state variable.
 */

export const useAttendingEventInvitationHooks = () => {
  const [updateSuccess, setUpdateSuccess] = useState<'Accepted' | 'Rejected'>();
  const dispatch = useAppDispatch();

  const handleInviteRequest = (meetingParticipantId: string, value: any) => {
    const args = {meetingParticipantId: meetingParticipantId, value: value};

    dispatch(
      meetingParticipantApi.endpoints.updateConfirmationStatus.initiate(args),
    ).then((_response: any) => {
      if (args.value === 'Accepted') {
        setUpdateSuccess('Accepted');
      } else if (args.value === 'Rejected') {
        setUpdateSuccess('Rejected');
      }
    });
  };

  return {handleInviteRequest, updateSuccess};
};

/**
 * Custom React hook for managing free event attendance cancellation.
 *
 * Provides a function to cancel free event attendance and tracks the cancellation status.
 *
 * @return {object} An object containing the handleFreeEventCancel function, freeCancelSuccess status, and freeCancelLoading status.
 */

export const useAttendingFreeEventHooks = () => {
  const [
    cancelFreeRequest,
    {isSuccess: freeCancelSuccess, isLoading: freeCancelLoading},
  ] = useCancelParticipantFreeEventMutation();

  const handleFreeEventCancel = (id: any) => {
    cancelFreeRequest(id);
  };

  return {handleFreeEventCancel, freeCancelSuccess, freeCancelLoading};
};

/**
 * Custom React hook for managing attending request pending functionality.
 *
 * Provides functions to handle pending request details and deletion, as well as state variables to track the pending request and deletion success status.
 *
 * @return {object} An object containing the handlePendingDetails function, handlePendingDelete function, loadMeetingPendingRequest state, deletePendingRequestSuccess state, and capitalizeFirstLetter function.
 */

export const useAttendingRequestPendingHooks = () => {
  const dispatch = useAppDispatch();

  const [loadMeetingPendingRequest, setloadMeetingRequest] = useState<any>('');
  const [deletePendingRequest, {isSuccess: deletePendingRequestSuccess}] =
    useDeleteRequestsMutation();

  const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handlePendingDetails = (id: string) => {
    setloadMeetingRequest(id);

    dispatch(meetingRequestApi.endpoints.getRequests.initiate(id)).then(
      value => {
        setloadMeetingRequest(value.data);
      },
    );
  };

  const handlePendingDelete = (values: any) => {
    deletePendingRequest(values);
  };

  return {
    handlePendingDetails,
    handlePendingDelete,
    loadMeetingPendingRequest,
    deletePendingRequestSuccess,
    capitalizeFirstLetter,
  };
};

/**
 * Custom React hook for managing attending request accepted functionality.
 *
 * Provides functions to handle accepted request cancellation and deletion, as well as state variables to track the cancellation and deletion success status.
 *
 * @return {object} An object containing the handleAcceptedCancellation function, handleAcceptedDelete function, cancelAcceptedSuccess status, deleteAcceptedSuccess status, and cancelAcceptedLoading status.
 */

export const useAttendingRequestAcceptedHooks = () => {
  const [
    cancelAcceptedRequest,
    {isSuccess: cancelAcceptedSuccess, isLoading: cancelAcceptedLoading},
  ] = useCancelRequestMutation();
  const [deleteAcceptedRequest, {isSuccess: deleteAcceptedSuccess}] =
    useDeleteRequestAfterAcceptMutation();

  const handleAcceptedCancellation = (values: any) => {
    cancelAcceptedRequest(values);
  };

  const handleAcceptedDelete = (values: any) => {
    deleteAcceptedRequest(values);
  };

  return {
    handleAcceptedCancellation,
    handleAcceptedDelete,
    cancelAcceptedSuccess,
    deleteAcceptedSuccess,
    cancelAcceptedLoading,
  };
};

/**
 * Custom React hook for managing attending event payments.
 *
 * Provides a function to handle payments, a state variable to track the navigation screen link, and a state variable to track the loading status.
 *
 * @return {object} An object containing the payment function, navigateScreenLink state, and loading state.
 */

export const useAttendingPaymentHooks = () => {
  const {data: paymentDetailIndia} = useGetPaymentQuery('INR');
  const dispatch = useAppDispatch();
  const [razorPaySuccess] = useRazorPaySuccessMutation<any>();
  const [Razorpay] = useRazorpay();
  const [razorpayOpen, setRazorpayOpen] = useState(false);

  const [navigateScreenLink, setNavigateScreenLink] = useState<string>();
  const [loading, setLoading] = useState(false);

  const payment = async (request: any, joinAnonymous: boolean) => {
    const args = {
      requestId: request.id,
      guestCount: request.participants,
      joinAnonymous: joinAnonymous,
    };
    if (!razorpayOpen) {
      setRazorpayOpen(true);
      const res = await dispatch(
        ledgerApi.endpoints.paymentRequest.initiate(args),
      );
      if (res.data) {
        if (
          paymentDetailIndia &&
          paymentDetailIndia.currencyList.indexOf(
            request.guest.preference.currency,
          ) > -1
        ) {
          const options: RazorpayOptions = {
            key: res.data.key_id,
            amount: res.data.amount,
            currency: 'INR',
            name: res.data.name,
            description: 'BeeMG',
            image: beemgLogo,
            order_id: res.data.order_id,
            handler: rzpayResponse => {
              if (rzpayResponse.razorpay_payment_id) {
                onPaymentSuccess(rzpayResponse);
              } else {
                onPaymentFailure(rzpayResponse);
              }
            },
          };
          const rzpay = new Razorpay(options);
          rzpay.open();
        } else {
          if (!navigateScreenLink) {
            setNavigateScreenLink(res.data.url);
          }
        }
      }
    }
  };

  const onPaymentSuccess = (response: any) => {
    const args = {
      orderId: response.razorpay_order_id,
      paymentId: response.razorpay_payment_id,
    };

    const res = razorPaySuccess(args);

    if (!loading) {
      setLoading(true);
    }

    res.then((val: any) => {
      if (!navigateScreenLink) {
        if (loading) {
          setLoading(false);
        }
        setRazorpayOpen(false);

        setNavigateScreenLink('/s/payment/' + val.data.transactionId);
      }
    });
  };

  const onPaymentFailure = (response: any) => {
    const args = {
      orderId: response.error.metadata.order_id,
      paymentId: response.error.metadata.payment_id,
    };
    dispatch(ledgerApi.endpoints.razorPayFail.initiate(args));
    setRazorpayOpen(false);
  };

  return {payment, navigateScreenLink, loading};
};

/**
 * Custom React hook for managing meeting cancellations.
 *
 * Provides functions and state variables to handle cancellation requests, track cancellation status, and manage meeting details.
 *
 * @param {object} meeting - The meeting object containing details about the meeting.
 * @return {object} An object containing the handleCancellationRequest function, cancellation data, and various state variables.
 */

export const useCancellationHooks = ({meeting}: {meeting: any}) => {
  const {t} = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const {
    data: cancellationData,
    isSuccess: cancellationDataSuccess,
    isLoading: cancellationDataIsLoading,
  } = useCheckCancellationRequestQuery(meeting.id ?? '', {skip: !meeting.id});
  const [
    paidCancellationRequest,
    {isSuccess: paidCancellationSuccess, isLoading: paidCancellationLoading},
  ] = useCreateCancellationMutation();

  const {
    control,
    register,
    setValue,
    getValues,
    trigger,
    formState: {errors, isDirty, isValid},
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(cancellationFormValidationSchema),
  });

  const [requestedCancellation, setRequestedCancellation] = useState<
    | 'REQUESTED'
    | 'PARTIALREQUESTED'
    | 'REQUEST'
    | 'CANCELLED'
    | 'PARTIALREQUEST'
  >();
  const [meetingTitle, setMeetingTitle] = useState<string>('');
  const [selectedToDateTime, setSelectedToDateTime] = useState<string>('');
  const [selectedFromDateTime, setSelectedFromDateTime] = useState<string>('');
  const [requestForMeetingCancel, setRequestForMeetingCancel] =
    useState<boolean>(false);

  useEffect(() => {
    if (meeting) {
      if (meeting?.type === 'Type 2' && currentUser.id === meeting.user?.id) {
        setMeetingTitle(
          `${t('meetingWith')} ${meeting.user2?.firstName} ${
            meeting.user2?.lastName
          }`,
        );
      } else if (
        meeting?.type === 'Type 2' &&
        currentUser.id === meeting.user2?.id
      ) {
        setMeetingTitle(
          `${t('meetingWith')} ${meeting.user?.firstName} ${
            meeting.user?.lastName
          }`,
        );
        setRequestForMeetingCancel(true);
      } else if (meeting?.type === 'Type 2' && meeting?.meetingParticipant) {
        setMeetingTitle(
          `${t('meetingWith')} ${meeting.user?.firstName} ${
            meeting.user?.lastName
          }`,
        );
      } else {
        setMeetingTitle(meeting.meetingDetails.title);
      }
    }
  }, [currentUser.id, meeting, t]);

  useEffect(() => {
    if (
      cancellationData &&
      cancellationData?.processed === 'No' &&
      currentUser.id !== meeting.user2?.id &&
      cancellationData?.refundType === 'Full' &&
      meeting.meetingParticipant[0].isCohost !== true
    ) {
      setRequestedCancellation('REQUESTED');
    }
    if (
      currentUser.id !== meeting.user2?.id &&
      !cancellationData &&
      meeting.meetingParticipant[0].isCohost !== true
    ) {
      setRequestedCancellation('REQUEST');
    }
    /*
    // if (cancellationData?.processed === 'Yes' && currentUser.id !== meeting.user2?.id &&
    //   cancellationData?.refundType === 'Full') {
    //   setRequestedCancellation('CANCELLED')
    // }*/
    if (
      cancellationData &&
      cancellationData?.processed === 'No' &&
      currentUser.id !== meeting.user2?.id &&
      cancellationData?.refundType === 'Partial' &&
      meeting.meetingParticipant[0].isCohost !== true
    ) {
      setRequestedCancellation('PARTIALREQUESTED');
    }
    if (
      currentUser.id !== meeting.user2?.id &&
      !cancellationData &&
      meeting.meetingParticipant &&
      meeting.meetingParticipant.length > 0 &&
      meeting.meetingParticipant[0].transaction &&
      meeting.meetingParticipant[0].transaction.originalGuestCount > 1
    ) {
      setRequestedCancellation('PARTIALREQUEST');
    }
  }, [cancellationData, currentUser]);

  useEffect(() => {
    if (
      meeting.meetingDetails?.cancellationPolicy?.chargePeriodFromTimeInMinute
    ) {
      setSelectedToDateTime(
        format(
          subMinutes(
            new Date(meeting?.fromDateTime),
            Number(
              meeting.meetingDetails?.cancellationPolicy
                ?.chargePeriodFromTimeInMinute,
            ),
          ),
          'do MMM, hh:mm a',
        ),
      );
    }
    if (
      meeting.meetingDetails?.cancellationPolicy?.chargePeriodToTimeInMinute
    ) {
      setSelectedFromDateTime(
        format(
          subMinutes(
            new Date(meeting?.fromDateTime),
            Number(
              meeting.meetingDetails?.cancellationPolicy
                ?.chargePeriodToTimeInMinute,
            ),
          ),
          'do MMM, hh:mm a',
        ),
      );
    }
  }, [meeting]);

  const handleCancellationRequest = (values: any) => {
    paidCancellationRequest({
      meeting: values,
      cancellationReason: getValues('reason'),
    });
  };

  return {
    handleCancellationRequest,
    cancellationData,
    cancellationDataIsLoading,
    requestedCancellation,
    cancellationDataSuccess,
    paidCancellationLoading,
    paidCancellationSuccess,
    currentUser,
    selectedToDateTime,
    selectedFromDateTime,
    meetingTitle,
    requestForMeetingCancel,
    getValues,
    setValue,
    register,
    errors,
    isDirty,
    isValid,
    trigger,
    control,
  };
};

/**
 * Custom React hook for managing received meeting requests.
 *
 * Provides functions to handle received request deletion, blocking, and details, as well as state variables to track the request data, submission status, and validation.
 *
 * @param {object} options - Options object containing the meeting request data.
 * @param {MeetingRequest} options.meetingRequest - The meeting request data.
 * @return {object} An object containing the meeting request data, submission status, and various functions to handle the request.
 */

export const useAttendingReceivedCardHooks = ({
  meetingRequest,
}: {
  meetingRequest?: MeetingRequest;
}) => {
  const dispatch = useAppDispatch();
  const currentTime = new Date().toISOString();
  const currentUser = useSelector(getCurrentUser);

  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    reset,
    watch,
    formState: {errors, isDirty, isValidating, dirtyFields, isValid},
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(attendingEventsValidationSchema),
  });

  const {data: meetingRequestData, isSuccess: meetingRequestSuccess} =
    useGetRequestsQuery(meetingRequest?.id ?? '', {skip: !meetingRequest?.id});

  const [setShow, setShowedValues] = useState(false);
  const [loadMeetingReceivedRequest, setloadMeetingReceivedRequest] =
    useState<any>();
  const [availableMeetingSubmitDisable, setAvailableMeetingSubmitDisable] =
    useState(false);
  const [expireDateSubmitDisable, setExpireDateSubmitDisable] = useState(false);
  const [
    availableGuestMeetingSubmitDisable,
    setAvailableGuestMeetingSubmitDisable,
  ] = useState(false);

  const [
    acceptReceivedRequest,
    {isSuccess: acceptReceivedSuccess, isLoading: acceptReceivedLoading},
  ] = useUpdateRequestMutation();
  const [rejectReceivedRequest, {isSuccess: rejectReceivedSuccess}] =
    useRejectRequestMutation();
  const [deleteReceivedRequest, {isSuccess: deleteReceivedSuccess}] =
    useDeleteRequestsMutation();
  const [blockReceivedRequest, {isSuccess: blockReceivedSuccess}] =
    useBlockRequestMutation();

  const checkForExpiredDate = (fromDateTime: string, toDateTime: string) => {
    if (fromDateTime && toDateTime && meetingRequestData) {
      if (
        fromDateTime < currentTime ||
        toDateTime < currentTime ||
        meetingRequestData.startAt1DateTime >
          meetingRequestData.endAt1DateTime ||
        (meetingRequestData.startAt2DateTime &&
        meetingRequestData.endAt2DateTime
          ? meetingRequestData.startAt2DateTime >
            meetingRequestData.endAt2DateTime
          : false) ||
        (meetingRequestData.startAt3DateTime &&
        meetingRequestData.endAt3DateTime
          ? meetingRequestData.startAt3DateTime >
            meetingRequestData.endAt3DateTime
          : false)
      ) {
        setAvailableMeetingSubmitDisable(false);
        setExpireDateSubmitDisable(true);
        return true;
      } else {
        setExpireDateSubmitDisable(false);
        return false;
      }
    }

    return false;
  };

  const checkForMeetings = (
    fromDateTime: string,
    toDateTime: string,
    guestId?: string,
  ) => {
    const expiredData = checkForExpiredDate(fromDateTime, toDateTime);
    if (!expiredData && fromDateTime && toDateTime) {
      const DateCheck = dispatch(
        meetingApi.endpoints.checkMeetingForRequest.initiate({
          fromDateTime,
          toDateTime,
        }),
      );
      DateCheck.then((res: any) => {
        if (res.data && res.data !== null && res.data === true) {
          setAvailableMeetingSubmitDisable(true);
          setExpireDateSubmitDisable(false);
        } else if (res.data === false) {
          setAvailableMeetingSubmitDisable(false);
          setExpireDateSubmitDisable(false);
        }
      });

      const GuestDateCheck = dispatch(
        meetingApi.endpoints.checkMeetingForGuest.initiate({
          fromDateTime,
          toDateTime,
          guestId,
        }),
      );

      GuestDateCheck.then((res: any) => {
        if (res.data) {
          setAvailableGuestMeetingSubmitDisable(true);
          setExpireDateSubmitDisable(false);
        }
      });
    } else {
      setAvailableMeetingSubmitDisable(false);
      setExpireDateSubmitDisable(true);
    }
  };

  const handleReceivedDelete = () => {
    if (meetingRequest) {
      deleteReceivedRequest(meetingRequest)
        .unwrap()
        .then(() => {
          SendWebCommand({
            type: WebCommandTypes.RFMUpdate,
            fromUserId: currentUser.id,
            toUserId: meetingRequest?.user?.id,
          });
        });
    }
  };

  const handleReceivedBlock = () => {
    if (meetingRequest) {
      let blockedRequest = {} as BlockedRequests;
      blockedRequest.requestId = meetingRequest.id;
      blockedRequest.blockedUser = meetingRequest.user?.id;
      blockedRequest.user_id = currentUser.id;
      blockReceivedRequest(blockedRequest);
    }
  };

  const handleReceivedDetails = () => {
    if (meetingRequestSuccess) {
      setloadMeetingReceivedRequest(meetingRequestData);
    }
  };

  const onSubmit = (values: any) => {
    let meetingRequestData = meetingRequest ?? ({} as MeetingRequest);
    const meetingRequestValues = Object.assign({}, meetingRequestData);
    meetingRequestValues.select = values.meetingRequestOption;
    meetingRequestValues.cost = Number(meetingRequest?.cost);
    meetingRequestValues.accept = Accept.Yes;
    meetingRequestValues.cancellationPolicy = values.cancellationPolicy;

    acceptReceivedRequest(meetingRequestValues)
      .unwrap()
      .then(() => {
        SendWebCommand({
          type: WebCommandTypes.RFMUpdate,
          fromUserId: currentUser.id,
          toUserId: meetingRequestValues?.user?.id,
        });
      });
  };

  const OnReject = () => {
    let meetingRequestData = meetingRequest ?? ({} as MeetingRequest);
    const meetingRequestValues = Object.assign({}, meetingRequestData);
    meetingRequestValues.accept = Accept.No;
    meetingRequestValues.cost = Number(meetingRequest?.cost);
    meetingRequestValues.reason = getValues().reason;

    rejectReceivedRequest(meetingRequestValues)
      .unwrap()
      .then(() => {
        SendWebCommand({
          type: WebCommandTypes.RFMUpdate,
          fromUserId: currentUser.id,
          toUserId: meetingRequestValues?.user?.id,
        });
      });
  };

  const checkForMeetingRequest = (guestId: string) => {
    const selectedRequest = getValues();

    if (selectedRequest.meetingRequestOption === Select.Option1) {
      return checkForMeetings(
        loadMeetingReceivedRequest.startAt1DateTime,
        loadMeetingReceivedRequest.endAt1DateTime,
        guestId,
      );
    } else if (selectedRequest.meetingRequestOption === Select.Option2) {
      return checkForMeetings(
        loadMeetingReceivedRequest.startAt2DateTime,
        loadMeetingReceivedRequest.endAt2DateTime,
        guestId,
      );
    } else if (selectedRequest.meetingRequestOption === Select.Option3) {
      return checkForMeetings(
        loadMeetingReceivedRequest.startAt3DateTime,
        loadMeetingReceivedRequest.endAt3DateTime,
        guestId,
      );
    }
  };

  return {
    meetingRequestData,
    meetingRequestSuccess,
    getValues,
    handleReceivedBlock,
    checkForMeetings,
    availableMeetingSubmitDisable,
    expireDateSubmitDisable,
    availableGuestMeetingSubmitDisable,
    register,
    control,
    handleSubmit,
    watch,
    reset,
    trigger,
    isValidating,
    dirtyFields,
    // handleAcceptedDetails, loadMeetingAcceptedRequest
    checkForMeetingRequest,
    loadMeetingReceivedRequest,
    setShow,
    setShowedValues,
    handleReceivedDelete,
    handleReceivedDetails,
    onSubmit,
    OnReject,
    setValue,
    errors,
    isDirty,
    isValid,
    deleteReceivedSuccess,
    acceptReceivedSuccess,
    rejectReceivedSuccess,
    blockReceivedSuccess,
    acceptReceivedLoading,
  };
};

/**
 * Custom React hook for managing meeting request rejections.
 *
 * Provides functions and state variables to handle rejection requests, track rejection status, and manage meeting request details.
 *
 * @param {object} meetingRequest - The meeting request object containing details about the meeting request.
 * @return {object} An object containing the OnReject function, form state variables, and various state variables related to the rejection request.
 */

export const useRejectRequestHooks = ({
  meetingRequest,
}: {
  meetingRequest?: MeetingRequest;
}) => {
  const {
    register,
    getValues,
    handleSubmit,
    trigger,
    reset,
    watch,
    formState: {errors, isDirty, isValid},
  } = useForm<InitialReport>({
    mode: 'onChange',
    resolver: yupResolver(rejectReasonValidationSchema),
  });

  const [rejectReceivedRequest, {isSuccess: rejectReceivedSuccess}] =
    useRejectRequestMutation();

  const OnReject = () => {
    let meetingRequestData = meetingRequest ?? ({} as MeetingRequest);
    const meetingRequestValues = Object.assign({}, meetingRequestData);
    meetingRequestValues.accept = Accept.No;
    meetingRequestValues.cost = Number(meetingRequest?.cost);
    meetingRequestValues.reason = getValues().reason;
    rejectReceivedRequest(meetingRequestValues);
  };
  return {
    OnReject,
    handleSubmit,
    reset,
    register,
    getValues,
    watch,
    trigger,
    errors,
    isDirty,
    isValid,
    rejectReceivedSuccess,
  };
};

/**
 * Generates hooks for managing the join state of a meeting.
 *
 * @param {object} options - The options object.
 * @param {Meeting | undefined} options.meeting - The meeting object.
 * @return {object} The hooks object.
 */

export const useMeetingJoinHooks = ({meeting}: {meeting?: Meeting}) => {
  const bufferTime = 10; // minutes
  const remainderTime = 5; // minutes

  const [joinTime, setJoinTime] = useState<boolean>();
  const [endedTime, setEndedTime] = useState<boolean>();
  const [joinBufferTime, setJoinBufferTime] = useState<boolean>();
  const [remainderJoinTime, setRemainderJoinTime] = useState<boolean>(false);
  const [startTime] = useState<Date>(
    subMinutes(
      new Date(meeting?.fromDateTime ? meeting?.fromDateTime : ''),
      bufferTime,
    ),
  );
  const [rejoin, setRejoin] = useState<boolean>(false);
  const [endTime] = useState<Date>(
    new Date(
      meeting?.expectedEndAt
        ? meeting.expectedEndAt
        : meeting?.toDateTime
        ? meeting?.toDateTime
        : '',
    ),
  );
  const [actuaStart] = useState<Date>(
    subMinutes(
      new Date(meeting?.fromDateTime ? meeting?.fromDateTime : ''),
      remainderTime,
    ),
  );
  const [requestCancelTime, setRequestCancelTime] = useState<boolean>(false);
  const requestTime = Number(
    meeting?.meetingDetails?.cancellationPolicy?.chargePeriodFromTime,
  );

  const [meetingTime] = useState<Date>(
    new Date(meeting?.fromDateTime ? meeting?.fromDateTime : ''),
  );
  const [cancellationTime] = useState<Date>(
    subHours(
      new Date(meeting?.fromDateTime ? meeting?.fromDateTime : ''),
      requestTime,
    ),
  );

  const [showStartMeeting, setShowStartMeeting] = useState<boolean>(false);

  useEffect(() => {
    if (
      meeting &&
      meeting.meetingParticipant &&
      meeting.meetingParticipant.length > 0
    ) {
      if (meeting.meetingParticipant[0]?.isCohost && !meeting.actualStartAt) {
        setShowStartMeeting(true);
      } else {
        setShowStartMeeting(false);
      }
    }
  }, [meeting]);

  useEffect(() => {
    if (isBetween(new Date(), cancellationTime, meetingTime)) {
      setRequestCancelTime(true);
    }
  }, [cancellationTime]);

  useEffect(() => {
    let interval: any = null;

    if (meeting?.fromDateTime) {
      if (isBetween(new Date(), startTime, actuaStart)) {
        setRemainderJoinTime(true);
      } else {
        setRemainderJoinTime(false);
      }
    }

    if (meeting?.actualStartAt !== null) {
      setRejoin(true);
    }

    if (meeting?.toDateTime) {
      if (isAfter(new Date(), endTime)) {
        if (!endedTime) {
          setEndedTime(true);
        }
      }
    }

    if (meeting?.fromDateTime && meeting?.toDateTime) {
      interval = setInterval(() => {
        if (isBetween(new Date(), startTime, actuaStart)) {
          setRemainderJoinTime(true);
        }
        if (isBefore(new Date(), meetingTime)) {
          setJoinBufferTime(true);
        } else {
          setJoinBufferTime(false);
        }
        if (isBetween(new Date(), startTime, endTime)) {
          if (!joinTime) {
            setJoinTime(true);
          }
        } else {
          if (joinTime) {
            setJoinTime(false);
          }
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [
    meeting,
    endTime,
    startTime,
    rejoin,
    joinTime,
    remainderJoinTime,
    actuaStart,
    endedTime,
  ]);

  return {
    joinTime,
    rejoin,
    actuaStart,
    remainderJoinTime,
    requestCancelTime,
    showStartMeeting,
    joinBufferTime,
    endedTime,
  };
};

/**
 * Custom hook for managing meeting reminders.
 *
 * It fetches meeting reminders, creates and deletes reminders, and provides a list of reminders.
 *
 * @param {{ meeting: Meeting }} params - Object containing the meeting data.
 * @return {{ remainders: MeetingNotificationRemainder[], setRemainders: Function, remainderSuccess: boolean, meetingRemainderData: any, meetingRemainderSuccess: boolean, remList: MeetingNotificationList[], addNewRemainder: Function, removeRemainder: Function }} - An object containing the reminders, functions to manage reminders, and success flags.
 */

export const useMeetingRemainderHooks = ({meeting}: {meeting: Meeting}) => {
  const [remList, setRemList] = useState<MeetingNotificationList[]>([]);
  const [remainders, setRemainders] = useState<MeetingNotificationRemainder[]>(
    [],
  );
  const {data: remainderData, isSuccess: remainderSuccess} =
    useGetAllRemainderQuery();
  const {data: meetingRemainderData, isSuccess: meetingRemainderSuccess} =
    useGetMeetingNotificationTimeQuery(meeting?.id);

  const [createRemainder] = useCreateRemainderMutation();
  const [deleteRemainder] = useDeleteRemainderMutation();

  const getRemainingMinutes = () =>
    (new Date(meeting?.fromDateTime).getTime() - new Date().getTime()) / 60000;

  const selectedRemainderList = () => {
    if (meetingRemainderData) {
      const differenceInMinutes = getRemainingMinutes();
      const futureRemainderData = meetingRemainderData?.filter(
        meetingNotification =>
          meetingNotification.meetingNotificationList
            .notificationTimingInMinutes < differenceInMinutes,
      );
      const sorted = [...futureRemainderData].sort(
        (a: MeetingNotificationRemainder, b: MeetingNotificationRemainder) => {
          return (
            a.meetingNotificationList.notificationTimingInMinutes -
            b.meetingNotificationList.notificationTimingInMinutes
          );
        },
      );

      return sorted;
    }
  };

  const createRemainderList = (
    selectedRemainders: MeetingNotificationRemainder[],
  ) => {
    const differenceInMinutes = getRemainingMinutes();
    const newTimeRemList = remainderData?.filter(
      meetingNotification =>
        meetingNotification.notificationTimingInMinutes < differenceInMinutes,
    );

    const result = newTimeRemList?.filter(t => {
      return !selectedRemainders.find(
        s =>
          s.meetingNotificationList.notificationTimingInMinutes ===
          t.notificationTimingInMinutes,
      );
    });

    return result ?? [];
  };
  const setRemaindersLists = () => {
    const selectedRemainders = selectedRemainderList() ?? [];
    let newTimeRemList = createRemainderList(selectedRemainders);
    if (newTimeRemList !== remList) {
      setRemList(newTimeRemList);
    }
    if (selectedRemainders !== remainders) {
      setRemainders(selectedRemainders);
    }
  };
  useEffect(() => {
    setRemaindersLists();
    const timer = setInterval(setRemaindersLists, 30 * 1000); // 30 seconds
    return () => {
      clearInterval(timer);
    };
  }, [remainderData, meetingRemainderData]);

  const addNewRemainder = (item: MeetingNotificationList) => {
    const found = remainders.find(
      remainder => remainder.meetingNotificationList.id === item.id,
    );

    if (found === undefined) {
      const newRemainder = {} as MeetingNotificationRemainder;
      newRemainder.meetingNotificationList = item;
      newRemainder.meeting = meeting;
      createRemainder(newRemainder);
    }
  };

  const removeRemainder = (meetingRemainderId: string) => {
    deleteRemainder({
      meetingId: meeting.id,
      meetingNotificationRemainderId: meetingRemainderId,
    });
  };

  return {
    remainders,
    setRemainders,
    remainderSuccess,
    meetingRemainderData,
    meetingRemainderSuccess,
    remList,
    addNewRemainder,
    removeRemainder,
  };
};
