/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from "../../Components/base/modal/modal";
import useToggle from "../../Components/_utils/useToggle";
import { useGetSettingValue } from "../../Services/settingReducer";

/**
 * Component to display a user's cover video.
 *
 * @param {string} videoFile - URL of the video file.
 * @returns {JSX.Element} The JSX element representing the component.
 */
const UserVideo = ({ videoFile }: { videoFile: string }) => {
  const { t } = useTranslation();
  const imageURL = useGetSettingValue('IMAGE_URL')
  const { isOpen: videoModalOpen, toggle: toggleVideoModalOpen } = useToggle()

  return (
    <>
      <video
        onClick={() => {
          toggleVideoModalOpen(true)
        }}
        className='w-full h-full flex flex-1 object-cover'
        muted
        loop
        autoPlay
      >
        <source data-testid={`video-${videoFile}`} src={`${imageURL}/${videoFile}`} type="video/mp4" />
      </video>

      <Modal
        isOpen={videoModalOpen}
        toggle={() => {
          toggleVideoModalOpen(false)
        }}
        closeOnClickOutside={false}>
        <div className="p-3">
          <div className="flex flex-row justify-between">
            <ModalHeader>
              <div>{t('coverVideo')}</div>
            </ModalHeader>
            <ModalCloseButton
              toggle={() => {
                toggleVideoModalOpen(false)
              }}
            />
          </div>
          <ModalBody>
            <div className="flex flex-col gap-y-5 flex-1 min-h-[20rem] w-full relative max-h-[70vh]">

              <div className="flex flex-col justify-center items-center w-full min-h-[20rem]">

                {videoFile && (
                  <video
                    controls
                    className="mt-1 flex flex-1 justify-center items-center rounded-md cursor-pointer relative max-h-[50vh]">
                    <source
                      src={`${imageURL}/${videoFile}`}
                      type="video/mp4"
                    />
                  </video>
                )}
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}


export default UserVideo;
