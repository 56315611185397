/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import {
  useMuteAllHooks,
  useUnMuteAllHooks,
  useHandRaiseHook,
  useHandRaiseReceivedHook,
  useHostCoHostAvailabilityHook,
} from 'livekit/livekitHooks'
import { useEffect, useState } from 'react'
import { useCustomRoomContext } from './CustomRoomContext'
import { useTranslation } from 'react-i18next'

/**
 * A React component that displays toasts for permission-related events in a LiveKit room.
 *
 * @return {JSX.Element | null} The rendered toast component, or null if no toast is displayed.
 */

function PermissionsToasterComp() {
  const { onMessage } = useCustomRoomContext()
  const { isMutedAll, setIsMutedAll } = useMuteAllHooks()
  const { isUnmutedAll, setIsUnmutedAll } = useUnMuteAllHooks()
  const { isHostUnmuted, setIsHostUnmuted } = useHandRaiseHook()
  const { localHandRaised, participantName } = useHandRaiseReceivedHook()
  const { isHostOrCohostAvailable } = useHostCoHostAvailabilityHook()
  const upperCaseName = participantName ? participantName.replace(/\b\w/g, (c) => c.toUpperCase()) : ''

  const [permissionToTalkToasterShown, setPermissionToTalkToasterShown] = useState(false)
  const [permissionRemovedToasterShown, setPermissionRemovedToasterShown] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    if ((isHostUnmuted || isUnmutedAll) && onMessage && isHostOrCohostAvailable && !permissionToTalkToasterShown) {
      onMessage(t(`hostGrantedPermissionToTalk`), 'info')
      setIsMutedAll(false)
      setPermissionToTalkToasterShown(true)
    }
  }, [isHostUnmuted, isUnmutedAll, onMessage, permissionToTalkToasterShown])

  useEffect(() => {
    if (isMutedAll && onMessage && isHostOrCohostAvailable && !permissionRemovedToasterShown) {
      setIsHostUnmuted(false)
      setIsUnmutedAll(false)
      onMessage(t(`hostRemovedPermissionToTalk`), 'info')
      setPermissionRemovedToasterShown(true)
    }
  }, [isMutedAll, onMessage, isHostOrCohostAvailable, permissionRemovedToasterShown])

  useEffect(() => {
    if (permissionRemovedToasterShown) {
      setPermissionToTalkToasterShown(false)
    }
  }, [permissionRemovedToasterShown])

  useEffect(() => {
    if (permissionToTalkToasterShown) {
      setPermissionRemovedToasterShown(false)
    }
  }, [permissionToTalkToasterShown])

  useEffect(() => {
    if (localHandRaised && onMessage) {
      onMessage(`${upperCaseName} ${t('raisedTheirHand')}`, 'info')
    }
  }, [localHandRaised, onMessage, upperCaseName])

  return null
}

export default PermissionsToasterComp
