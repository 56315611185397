/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useState,createRef, useEffect,forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetSettingValue } from '../Services/settingReducer'
import { Button } from '../Components'
import { Image } from '../models/image.model'
import { useGetCurrentUserDetailsQuery, useUploadAvatarFileMutation } from '../Services/userApi'
import Loading from '../Components/base/loading/loading'
import { toastSuccess } from '../Components/toast';
import NewIcon from '../Components/base/icon/newIcons'
import Icon from '../Components/base/icon/icon'
import Cropper from 'react-cropper'

// const Cropper = lazy(() => import("react-cropper"));

/**
 * Formats a given number of bytes into a human-readable string.
 *
 * @param {number} bytes - The number of bytes to format.
 * @param {number} [decimals=2] - The number of decimal places to include in the formatted string.
 * @return {string} A string representing the formatted bytes (e.g. '1 KB', '2.5 MB', etc.).
 */
function formatBytesAvatar(bytes: number, decimals?: number): string {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024, dm = decimals || 2, sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const ImageCropperAvatar = forwardRef(
  (
    {toggle, isSelected, selectAvatar}: {toggle?: (isOpen?: boolean) => void, isSelected: boolean, selectAvatar: (value: boolean) => void},
    
    ref,
  ) => {
    const {t} = useTranslation()
    const imageSizeDataAvatar = useGetSettingValue('EVENT_IMAGE_SIZE')
    const {data: currentUser} = useGetCurrentUserDetailsQuery()

    const [imageAvatar] = useState<Image>()
    const [imageErrorAvatar, setImageErrorAvatar] = useState(false)
    const [isFilePickedAvatar, setIsFilePickedAvatar] = useState(false)
    const [upImgAvatar, setUpImgAvatar] = useState({} as any)

    const cropperRef = createRef<any>()
    const [croppedBlob, setCroppedBlob] = useState<any>()
    const [cropData, setCropData] = useState('')

    const [addImageAvatar, {isLoading: avatarImageUpdateLoading}] =
      useUploadAvatarFileMutation<any>()

    let imageSizeAvatar = Number(imageSizeDataAvatar)
    let imageSizeFormatAvatar = formatBytesAvatar(imageSizeAvatar)

    useEffect(() => {
      selectAvatar(!!cropData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cropData])
 
    /**
     * Saves the cropped image to the server.
     * Only triggers the image upload if the loading state is not set and the avatar has been selected.
     * If the image upload is successful, shows a success toast message, resets the cropped image blob to null,
     * resets the crop data, and closes the image cropper modal.
     * If the toggle function is provided, it is called to close the modal.
     */
    const saveImageAvatar = () => {
     
      let file: any = new File([croppedBlob], 'cropped.jpg', {
        type: 'image/jpeg',
      })

      if (!croppedBlob) {
        return
      }

      if (!avatarImageUpdateLoading && isSelected) {
        addImageAvatar({file, id: currentUser?.id}).then((response: any) => {

          cancelAvatar()
          toastSuccess(t('avatarImageUpdatedSuccessfully'))
          setCroppedBlob(undefined)
          setCropData('')
          closeOnSave()
          if (toggle) {
            toggle()
          }
        })
      }
    }

    /**
     * Resets the component state, but does not close the modal.
     * This is used when the user selects a new image, but then cancels the upload.
     */
    const cancelAvatar = () => {
      setImageErrorAvatar(false)
      setIsFilePickedAvatar(false)
      setUpImgAvatar({} as any)
      setCroppedBlob(undefined)
    }

    /**
     * Resets the component state and closes the modal after a successful save.
     * It sets imageErrorAvatar to false, setIsFilePickedAvatar to false, and resets the image to an empty object.
     * If the toggle function is provided, it is called to close the modal.
     */
    const closeOnSave = () => {
      setImageErrorAvatar(false)
      setIsFilePickedAvatar(false)
      setUpImgAvatar({} as any)
      if (toggle) {
        toggle()
      }
    }
    /**
     * Handles the event when a user selects a file to be uploaded as their avatar.
     * If the selected file is an image and is smaller than the maximum allowed image size,
     * sets the image loaded state to true and resets any image error state.
     * Otherwise, sets the image error state to true.
     * @param {Event} event - The event object containing the selected file.
     */
    const onUploadAvatarFileSelected = (event: any) => {
      if (
        imageSizeAvatar > event.target.files[0].size &&
        event.target.files[0].type.match('image.*')
      ) {
        setIsFilePickedAvatar(true)
        const readerAvatar = new FileReader()
        readerAvatar.addEventListener('load', () =>
          setUpImgAvatar(readerAvatar.result),
        )
        readerAvatar.readAsDataURL(event.target.files[0])
      } else {
        setImageErrorAvatar(true)
      }
    }

    /**
     * Gets the cropped image as a base64 data url and a blob.
     * Only runs if the cropper library has been initialized.
     * Sets the cropped image base64 data url and blob in the component state.
     */
    const getCropData = () => {
      if (typeof cropperRef.current?.cropper !== 'undefined') {
        setCropData(
          cropperRef.current?.cropper
            .getCroppedCanvas({
              width: 650,
              height: 650,
              imageSmoothingEnabled: true,
              imageSmoothingQuality: 'high',
            })
            .toDataURL(),
        )

        cropperRef.current?.cropper
          .getCroppedCanvas({
            width: 650,
            height: 650,
            imageSmoothingEnabled: true,
            imageSmoothingQuality: 'high',
          })
          .toBlob((blob: any) => {
            if (blob) {
              setCroppedBlob(blob)
            }
          })
      }
    }

    useImperativeHandle(
      ref,
      () => ({
        saveProcess: saveImageAvatar,
      }),
      [saveImageAvatar],

    )
    if (!currentUser) {
      return <Loading />
    }
    return (
      <div>
        {!isFilePickedAvatar && (
          <div>
            {!imageAvatar && !cropData && (
              <div
                id="btn_addYours"
                data-testid="btn_addYours"
                className=" flex flex-col items-center gap-6">
                <label htmlFor="file-upload-avatar" className="flex leading-3">
                  <input
                    type="file"
                    id="file-upload-avatar"
                    accept="image/*"
                    className="hidden"
                    // style={{display: 'none'}}
                    data-testid="profile_upload"
                    onChange={onUploadAvatarFileSelected}
                  />
                  <NewIcon
                    icon="ADDNEW"
                    size="xl-large"
                    stroke="#D3D3D3"
                    className="cursor-pointer"
                  />
                
                {imageErrorAvatar && (
                  <small id="image_errorMessage" className="absolute text-red-600 mt-16 whitespace-nowrap">
                    {' '}
                    * {t('warning!SizeExceedsThan')} {imageSizeFormatAvatar}
                  </small>
                )}
                </label>
              </div>
            )}
          </div>
        )}

        {cropData && (
          <div className="relative">
            <Button
              id={'btn_imageEdit'}
              onClick={() => setCropData('')}
              className="flex absolute top-0 right-0 z-10 cursor-pointer "
              color="close"
              rounded={true}
              size="circle">
              {<Icon icon="CLOSE" size="large" className="pb-4" />}
            </Button>
            <img
              src={cropData}
              alt="cropped"
              onClick={() => {
                selectAvatar(true)
              }}
              className={`w-18 h-18 p-1 border-2 rounded-full ${isSelected ?  'bg-primary' : ''}`} 
            />
          </div>
        )}
        {isFilePickedAvatar && (
          <div className="fixed inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="rounded overflow-hidden shadow-lg bg-white p-2 ">
              <>
                {/* <Suspense fallback={<Loading />}> */}
                  <Cropper
                    ref={cropperRef}
                    className='cropper-container'
                    initialAspectRatio={1}
                    // className=''
                    // preview=".img-preview"
                    src={upImgAvatar}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    guides={true}
                    autoCropArea={1}
                    aspectRatio={1}
                  />
                {/* </Suspense> */}
              </>
            
            <div className="flex justify-center mt-2 mb-2">
              <Button
                size="sm"
                color="footerButton"
                className="mr-2"
                data-testid="input_save"
                id="btn_ImageCropSave"
                onClick={() => {
                  getCropData()
                  closeOnSave()
                }}>
                {t('crop')}
              </Button>
              <Button
                id="btn_ImageCancel"
                size="sm"
                color="footerButton"
                onClick={cancelAvatar}>
                {t('cancel')}
              </Button>
            </div>
          </div>
            {/* <canvas
            ref={previewCanvasRefAvatar} hidden
            style={{ width: Math.round(completedCropAvatar?.width ?? 0), height: Math.round(completedCropAvatar?.height ?? 0) }}
          /> */}
          </div>
        )}
      </div>
    )
  }
)
export default ImageCropperAvatar
