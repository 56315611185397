/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import { useState } from "react";
import { useGetIPAddressQuery } from "../Services/allOthersApi";
import { useGetCognitoKeysQuery, useGetIPGeolocationKeyQuery, useGetReCaptchaQuery } from "../Services/envApi";
import { useGetSettingValue } from "../Services/settingReducer";

export const useSignUpHook = () => {
    // const { data: invite, isSuccess: inviteSuccess } = useGetInviteByTokenQuery(token ? token : '', { skip: !token });
    const { data: siteKey, isSuccess: siteKeySuccess } = useGetReCaptchaQuery();
    const { data: key, isSuccess: keySuccess } = useGetIPGeolocationKeyQuery();
    const { data: ipAddressData, isSuccess: ipAddressSuccess } = useGetIPAddressQuery(key, { skip : !key });
    const { isSuccess: cognitoSuccess } = useGetCognitoKeysQuery()
    // const [val, setVal] = useState<Initial>();
    // const { data: userData, isSuccess: userDataSuccess } = useGetUserDetailByEmailQuery(val ? val?.email : '', { 
    //     skip : !val?.email 
    // });
    
    const [loading, setLoading] = useState(true);

    if ( siteKeySuccess && keySuccess && ipAddressSuccess && cognitoSuccess) {
        if (loading) {
            setLoading(false);
        }
    }

    // if (inviteSuccess) {
    //     if (invite && !val?.email) {
    //         setVal({
    //             email: invite?.inviteEmail,
    //             firstName: '',
    //             lastName: '',
    //             phoneNumber: '',
    //             password: '',
    //             dateOfBirth: '',
    //             gender: Gender.Male,
    //             ageLimit: ageLimit
    //         });
    //     }

    //     if (!showScreen) {
    //         setShowScreen(true);
    //     }
    // }

    return { loading,  siteKey, ipAddressData };
}
