/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faColumns, faTh } from '@fortawesome/free-solid-svg-icons'
import { useCustomRoomContext } from 'livekit/room/CustomRoomContext'
import { useGetHostAndCoHost } from 'livekit/livekitHooks'
import { ToolTip } from 'base/tooltip/tooltip'
import { t } from 'i18next'

/**
 * A React component that displays the participant count and a dropdown menu to view participants, co-hosts, and hosts.
 * It also includes a search bar to filter participants by name.
 *
 * @return {JSX.Element} The rendered participant count component.
 */

function PinHostsButton() {
  const { viewType, setViewType } = useCustomRoomContext()
  const { hostAndCoHosts } = useGetHostAndCoHost()
  const [viewTypeName, setViewTypeName] = React.useState('')

  const togglePinnedLayout = () => {
    const newViewType = viewType === 'grid' ? 'hostHighlighted' : 'grid'
    setViewType(newViewType)
  }

  useEffect(() => {
    if (viewType === 'grid') {
      setViewTypeName('hostView')
    } else if (viewType === 'hostHighlighted') {
      setViewTypeName('gridView')
    }
  }, [viewType])

  useEffect(() => {
    if (hostAndCoHosts.length === 0) {
      setViewType('grid')
    } else if (hostAndCoHosts.length > 0) {
      setViewType('hostHighlighted')
    }
  }, [hostAndCoHosts.length])

  return (
    <>
      {hostAndCoHosts.length > 0 && (
        <ToolTip tip={t(`${viewTypeName}`)}>
          <div
            className="px-1 py-1 dropdown-trigger cursor-pointer lg:text-lg md:text-md sm:text-sm xs:text-xs"
            onClick={togglePinnedLayout}
          >
            <FontAwesomeIcon icon={viewType === 'grid' ? faColumns : faTh} />
          </div>
        </ToolTip>
      )}
    </>
  )
}

export default PinHostsButton
