/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import Icon from "../icon/icon";
import { ToolTip } from "../tooltip/tooltip";

interface TagProps {
  text: string;
  remove: any;
  disabled?: boolean;
  className?: string;
  error: any;
  keyId?: number;
}

/**
 * A single tag component for displaying a tag and an option to remove it.
 *
 * @param {string} text - The text to display in the tag.
 * @param {function(string)} remove - A function to call when the tag is removed.
 * @param {boolean} [disabled] - Whether the tag should be disabled.
 * @param {string} [className] - Additional CSS classes to apply to the tag.
 * @param {object} [error] - An error object to display as a tooltip.
 * @param {number} [keyId] - An ID to use as the key for the tag.
 *
 * @returns A React component that displays a single tag.
 */
export default function Tag({ text, remove, disabled, className, error, keyId}: TagProps) {
  /**
   * Handles the click event on the remove icon.
   * It will prevent the event from bubbling up and call the `remove` callback.
   * @param {any} e - The event object.
   */
  const handleOnRemove = (e: any) => {
    e.stopPropagation();
    remove(text);
  };
  
  return (
    <div
      className={`py-2 px-2 text-sm rounded-md flex flex-row items-center justify-between mb-2 ${
        !!error ? 'bg-red-500' : 'bg-primary'
      }`}>
      <span
        id={`invite-${keyId}`}
        data-testid={`invite-${keyId}`}
        className="flex items-center justify-between">
        {text}
      </span>

      <div className="flex items-center">
        {error && (
          <ToolTip tip={error.message} keyId={`error-${keyId}`}>
            <Icon
              icon="QUESTION"
              size="small"
              className="ml-3"
              stroke={'#fff'}
            />
          </ToolTip>
        )}

        {!disabled && (
          <div
            className="cursor-pointer"
            id={text}
            data-testid={text}
            onClick={handleOnRemove}>
            <Icon icon="CLOSE" size="x-small" className="ml-3" />
          </div>
        )}
      </div>
    </div>
  );
}