/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useSelector} from 'react-redux';
import {getCurrentUser} from '../Services/userReducer';
import {
  useGetUserByUserIdQuery,
  useGetUserDetailQuery,
} from '../Services/userApi';
import {useEffect, useState} from 'react';
import {Verified} from '../models/user.model';

/**
 * A custom React hook for viewing a user's profile.
 *
 * It fetches the user's data and detail by user ID, and determines whether the user allows messaging based on their settings and verification status.
 *
 * @param {string} userId - The ID of the user to view.
 * @return {object} An object containing the user's data, whether the user allows messaging, and the success status of the data fetching.
 */

export const useViewUserProfileHook = (userId: string) => {
  const currentUser = useSelector(getCurrentUser);
  const {data: userData, isSuccess: userDataSuccess} = useGetUserByUserIdQuery(
    userId ? userId : '',
    {skip: !userId},
  );
  const {data: userDetailcheck, isSuccess: userDetailSuccess} =
    useGetUserDetailQuery(currentUser.sub, {skip: !currentUser.sub});

  const [allowUserMessage, setAllowUserMessage] = useState<
    'ALLOW' | 'NOTALLOW'
  >();

  useEffect(() => {
    if (userData && userDetailcheck && currentUser) {
      const isEmailVerified = userDetailcheck?.email_verified === 'true';
      const isUserEmailVerified = currentUser.emailVerified === Verified.Complete;
      const isUserPhoneVerified = currentUser.phoneVerified === Verified.Complete;

      if (isEmailVerified && isUserEmailVerified && isUserPhoneVerified) {
        if (userData?.allowMessage) {
          setAllowUserMessage('ALLOW');
        } else {
          setAllowUserMessage('NOTALLOW');
        }
      } else {
        setAllowUserMessage('NOTALLOW');
      }
    }
  }, [userData, userDetailcheck, currentUser]);


  return {
    userDetailSuccess,
    allowUserMessage,
    userData,
    userDataSuccess,
  };
};
