/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {Meeting} from './../models/meeting.model';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getCurrentUser, getPreference} from '../Services/userReducer';
import {ledgerApi, useRazorPaySuccessMutation} from '../Services/ledgerApi';
import {useAppDispatch} from '../Store/hooks';
import {useGetPaymentQuery} from '../Services/allOthersApi';
import {useCreateParticipantMutation} from '../Services/meetingParticipantApi';
import {MeetingDetails} from '../models/meetingDetails.model';
import {Verified} from '../models/user.model';
import {useMeetingJoinHooks} from './AttendingHooks';
import {format} from '../Utils/format';
import {getCurrencyList} from '../Services/currencyListReducer';
import useRazorpay, {RazorpayOptions} from 'react-razorpay';
import {InviteStatus} from '../models/meetingParticipant.model';
import beemgLogo from '../Assets/Images/beeMG_logo-icon-notext-04.svg';

/**
 * A hook that handles the state and functionality of upcoming events.
 *
 * @param {MeetingDetails} meetingDetails - The details of the meeting.
 * @return {object} An object containing the number of guests, a function to change the number of guests, and the current user.
 */

export const useViewEventsUpcomingHooks = (meetingDetails?: MeetingDetails) => {
  const [numberOfGuests, setNumberOfGuests] = useState(1);
  const currentUser = useSelector(getCurrentUser);

  const changeNumberOfGuests = (e: any) => {
    if (e?.target?.value > 0) {
      setNumberOfGuests(e.target.value);
    }
  };

  return {numberOfGuests, changeNumberOfGuests, currentUser};
};

/**
 * A hook that handles the display and functionality of an upcoming event card.
 * It determines the display button based on the meeting details, user's status, and other factors.
 * It also handles payment processing and navigation to the payment screen.
 *
 * @param {MeetingDetails} meetingDetails - The details of the meeting.
 * @param {Meeting} meeting - The meeting object.
 * @param {number} numberOfGuests - The number of guests attending the meeting.
 * @return {object} An object containing the display button, user data, and other relevant information.
 */

export const useViewEventsDisplayEventCard = ({
  meetingDetails,
  meeting,
  numberOfGuests,
}: {
  meetingDetails: MeetingDetails;
  meeting: Meeting;
  numberOfGuests: number;
}) => {
  const preference = useSelector(getPreference);
  const currentUser = useSelector(getCurrentUser);
  const currenciesList = useSelector(getCurrencyList);
  const dispatch = useAppDispatch();
  const [Razorpay] = useRazorpay();

  const totalCostCurrency = currenciesList?.filter(
    currency => currency.code === preference.currency,
  );
  const totalCostApproxCurrency = currenciesList.filter(
    currency => currency.code === meeting.currency,
  );
  const [toggleCard, setToggleCard] = useState(false);
  const [totalCalculatedCost, setTotalCalculatedCost] = useState(0);
  const [totalCalculatedCostApprox, setTotalCalculatedCostApprox] = useState(0);
  const [navigateScreenLink, setNavigateScreenLink] = useState<string>();
  const [displayButton, setDisplayButton] = useState<
    | 'JOIN'
    | 'SOLDOUT'
    | 'PAYNOW'
    | 'PAID'
    | 'REGISTER'
    | 'REGISTERED'
    | 'COHOST'
    | 'ERROR'
    | 'INVITED'
    | 'LIMITREACHED'
  >();
  const [registerErrorLimit, setRegisterErrorLimit] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState('');
  const [razorpayOpen, setRazorpayOpen] = useState(false);

  const [
    registerFreeEvent,
    {
      isSuccess: registerSuccess,
      isError: registerError,
      isLoading: registerLoading,
    },
  ] = useCreateParticipantMutation<any>();
  const [razorPaySuccess] = useRazorPaySuccessMutation<any>();
  const {data: paymentDetailIndia} = useGetPaymentQuery('INR');
  const {joinTime} = useMeetingJoinHooks({meeting});

  useEffect(() => {
    if (
      meetingDetails?.isFreeAudioMeeting === false &&
      meeting?.meetingParticipant?.length === 0 &&
      currentUser?.id !== meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete
    ) {
      if (meeting.seatsAvailable >= numberOfGuests && !serverError) {
        setDisplayButton('PAYNOW');
      } else if (serverError) {
        setDisplayButton('ERROR');
      } else if (
        meeting.seatsAvailable < numberOfGuests ||
        serverError === 'BeeMG-ERR010'
      ) {
        setDisplayButton('SOLDOUT');
      }
    } else if (
      meetingDetails?.isFreeAudioMeeting === false &&
      meeting?.meetingParticipant?.length > 0 &&
      meeting?.meetingParticipant[0]?.isCohost === false &&
      currentUser?.id !== meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete &&
      !meeting?.meetingParticipant[0]?.inviteStatus
    ) {
      if (joinTime) {
        setDisplayButton('JOIN');
      } else {
        setDisplayButton('PAID');
      }
    } else if (
      meetingDetails?.isFreeAudioMeeting === false &&
      meeting?.meetingParticipant?.length > 0 &&
      meeting?.meetingParticipant[0]?.isCohost === false &&
      currentUser?.id !== meetingDetails?.host?.id &&
      meeting?.meetingParticipant[0]?.inviteStatus === InviteStatus.Accepted
    ) {
      setDisplayButton('PAID'); // For Invited & Accepted
    } else if (
      meetingDetails?.isFreeAudioMeeting === false &&
      meeting?.meetingParticipant?.length > 0 &&
      meeting?.meetingParticipant[0]?.isCohost === false &&
      currentUser?.id !== meetingDetails?.host?.id &&
      meeting?.meetingParticipant[0]?.inviteStatus === InviteStatus.Pending
    ) {
      setDisplayButton('INVITED');
    } else if (
      meetingDetails?.isFreeAudioMeeting === false &&
      meeting?.meetingParticipant?.length > 0 &&
      meeting?.meetingParticipant[0]?.isCohost === true &&
      currentUser?.id !== meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete
    ) {
      setDisplayButton('COHOST');
    } else if (
      meetingDetails?.isFreeAudioMeeting === true &&
      meeting?.meetingParticipant?.length > 0 &&
      meeting?.meetingParticipant[0]?.isCohost === true &&
      currentUser?.id !== meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete
    ) {
      setDisplayButton('COHOST');
    } else if (
      meetingDetails?.isFreeAudioMeeting === true &&
      meeting.meetingParticipant.length === 0 &&
      meeting.seatsAvailable > 0 &&
      currentUser?.id !== meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete
    ) {
      setDisplayButton('REGISTER');
    } else if (
      meetingDetails?.isFreeAudioMeeting === true &&
      meeting.meetingParticipant.length > 0 &&
      currentUser?.id !== meetingDetails?.host?.id &&
      currentUser?.verified !== Verified.NotComplete
    ) {
      setDisplayButton('REGISTERED');
    } else if (meeting.seatsAvailable === 0) {
      setDisplayButton('LIMITREACHED');
    }
  }, [
    meetingDetails,
    meeting,
    currentUser?.id,
    currentUser?.verified,
    numberOfGuests,
    joinTime,
    serverError,
  ]);

  const handleRegister = (meeting: any, joinAnonymous: boolean) => {
    const meetingRes = {
      id: meeting.id,
      joinAnonymous: joinAnonymous,
    };
    registerFreeEvent(meetingRes).then((res: any) => {
      if (res?.error?.data) {
        setRegisterErrorLimit(res.error.data.message);
      }
    });
  };

  useEffect(() => {
    if (numberOfGuests > 0) {
      const totalCost = format(
        totalCostCurrency[0]?.formatType,
        Math.round(
          (meeting.costLocal * numberOfGuests + Number.EPSILON) * 100,
        ) / 100,
      );
      const totalCostApprox = format(
        totalCostApproxCurrency[0].formatType,
        Math.round((meeting.cost * numberOfGuests + Number.EPSILON) * 100) /
          100,
      );
      setTotalCalculatedCost(totalCost);
      setTotalCalculatedCostApprox(totalCostApprox);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting, numberOfGuests]);

  const payment = async (
    id: string,
    meetingDetailsId: string,
    currency: string,
    joinAnonymous: boolean,
  ) => {
    try {
      const args = {
        id: id,
        meetingDetailsId: meetingDetailsId,
        currency: currency,
        guestCount: numberOfGuests,
        joinAnonymous: joinAnonymous,
      };
      if (!razorpayOpen) {
        setRazorpayOpen(true);

        // Dispatch the payment initiation action and wait for the response
        const res = await dispatch(ledgerApi.endpoints.payment.initiate(args));

        // Continue with the rest of your function using the response data
        if (res.data) {
          if (
            paymentDetailIndia &&
            paymentDetailIndia.currencyList.indexOf(currency) > -1
          ) {
            const options: RazorpayOptions = {
              key: res.data.key_id,
              amount: res.data.amount,
              currency: 'INR',
              name: res.data.name,
              description: 'BeeMG',
              image: beemgLogo,
              order_id: res.data.order_id,
              handler: rzpayResponse => {
                console.log(rzpayResponse);
                // Check if payment is successful (you might need to adjust this based on Razorpay response)
                if (rzpayResponse.razorpay_payment_id) {
                  // Call onPaymentSuccess with the relevant data
                  onPaymentSuccess(rzpayResponse);
                } else {
                  // Call onPaymentFailure with the relevant data
                  onPaymentFailure(rzpayResponse);
                  // Handle case when payment is not successful
                  console.error('Payment not successful:', rzpayResponse);
                }
              },
            };
            const rzpay = new Razorpay(options);
            rzpay.open();
          } else {
            if (!navigateScreenLink) {
              setNavigateScreenLink(res.data.url);
            }
          }
        } else if (res.error) {
          if (!navigateScreenLink) {
            setNavigateScreenLink(res.data.url);
          }
          setServerError(res.data.error.data.message);
        }
      }
    } catch (error) {
      console.error('Error during payment initiation:', error);
      // Handle errors as needed
    }
  };

  const onPaymentSuccess = (response: any) => {
    const args = {
      orderId: response.razorpay_order_id,
      paymentId: response.razorpay_payment_id,
    };
    const res = razorPaySuccess(args);
    if (!loading) {
      setLoading(true);
    }
    res.then((val: any) => {
      if (!navigateScreenLink) {
        if (loading) {
          setLoading(false);
        }
        setRazorpayOpen(false);
        setNavigateScreenLink('/s/payment/' + val.data.transactionId);
      }
    });
  };

  const onPaymentFailure = (response: any) => {
    const args = {
      orderId: response.error.metadata.order_id,
      paymentId: response.error.metadata.payment_id,
    };
    setRazorpayOpen(false); // Reset Razorpay open state
    dispatch(ledgerApi.endpoints.razorPayFail.initiate(args));
  };

  return {
    displayButton,
    currentUser,
    registerSuccess,
    handleRegister,
    preference,
    toggleCard,
    registerError,
    setToggleCard,
    totalCalculatedCost,
    totalCalculatedCostApprox,
    payment,
    navigateScreenLink,
    loading,
    serverError,
    registerErrorLimit,
    razorpayOpen,
    registerLoading,
  };
};

/**
 * A hook that handles the display of read more/less functionality for meeting details.
 *
 * @param {Object} meetingDetails - Optional meeting details object.
 * @return {Object} An object containing state variables and setters for read more/less functionality.
 */

export const useViewReadMore = ({
  meetingDetails,
}: {
  meetingDetails?: MeetingDetails;
}) => {
  const [showMoreLess, setShowMoreLess] = useState<boolean>(true);
  const [minLimit, setMinLimit] = useState<number>(3);
  const [readMoreToLess, setReadMoreToLess] = useState<boolean>(true);
  const [detailsLength, setDetailsLength] = useState<number>(35);

  useEffect(() => {
    if (showMoreLess) {
      setMinLimit(3);
    } else {
      if (meetingDetails && meetingDetails?.meetings?.length > 1) {
        setMinLimit(meetingDetails?.meetings?.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMoreLess]);

  return {
    detailsLength,
    setDetailsLength,
    minLimit,
    setShowMoreLess,
    showMoreLess,
    readMoreToLess,
    setReadMoreToLess,
  };
};
