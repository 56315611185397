/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import useWindowDimensions from '../../Components/base/carousel/whirligig/utils';
import Icon from '../../Components/base/icon/icon';
import {Meeting} from '../../models/meeting.model';
import {MeetingDetails, VideoType} from '../../models/meetingDetails.model';
import {getMyEventById} from '../../Services/myEventsReducer';
import {useAppSelector} from '../../Store/hooks';
import NewIcon from '../../Components/base/icon/newIcons';

/**
 * @description This component is used to display the event type icons in the my events tab.
 *              It takes two props: meetingDetail and meeting. The meetingDetail is the meeting
 *              details object that contains the videoType and isAnonymous properties. The meeting
 *              object is the meeting object that contains the language property.
 * @param {MeetingDetails} meetingDetail The meeting details object that contains the videoType and isAnonymous properties.
 * @param {Meeting} meeting The meeting object that contains the language property.
 * @returns {JSX.Element} The JSX element that displays the event type icons.
 */
export function DisplayEventTypeIcons({
  meetingDetail,
  meeting,
}: {
  meetingDetail: MeetingDetails;
  meeting?: Meeting;
}) {
  const {t} = useTranslation();
  const {width} = useWindowDimensions();
  const [meetingDetailDisplay, setMeetingDetailDisplay] =
    useState(meetingDetail);

  const meetingDetailFromState = useAppSelector(state =>
    getMyEventById(state, meetingDetail.id),
  );

  useEffect(() => {
    if (meetingDetailFromState) {
      setMeetingDetailDisplay(meetingDetailFromState);
    }
  }, [meetingDetailFromState]);

  const style = {
    eventDetailText: `items-center flex mr-2 ml-0.5 font-normal text-xs mb-2`,
  };

  return (
    <>
      {meetingDetailDisplay?.videoType === VideoType.Audio &&
        !meetingDetailDisplay.isFreeAudioMeeting && (
          <>
            <NewIcon icon="AUDIO" size={'x-small'} height="small" />
            <span
              data-testid="paidAudio_event"
              className={style.eventDetailText}>
              {t('audio')}
            </span>
          </>
        )}
      {meetingDetailDisplay?.videoType === VideoType.Audio &&
        meetingDetailDisplay.isFreeAudioMeeting && (
          <>
            <NewIcon icon="AUDIO" size={'x-small'} height="small" />
            <span
              data-testid="freeAudio_event"
              className={style.eventDetailText}>
              {t('freeAudioEvent')}
            </span>
          </>
        )}

      {meetingDetailDisplay?.videoType === VideoType.Both && (
        <>
          <NewIcon icon="VIDEO_CAM" size={'x-small'} height="small" />
          <span data-testid="video_event" className={style.eventDetailText}>
            {t('video')}
          </span>
        </>
      )}

      {meetingDetailDisplay?.isAnonymous && (
        <>
          <Icon icon="ANONYMOUS" size={'x-small'} height="small" />
          <span
            id={`txt_anonymous${meetingDetailDisplay?.id}`}
            data-testid="event_anonymous"
            className={style.eventDetailText}>
            {t('anonymous')}
          </span>
        </>
      )}

      {meeting?.language !== undefined && meeting?.language !== '' && (
        <>
          <Icon icon="LANG_B" size={'x-small'} height="small" />
          <span
            data-testid={`icon_lang${meeting.id}`}
            className={style.eventDetailText}>
            {meeting.language}
          </span>
        </>
      )}
    </>
  );
}
