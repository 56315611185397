/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { useParticipantContext } from '@livekit/components-react'
import React from 'react'

/**
 * Displays an audio level indicator based on the participant's audio level and speaking status.
 *
 * @return {JSX.Element} The audio level indicator component
 */

export default function AudioLevelIndicator() {
  const { audioLevel, isSpeaking } = useParticipantContext()

  return (
    <>
      <div
        className={`absolute transition-all ease-in-out justify-center items-center flex top-0 left-0 right-0 bottom-0 rounded-full overflow-hidden ${
          isSpeaking ? 'outline outline-yellow-400 outline-1 opacity-40  animate-audioLevel ' : ''
        }`}
      />
      <div
        className={`bg-orange-400 bg-opacity-60 absolute transition-all ease-in-out duration-300 delay-300 opacity-40 justify-center items-center flex top-0 left-0 right-0 bottom-0 rounded-full overflow-hidden ${
          audioLevel >= 0.08
            ? 'scale-110'
            : audioLevel >= 0.09
            ? 'scale-115'
            : audioLevel >= 0.1
            ? 'scale-120'
            : audioLevel >= 0.11
            ? 'scale-125'
            : audioLevel >= 0.12
            ? 'scale-130'
            : 'scale-140'
        }`}
      />
    </>
  )
}
