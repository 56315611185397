/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IPaginationElement } from '../Hooks/FetchDataHook';


    /**
     * Pagination component for pagination of events
     * @param pagination Pagination data provided by the FetchDataHook
     * @param isPageLoading Loading state of the page
     * @returns JSX.Element
     */
function Pagination({ pagination: page, isPageLoading }: { pagination: IPaginationElement, isPageLoading: boolean }) {

    const [pagination, setPagination] = useState<IPaginationElement>(page);

    const style = 'h-8 w-8 rounded-full text-black font-normal text-sm disabled:cursor-not-allowed mt-4';
    
    let _array: number[] = [];
    for (let i = pagination.pagination.displayStartPageNumber; i <= pagination.pagination.displayEndPageNumber; i++) {
        _array.push(i);
    }

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const currentPage = parseInt(searchParams.get('page') ?? '1') || 1;

    /**
     * Updates the URL query parameter to navigate to the given page.
     * This function ensures that the 'sort' query parameter is preserved if it exists.
     * @param page The page to navigate to.
     */
    const updatePageQueryString = (page: number) => {
        if(page <= pagination.pagination.totalPages && page > 0){
            const sort = searchParams.has('sort')
            if (searchParams.has('page')) {
                if (sort) {
                    navigate(`/s/events/my-events?page=${page}&sort=${searchParams.get('sort')}`)
                } else {
                    navigate(`/s/events/my-events?page=${page}`)
                }
            } else {
                if (sort) {
                    navigate(`/s/events/my-events?sort=${searchParams.get('sort')}`)
                } else {
                    navigate(`/s/events/my-events?page=${page}`)
                }
            }
        }
    }


    useEffect(() => {
        if (!isPageLoading && page) {
            setPagination(page)
        }
    }, [page, isPageLoading])

    if (pagination.pagination.totalPages <= 1) {
        return <></>
    }
    return (
        <div className='flex justify-center gap-1 mt-3'>
            <button data-testid="event_pagination_prev" className={`${style} ${pagination.pagination.displayStartPageNumber ? 'disabled:opacity-50' : ''}`} disabled={1 === pagination.pagination.pageIndex+1} onClick={() => updatePageQueryString(pagination.pagination.pageIndex)} >{'<'}</button>
            <button  id="btn_pagination1" data-testid="btn_pagination1" className={`${style} ${pagination.pagination.displayStartPageNumber ? 'disabled:opacity-50' : ''} ${1 === currentPage ? 'bg-BeeMG-yellow cursor-not-allowed' : ''}`} onClick={() => updatePageQueryString(1)} >1</button>
            {pagination.pagination.displayStartPageNumber > 2 && <span data-testid="event_pagination_start_dots" className='text-black font-semibold mt-4'>{'...'}</span>}
            {_array.map((item, index) => {
                if (item + 1 >= pagination.pagination.totalPages) {
                    return null
                }
                return <button id={`btn_pagination${item + 1}`} data-testid={`btn_pagination${item + 1}`} key={index} className={`${style} ${pagination.pagination.pageIndex === item ? 'bg-BeeMG-yellow cursor-not-allowed' : ''}`} onClick={() => updatePageQueryString(item + 1)}>{item + 1}</button>
            })}

            {pagination.pagination.displayEndPageNumber < pagination.pagination.totalPages - 1 && <span data-testid="event_pagination_end_dots" className='text-black font-semibold mt-4'>{'...'}</span>}
            <button data-testid={`btn_pagination${pagination.pagination.totalPages}`} className={`${style} ${pagination.pagination.displayEndPageNumber ? 'disabled:opacity-50' : ''} ${pagination.pagination.totalPages === currentPage ? 'bg-BeeMG-orange cursor-not-allowed' : ''}`} onClick={() => updatePageQueryString(pagination.pagination.totalPages)} >{pagination.pagination.totalPages}</button>
            <button data-testid="event_pagination_next" className={`${style} ${pagination.pagination.displayEndPageNumber ? 'disabled:opacity-50' : ''}`} disabled={pagination.pagination.totalPages  === currentPage} onClick={() => updatePageQueryString(pagination.pagination.pageIndex + 2)} >{'>'}</button>
        </div>
    )
}

export default Pagination