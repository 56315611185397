/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import useCheckOnlineHook from '../Hooks/CheckOnlineHook'

interface OnlineStatusProps {
  id: string
  className?: string
}

/**
 * A component to display a user's online status.
 *
 * This component takes an `id` which is the ID of the user whose online status is to be displayed.
 * It also takes an optional `className` which is a CSS class to apply to the indicator.
 *
 * The component displays a small circle which is green if the user is online, and red if the user is offline.
 *
 * @param {OnlineStatusProps} props The props object, which should contain the `id` and optional `className`.
 *
 * @returns {JSX.Element} A JSX element representing the online status indicator.
 */
const OnlineStatus = (props: OnlineStatusProps) => {
  const { id, className } = props
  const { isOnline } = useCheckOnlineHook(id)

  return (
    <>
      {isOnline !== null && (
        <div className={`rounded-full w-3 h-3 shadow-lg z-10 border-white border-[1.5px] ${isOnline ? 'bg-chat-accepted' : 'bg-chat-declined'} ${className}`} />
      )}
    </>
  )
}

export default OnlineStatus
