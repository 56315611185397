/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useParams } from 'react-router-dom'
import { ReportProfile } from '../../ViewProfile'
import Loading from '../../../Components/base/loading/loading'
import HostEvents from './HostEvents'
import UserDescription from '../UserDescription'
import UserAvatarVideo from '../UserAvatarVideo'
import UserNameAndShare from '../UserNameAndShare'
import UserInterests from '../UserInterests'
import UserSocials from '../UserSocials'
import { useViewHostProfileHook } from '../../../Hooks/ViewHostProfileHook'

/**
 * Displays the profile of a host, including their information, events, and reviews.
 *
 * @returns {JSX.Element} The JSX element representing the host profile.
 */
function ViewHost() {
  const { id } = useParams()
  const { hostData, checkVerified } = useViewHostProfileHook(id ? id : '')

  if (!hostData) {
    return <div className='flex flex-1 h-[80vh] w-full justify-center items-center'>
      <Loading />
    </div>
  }

  return (
    <div className="w-full">
      <UserAvatarVideo user={hostData} />
      <div className='flex flex-col gap-y-4 justify-center items-center max-w-70 mx-auto mt-[12vh] px-6 pb-[2rem]'>
        <UserNameAndShare user={hostData} checkVerified={checkVerified} />
        <UserSocials user={hostData} />
        <UserDescription user={hostData} />
        <UserInterests user={hostData} />
        <HostEvents />
        <ReportProfile userId={hostData?.id} userData={hostData} hideBlock={true} />
      </div>
    </div>
  )
}

export default ViewHost
