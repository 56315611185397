/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {ChatMessage} from '../Store/Chat/ChatModel';

export enum MessageType {
  AddUser = 'add',
  Text = 'T',
  VideoRequest = 'VR',
  AddToGroup = 'ATG',
  RemoveFromGroup = 'RFG',
  AllOnlineUsers = 'AOU',
  UserOffline = 'UO',
  Acknowledgement = 'ACK',
  VideoMeetingAccept = 'VMA',
  VideoMeetingReject = 'VMRJ',
  UpdateUserStatus = 'UUS',
  GetUserStatus = 'GUS',
  UpcomingMeeting = 'UM',
  MeetingNotificationCount = 'MNC',
  WebCommand = 'WC',
}

export enum WebCommandTypes {
  ProfileUpdate = 'ProfileUpdate',
  RFMUpdate = 'RFMUpdate',
  BlockingUsersUpdate = 'BlockingUsersUpdate',
}

export interface WebCommandMessage {
  type: WebCommandTypes;
  fromUserId: string;
  toUserId?: string;
}

export interface AddToGroupMessage {
  userId: string;
  meetingId: string;
}

export interface RemoveFromGroupMessage {
  userId: string;
  meetingId: string;
}

export interface AddUserMessage {
  userId: string;
  token: string;
}

export interface AllOnlineUsers {
  userIds: string[];
}

export interface UserOffline {
  userId: string;
  meetingId: string;
}

export interface UserStatusMessage {
  userId: string;
  isOnline: boolean;
  lastSeen: string;
}

export interface GetUserStatusMessage {
  fromUserId: string;
  userId: string;
}

export interface AcknowledgementMessage {
  userId: string;
  type: MessageType;
  success: boolean;
}

export type WebSocketMessageTypes =
  | ChatMessage
  | AddToGroupMessage
  | RemoveFromGroupMessage
  | AddUserMessage
  | AllOnlineUsers
  | UserOffline
  | UserStatusMessage
  | GetUserStatusMessage
  | AcknowledgementMessage
  | WebCommandMessage
  | string
  | number;

export interface Message {
  type: MessageType;
  message: WebSocketMessageTypes;
}
