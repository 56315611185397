/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { ReactNode } from "react";

/**
 * A component to display a green status.
 *
 * The component renders a span tag with the given children.
 *
 * @param {ReactNode} children - The children of the component.
 *
 * @returns {JSX.Element} The JSX element representing the component.
 */
export default function StatusGreen({ children }: { children: ReactNode }) {
    return <span className="text-lg font-semibold text-BeeMG-green">{children}</span>
}