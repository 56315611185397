/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import Avatar from '../../Components/base/avatar/avatar'
import Icon from '../../Components/base/icon/icon'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import DisplayName from '../../Components/base/user-name/DisplayName'
import useCheckOnlineHook from '../../Hooks/CheckOnlineHook'
import { useChatUserBlockedHook } from '../../Hooks/MessageHooks'
import { useGetPreferenceByIdQuery } from '../../Services/preferenceApi'
import { ChatUserData } from '../../Store/Chat/ChatModel'
import { getChatUsersList, getCurrentChatUser } from '../../Store/Chat/chat'
import { useAppSelector } from '../../Store/hooks'
import { getTimezoneOffsetString } from '../../Utils/helper'

/**
 * ChatProfileAndSearch component renders a list of users with whom the user has open chat.
 * Each user is displayed as a row with avatar, name, and online status.
 * If the user has blocked the other user, the row is grayed out.
 * If the user is currently online, a green dot is displayed to the right of their name.
 * The component is hidden on mobile devices.
 */
function ChatProfileAndSearch() {
  const { t } = useTranslation()
  const chatUsersList = useAppSelector(getChatUsersList)
  const { isBlockedByMe, isBlockedMe, gettingAllBlockedData } = useChatUserBlockedHook()

  return (
    <div className="hidden md:flex w-[35rem] h-full">
      {chatUsersList && !gettingAllBlockedData &&
        <div className='hidden md:flex flex-col gap-y-3 flex-1'>
          <div id="title_message" className="text-xl md:text-2xl font-bold px-3">{t('messages')}</div>
          <div className='flex-1 h-full w-full hidden md:flex overflow-y-scroll'>
            {chatUsersList &&
              <div data-testid={`usersList${chatUsersList.length}`} className="bg-white  w-full hidden md:flex">
                <div className="h-full w-full overflow-y-auto">
                  {chatUsersList?.map((value: ChatUserData) => {
                    return <ChatProfile chatUser={value} key={value.chatUser?.id} isBlockedByMe={isBlockedByMe} isBlockedMe={isBlockedMe} />
                  })}
                </div>
              </div>}
          </div>
        </div>}
    </div>
  )
}

  /**
   * Renders a single chat profile item for the list of chat profiles.
   * The component shows the user's avatar, name, online status, and unread message count.
   * If the user is blocked by the current user, the row is grayed out.
   * If the user is currently online, a green dot is displayed to the right of their name.
   * The component also shows the user's last message if it exists.
   * The component is a link to the chat box with the user.
   * @param {object} props Props passed to the component.
   * @param {boolean} props.isBlockedByMe Whether the user is blocked by the current user.
   * @param {boolean} props.isBlockedMe Whether the user has blocked the current user.
   * @param {ChatUserData} props.chatUser The user data.
   * @returns JSX element representing the chat profile item.
   */
function ChatProfile(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isBlockedByMe, isBlockedMe, chatUser } = props;
  const currentChatUser = useSelector(getCurrentChatUser)

  const showOnlineStatus = !isBlockedByMe(chatUser?.chatUser?.id) && !isBlockedMe(chatUser?.chatUser?.id)
  const showAvatar = !isBlockedMe(chatUser?.chatUser?.id)
  const { isOnline } = useCheckOnlineHook(chatUser?.chatUser?.id)

  const styles = {
    chatUser: 'truncate focus:bg-gray-200 cursor-pointer m-1 flex rounded-xl border-2 border-transparent hover:border-gray-300 items-center text-sm focus:outline-none focus:border-gray-200 transition duration-150 ease-in-out',
    chatUserActive: 'truncate focus:bg-gray-200 cursor-pointer m-1 flex rounded-xl border-gray-300 items-center text-sm focus:outline-none border-2 border-gray-200 transition duration-150 ease-in-out bg-gray-200',
  }

  /**
   * Navigates to the view profile page for the given user ID.
   */
  const handleClick = () => {
    navigate(`/s/viewProfile/${chatUser?.chatUser?.userId}`)
  }

  return (
    <NavLink to={`/s/chat-box/${chatUser?.chatUser?.id}`}
      className={currentChatUser?.id === chatUser?.chatUser?.id ? styles.chatUserActive : styles.chatUser}
    >
      <div
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleClick()
        }}
        className='relative hover:scale-110 transition-all min-w-[6rem] min-h-[5rem] flex flex-row items-center justify-center'>
        {showOnlineStatus && <div id={`avatarOnlineStatus_${currentChatUser?.id}`} className={`h-6 w-1 ${showOnlineStatus && isOnline !== null && isOnline ? 'bg-chat-accepted' : 'bg-transparent'} rounded-lg mr-1`} />}
        <div id={`btn_avatarImg_${chatUser?.chatUser?.userId}`} className={`${showAvatar && showOnlineStatus && chatUser?.unreadMessage ? 'rounded-full p-0.5 bg-white border-primary border-[3px]' : 'p-0.5'}`}>
          {showAvatar ? <Avatar
            size="chatScreen"
            anonymous={chatUser.chatUser.anonymous}
            avatar={chatUser?.chatUser.avatar}
            images={chatUser?.chatUser.image}
            displayChar={chatUser.chatUser.anonymous ? chatUser.chatUser.nickName : chatUser.chatUser.firstName}
          /> :
            <Avatar
              size="chatScreen"
            />
          }
        </div>
      </div>

      <div className='flex flex-row justify-between w-full'>

        <div className="flex flex-col truncate ml-1 md:text-sm lg:text-lg text-xs flex-1">
          <div className='flex flex-row justify-between items-center'>
            <div className="flex">
              <div id={`chatProfile_${chatUser?.chatUser?.id}`} className={`ml-0.5 font-semibold text-base truncate max-w-36 leading-[1.4rem]`}>
                <DisplayName user={chatUser?.chatUser} />
              </div>
              {showAvatar && showOnlineStatus && chatUser?.unreadMessage ? (
                <div className='flex items-center'>
                <div className="bg-chat-accepted px-2 rounded-full text-white text-xs ml-1">
                {chatUser?.unreadMessage}
                </div>
                </div>
              ) : null}
            </div>
          </div>
          {showAvatar && showOnlineStatus && <div className={`ml-0.5  leading-none ${chatUser?.unreadMessage ? 'text-primary' : 'text-gray-600'} truncate font-normal text-xs max-w-36`}>
            {chatUser?.message ? chatUser.message : t('videoRequest')}
          </div>}
        </div>

        {/* !TODO commented below lines for future use */}
        {/* location details hided as per new design */}
        {/* <div className='flex flex-0.2 flex-col items-end justify-between'>
          {showOnlineAndAvatar && <OnlineStatusText id={chatUser?.chatUser?.id} />}
          <TimezoneOffString timezone={chatUser?.chatUser?.timezone} timezoneUtc={chatUser?.chatUser?.timezoneUtc} id={chatUser?.chatUser?.id} />
        </div> */}
      </div>
    </NavLink>
  )
}

export default ChatProfileAndSearch


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TimezoneOffString = ({ timezone, timezoneUtc, id }: { timezone: string, timezoneUtc: string, id: string }) => {
  const { data: userPreference } = useGetPreferenceByIdQuery({ id }, { skip: !!timezoneUtc && !!timezone })

  if (timezoneUtc && timezone) {
    return <ToolTip tip={`${timezone} ${getTimezoneOffsetString({ timezoneUtc })}`} >
      <Icon icon="CLOCK_BLACK" size="x-small" />
    </ToolTip>
  }

  if (!userPreference?.timezoneUtc) {
    return null;
  }

  return (<ToolTip tip={`${userPreference?.timezone} ${getTimezoneOffsetString({ timezoneUtc: userPreference.timezoneUtc })}`} >
    <Icon icon="CLOCK_BLACK" size="x-small" />
  </ToolTip>)
}