/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import Avatar from "../../Components/base/avatar/avatar";
import Icon from "../../Components/base/icon/icon";
import useToggle from "../../Components/_utils/useToggle";
import { User } from "../../models/user.model";
import EditIcon from "./EditIcon";
import UploadUserImage from "./UserProfile/UploadUserImage";
import UserVideo from "./UserVideo";
// import { lazy, Suspense } from "react";
// import Loading from "../../Components/base/loading/loading";
import UploadCoverVideo from "./UserProfile/UploadCoverVideo";

// const UploadCoverVideo = lazy(() => import("./UserProfile/UploadCoverVideo"));

interface UserAvatarVideoProps {
  user: User;
  editMode?: boolean;
}

  /**
   * Component to display a user's avatar and video.
   * @param {UserAvatarVideoProps} props
   * @param {User} props.user - The user object.
   * @param {boolean} [props.editMode=false] - Whether edit mode is on.
   * @returns {JSX.Element} The JSX element representing the component.
   */
const UserAvatarVideo = (props: UserAvatarVideoProps) => {
  const { user, editMode = false } = props;

  const { isOpen: videoModalOpen, toggle: toggleVideoModalOpen } = useToggle()
  const { isOpen: imageModalOpen, toggle: toggleImageModalOpen } = useToggle()

  /**
   * Toggles the video upload modal open when the user clicks the edit button on the video component.
   * @param {React.MouseEvent<HTMLElement>} e - The mouse event when the user clicks the button.
   */
  const handleEditVideo = (e: React.MouseEvent<HTMLElement>) => {
    if (!videoModalOpen)
      toggleVideoModalOpen(true);
  }

  /**
   * Toggles the image upload modal open when the user clicks the edit button on the image component.
   * @param {React.MouseEvent<HTMLElement>} e - The mouse event when the user clicks the button.
   */
  const handleEditImage = (e: React.MouseEvent<HTMLElement>) => {
    if (!imageModalOpen)
      toggleImageModalOpen(true);
  }

  return (
    <div className="relative flex justify-center items-center">
      {editMode && !imageModalOpen && <div className="absolute right-5 top-5 z-10">
        <EditIcon keyId="userVideo" onClick={handleEditVideo} />
        {/* <Suspense fallback={<Loading />}> */}
          <UploadCoverVideo toggleVideoModalOpen={toggleVideoModalOpen} videoModalOpen={videoModalOpen} />
        {/* </Suspense> */}
      </div>}

      {/* User Video */}
      <div className={`w-full h-[50vh] flex flex-1 ${!user?.userVideo ? "bg-gray-200 justify-center items-center" : ""}`}>
        {!user?.userVideo && <Icon icon="VIDEO_WHITE" className='h-[10vh] w-[10vh]' />}
        {user?.userVideo && <UserVideo videoFile={user?.userVideo} />}
      </div>

      {/* User Image */}
      <div className="absolute -bottom-[10vh]">
        <div className='rounded-full p-2 bg-white relative'>
          {editMode && <div className="absolute bottom-2 right-2 z-[2]">
            <EditIcon keyId="userImg" onClick={handleEditImage} />
            <UploadUserImage toggleImageModalOpen={toggleImageModalOpen} imageModalOpen={imageModalOpen} />
          </div>}
          <Avatar size='large' images={user.image} anonymous={false} />
        </div>
      </div>
    </div>
  )
}

export default UserAvatarVideo;
