/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import LogoGIF from "../../../Assets/Images/beemg_logo.gif"

/**
 * A component that displays a loading spinner.
 *
 * This component displays the BeemG logo spinning round in a circle. It is used in
 * places where the app is waiting for data to load.
 *
 * @returns A JSX element that displays a loading animation.
 */
function Loading() {
    return (
        <div className="flex justify-center" id="load_data" data-testid="load_data">
            <img src={LogoGIF} alt="loading..." className="h-14 w-14" style={{ objectFit: 'contain' }} />
        </div>
    )
}

export default Loading;