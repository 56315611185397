/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import TimeField from 'react-simple-timefield'
import NewIcon from '../../Components/base/icon/newIcons'
import { useMeetingTimerHook } from '../../Hooks/MeetingTimerHooks'
import { flushSync } from 'react-dom'
import { enGB, ja } from 'date-fns/locale'
import { DisplayFormattedDate } from '../../Components/formattedDateAndTime'
import { format } from 'date-fns'

type MeetingTimerProps = {
  startDateTime?: string
  endDateTime?: string
  duration?: number
  showDatePicker?: boolean
  onFinalSelection: (
    selectedStartDateTime: string,
    selectedEndDateTime: string,
  ) => void
  keyId?: string
  disabled?: boolean
  index?: number
  RFMLayout?: boolean
  timeZone?: any
}

const date = new Date()
const maxDate = new Date(date.setMonth(date.getMonth() + 6))

/**
 * @param {UTC Date} startDateTime   ISO 8601 format  2020-01-01T00:00:00.000Z
 * @param {UTC Date} endDateTime    ISO 8601 format  2020-01-01T00:00:00.000Z
 * @param {string} timezone
 */

export const MeetingTimer = forwardRef(
  (
    {
      duration,
      onFinalSelection,
      showDatePicker = true,
      keyId,
      disabled = false,
      index,
      RFMLayout = false,
      timeZone,
    }: MeetingTimerProps,
    ref,
  ) => {
    const { t, i18n } = useTranslation()
    // const languageLocale = i18n.language === 'en' ? enGB : ja;
   const languageLocale = enGB;
    const {
      startDate,
      startTime,
      setStartTime,
      endTime,
      setEndTime,
      startDateTimeString,
      endDateTimeString,
      setStartAndEndDateTime,
      onStartDateChange,
      endDate,
      setStartDateTimeString,
      setEndDateTimeString,
      isTwoDays
    } = useMeetingTimerHook({ duration })

    useEffect(() => {
      flushSync(() => {
          if (startDate && endDate) {
              setStartDateTimeString(startDate.toISOString())
              setEndDateTimeString(endDate.toISOString())
              // updateDuration()
          }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

    useEffect(() => {
      if (startDateTimeString && endDateTimeString) {
        onFinalSelection(startDateTimeString, endDateTimeString)
      }
    }, [startDateTimeString, endDateTimeString])

    useImperativeHandle(ref, () => ({
    /**
     * Set the start and end dates for the MeetingTimer component
     * @param {{startDateTime: string, endDateTime: string}} param
     * @param {string} param.startDateTime ISO 8601 format  2020-01-01T00:00:00.000Z
     * @param {string} param.endDateTime ISO 8601 format  2020-01-01T00:00:00.000Z
     */
      setStartDateAndEndDate({
        startDateTime,
        endDateTime,
      }: {
        startDateTime: string
        endDateTime: string
      }) {
        setStartAndEndDateTime(startDateTime, endDateTime)
      },
    }))

    return (
      <>
        <div
          className={
            RFMLayout === true
              ? 'flex flex-wrap md:flex-row py-2 flex-col justify-between w-full'
              : 'flex gap-y-2 flex-col justify-between lg:w-[21.5rem] w-full'
          }>
          {index !== undefined && RFMLayout === true && (
            <span
              id={`chk_option${index + 1}`}
              className="items-center place-self-center font-medium whitespace-nowrap ">
              {t('session')} {index}
            </span>
          )}
          {showDatePicker && RFMLayout === true && (
            <>
              <div className="w-28 place-self-center">
                <ReactDatePicker
                  locale={languageLocale}
                  name="react-datepicker-plain"
                  id={`input_date_${keyId}`}
                  data-testid={`input_date_${keyId}`}
                  selected={startDate}
                  onChange={selectedDate => {
                    setStartTime('00:00')
                    setEndTime('00:00')
                    onStartDateChange(selectedDate)
                  }}
                  className="disabled:bg-gray-200  disabled:text-gray-400 h-8"
                  disabled={disabled}
                  // highlightDates={highlightDates}
                  placeholderText={t('enterDate') + ' *'}
                  minDate={new Date()}
                  maxDate={maxDate}
                  autoComplete="off"
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div className="flex items-center px-2 py-2">
                      <span className="text-lg text-gray-700">
                         <DisplayFormattedDate displayDate={date} dateFormat='MMMM yyyy'/>
                      </span>

                      <div className="space-x-2">
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                          type="button"
                          className={`
                                    ${prevMonthButtonDisabled &&
                            'cursor-not-allowed opacity-50'
                            }
                                    inline-flex p-1 w-6 justify-center text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-orange-300
                                `}>
                          {' <'}
                        </button>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                          type="button"
                          className={`
                                    ${nextMonthButtonDisabled &&
                            'cursor-not-allowed opacity-50'
                            }
                                    inline-flex p-1 w-6 justify-center text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-orange-300
                                `}>
                          {' >'}
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </>
          )}
          {showDatePicker && RFMLayout === false && (
            <>
              <div className="relative">
                <ReactDatePicker
                  name="react-datepicker-plain"
                  locale={languageLocale}
                  id={`input_date_${keyId}`}
                  data-testid={`input_date_${keyId}`}
                  selected={startDate}
                  onChange={selectedDate => {
                    setStartTime('00:00')
                    setEndTime('01:00')
                    onStartDateChange(selectedDate)
                  }}
                  className="disabled:bg-gray-200 disabled:text-gray-400 h-8 rounded-md"
                  disabled={disabled}
                  // highlightDates={highlightDates}
                  placeholderText={t('enterDate') + ' *'}
                  minDate={new Date()}
                  maxDate={maxDate}
                  autoComplete="off"
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div className="flex items-center px-2 py-2">
                      <span className="text-lg text-gray-700">
                        <DisplayFormattedDate displayDate={date} dateFormat='MMMM yyyy'/>
                      </span>

                      <div className="space-x-2">
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                          type="button"
                          className={`
                                    ${prevMonthButtonDisabled &&
                            'cursor-not-allowed opacity-50'
                            }
                                    inline-flex p-1 w-6 justify-center text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-orange-300
                                `}>
                          {' <'}
                        </button>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                          type="button"
                          className={`
                                    ${nextMonthButtonDisabled &&
                            'cursor-not-allowed opacity-50'
                            }
                                    inline-flex p-1 w-6 justify-center text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-orange-300
                                `}>
                          {' >'}
                        </button>
                      </div>
                    </div>
                  )}
                />
                <div className="absolute top-[0.30rem] right-4">
                  <NewIcon icon="CALENDER" stroke='#545454'/>
                </div>
              </div>
            </>
          )}

          {RFMLayout === false && (
            <div className="flex md:flex-row items-center gap-x-2">
              {/* From time */}
              <TimeField
                input={
                  <input
                    type="text"
                    data-testid={`input_startAt_${keyId}`}
                    id={`input_startAt_${keyId}`}
                    className="text-base h-8 w-32 md:w-[9.6rem] rounded-md md:py-1.5 py-1 bg-white text-gray-400 placeholder-gray-400 focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 disabled:bg-gray-200 disabled:text-gray-400"
                    disabled={disabled}
                  />
                }
                value={startTime}
                onChange={(e, val) => setStartTime(val)}
              />
              {/* To time */}
              <>
                <span className="md:w-auto xs:w-16 sm:w-16 "> {t('to')}</span>
                <TimeField
                  input={
                    <input
                      type="text"
                      data-testid={`input_endAt_${keyId}`}
                      id={`input_endAt_${keyId}`}
                      className="text-base h-8 w-32 md:w-[9.6rem] rounded-md md:py-1.5 py-1 bg-white text-gray-400 placeholder-gray-400 focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 disabled:bg-gray-200 disabled:text-gray-400"
                      disabled={disabled}
                    />
                  }
                  value={endTime}
                  onChange={(e, val) => setEndTime(val)}
                />
              </>
              <div className="w-3 ml-1" />
              <div className="text-black text-sm">{timeZone}</div>
            </div>
          )}
          {RFMLayout === true && (
            <div className="flex md:flex-row items-center gap-x-2">
              {/* From time */}
              <TimeField
                input={
                  <input
                    type="text"
                    data-testid={`input_startAt_${keyId}`}
                    id={`input_startAt_${keyId}`}
                    className="text-base w-32 md:w-20 rounded-md md:py-1.5 py-1 bg-white text-gray-400 placeholder-gray-400 focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 disabled:bg-gray-200 disabled:text-gray-400"
                    disabled={disabled}
                  />
                }
                value={startTime}
                onChange={(e, val) => setStartTime(val)}
              />
              {/* To time */}
              <>
                <span className="md:w-auto xs:w-16 sm:w-16 "> {t('to')}</span>
                <div className="flex flex-col items-center">
                  {RFMLayout===true && isTwoDays && <span className="text-xs">&nbsp; </span>} 
                  <TimeField
                    input={
                      <input
                        type="text"
                        data-testid={`input_endAt_${keyId}`}
                        id={`input_endAt_${keyId}`}
                        className="text-base w-32 md:w-20 rounded-md md:py-1.5 py-1 bg-white text-gray-400 placeholder-gray-400 focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 disabled:bg-gray-200 disabled:text-gray-400"
                        disabled={disabled}
                      />
                    }
                    value={endTime}
                    onChange={(e, val) => setEndTime(val)}
                  />
                  {RFMLayout===true && isTwoDays && endDateTimeString &&<span data-testid='nextDate' className="text-xs">{format(Date.parse(endDateTimeString), 'yyyy-MM-dd')} </span>}

                </div>

              </>
            </div>
          )}
        </div>
      </>
    )
  },
)
