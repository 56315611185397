/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import useToggle from "../../Components/_utils/useToggle"
import Button from '../../Components/base/button/button'
import Field from "../../Components/base/field/field"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../Components/base/modal/modal"
import { toastSuccess } from '../../Components/toast'
import { useAttendingPartialCancellationHooks, usePartialCancellationHook } from "../../Hooks/AttendingPartialCancellationHook"
import { MeetingParticipant } from "../../models/meetingParticipant.model"

type Props = {
  meeting: any,
  closeModal: (e: any) => void
}

/**
 * The PartialCancellationRequestModal component renders a modal dialog
 * to request for partial cancellation of an event. The component renders
 * a form with a text area for the user to enter the reason for cancellation
 * and a list of guests to be cancelled. The component also renders a button
 * to save the cancellation request.
 * @prop {Meeting} meeting - The meeting object.
 * @prop {Function} closeModal - A function to close the modal dialog.
 * @returns {JSX.Element} The PartialCancellationRequestModal component.
 */
export function PartialCancellationRequestModal({ meeting, closeModal }: Props) {
  const { t } = useTranslation()
  const { toggle } = useToggle();
  const changeToggle = (e: any) => closeModal(e)
  const { allInviteData, selectedGuests, inviteSuccess, setChecked, handleSave, createPartialCancellationSuccess, setSelectedGuests, register, errors, isValid, trigger } = useAttendingPartialCancellationHooks({ meeting, closeModal: closeModal })
  const [ sendButtonDisabled, setSendButtonDisabled] = useState(false);

  useEffect(() => {
    if (createPartialCancellationSuccess) {
      toastSuccess(t('cancellationProcessedSuccessfully'))
    }
  }, [createPartialCancellationSuccess])

  useEffect(() => {
    setSelectedGuests([])
  }, [])

  if (!inviteSuccess) {
    return <></>
  }

  return (

    <Modal isOpen={true} toggle={toggle} closeOnClickOutside={true} position="semiLarge">
      <ModalHeader>{t('requestForPartialCancellation')}</ModalHeader>
      <ModalBody>
        <Field
          {...register('reason')}
          name="reason"
          type="textarea"
          id="input_reason"
          data-testid="input_reason"
          placeholder={t(`specificReasonsforCancellationIfAny`) + '...'}
          error={errors?.reason?.message}
        />
        <div className='text-md font-semibold mt-2'>{t('selectUsersForCancellation')}:</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="col-span-1">
            <div className="flex flex-wrap gap-4 flex-1" >
              {allInviteData?.filter((_, i) => i % 2 === 0).map((inviteGuest: MeetingParticipant, index: number) => (
                <div className="flex items-center gap-2 w-full" key={inviteGuest.id}>
                  <Field
                    onChange={() => {setChecked(inviteGuest); ; setSendButtonDisabled(true)}}
                    disabled={!!inviteGuest.cancelationRequest}
                    id={!!inviteGuest.cancelationRequest ? `cancelledInvite${index}` : `invite${index}`}
                    defaultValue={inviteGuest.id}
                    name="terms"
                    type="checkbox"
                  />
                  <label htmlFor={inviteGuest.id} className="break-all">
                    {!inviteGuest.user && inviteGuest?.invitedUser && <InvitedUserCard keyId={index} inviteGuest={inviteGuest} />}
                    {inviteGuest.user && <UserCard keyId={index} inviteGuest={inviteGuest} />}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex flex-wrap gap-4 flex-1">
              {allInviteData?.filter((_, i) => i % 2 !== 0).map((inviteGuest: MeetingParticipant, index: number) => (
                <div className="flex items-center gap-2 w-full" key={inviteGuest.id}>
                  <Field
                    onChange={() => {setChecked(inviteGuest); ; setSendButtonDisabled(true)}}
                    disabled={!!inviteGuest.cancelationRequest }
                    id={!!inviteGuest.cancelationRequest ? `cancelledInvite${index}` : `invite${index}`}
                    defaultValue={inviteGuest.id}
                    name="terms"
                    type="checkbox"
                  />
                  <label htmlFor={inviteGuest.id} className="break-all">
                    {!inviteGuest.user && inviteGuest?.invitedUser && <InvitedUserCard keyId={index} inviteGuest={inviteGuest} />}
                    {inviteGuest.user && <UserCard keyId={index} inviteGuest={inviteGuest} />}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

      </ModalBody>
      <ModalFooter>
        <Button id="btn_cancel" onClick={changeToggle} color="transparent">{t('close')}</Button>
        {selectedGuests && (
          <Button id="btn_ok" onClick={(e: any) => {
            trigger('reason');
            !errors?.reason?.message && isValid && handleSave(e);
          }}
            color="filterData" disabled={!sendButtonDisabled} autoFocus>{t('send')}</Button>
        )}
      </ModalFooter>
    </Modal>
  )

  /**
   * InvitedUserCard is a component that displays the email of the invited user,
   * and whether the user has requested or been cancelled.
   * @param {MeetingParticipant} inviteGuest - The meeting participant object.
   * @param {number} keyId - The key id of the component.
   * @returns {JSX.Element} The InvitedUserCard component.
   */
  function InvitedUserCard({ inviteGuest, keyId }: { inviteGuest: MeetingParticipant, keyId: number }) {
    const { partialCancel } = usePartialCancellationHook({ inviteGuest })
    return (
      <div className="gap-1">
        <span id={`btn_notify${keyId}`}>{inviteGuest?.invitedUser?.inviteEmail}</span>
        {partialCancel === 'REQUESTED' && (
          <span id={`txt_requested${keyId}`}> ({t('cancellationRequested')})</span>
        )}
        {partialCancel === 'CANCELLED' && <span id={`txt_cancelled${keyId}`}>({t('cancelled')})</span>}
      </div>
    )
  }

  /**
   * UserCard is a component that displays the first name of the user,
   * and whether the user has requested or been cancelled.
   * @param {MeetingParticipant} inviteGuest - The meeting participant object.
   * @param {number} keyId - The key id of the component.
   * @returns {JSX.Element} The UserCard component.
   */
  function UserCard({ inviteGuest, keyId }: { inviteGuest: MeetingParticipant, keyId: number }) {
    const { partialCancel } = usePartialCancellationHook({ inviteGuest })
    return (
      <div className="gap-1">
        <span id={`btn_notify${keyId}`} >{inviteGuest?.user?.firstName}</span>
        {partialCancel === 'REQUESTED' && (
          <span id={`txt_requested${keyId}`}> ({t('cancellationRequested')})</span>
        )}
        {partialCancel === 'CANCELLED' && <span>({t('cancelled')})</span>}
      </div>
    )
  }
}
