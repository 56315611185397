/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import { PageTitle } from '../../Components/pageTitle'
import InvitationList from './InvitationList'
import StartInviting from './StartInviting'
import Loading from '../../Components/base/loading/loading'
import { useGetInviteByUserQuery } from '../../Services/inviteApi'

/**
 * A component that displays a page for inviting users, including a form to invite and a list of sent invitations.
 *
 * @param {Object} props The component props.
 * @param {string} [props.serverError] An optional error message to show when there is a server error.
 *
 * @return {ReactElement} The JSX element representing the invitation page.
 */
function Invite({ serverError }: { serverError?: string }) {
  const { t } = useTranslation()
  const { isLoading: inviteLoading } = useGetInviteByUserQuery();

  if (inviteLoading) {
    return <Loading />;
  }

  return (
    <>
      {!serverError &&
        <PageTitle title='invitations'>
          <StartInviting />
          <InvitationList />
        </PageTitle>
      }
    </>
  )
}
export default Invite
