/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { PageTitle } from "../Components/pageTitle"
import { useGetSettingValue } from "../Services/settingReducer"

/**
 * Version component renders the version information of the current application.
 * It displays the version of web, API, batch and chat.
 *
 * @return {JSX.Element} The version information component.
 */
function Version() {

  const apiVersion = useGetSettingValue('VERSION')
  const batchVersion = useGetSettingValue('BATCH_VERSION')
  const chatVersion = useGetSettingValue('CHAT_VERSION')
  const liveKitVersion = useGetSettingValue('LIVEKIT_VERSION')

  return (
    <PageTitle title = 'Version'>
      <div className="font-semibold">Version</div>
      <div><span className="font-light">Web version:</span> <span className="font-semibold">{process.env.REACT_APP_VERSION}</span></div>
      <div><span className="font-light">API version: </span> <span className="font-semibold">{apiVersion}</span></div>
      <div><span className="font-light">Batch version: </span> <span className="font-semibold">{batchVersion}</span></div>
      <div><span className="font-light">Chat version: </span> <span className="font-semibold">{chatVersion}</span></div>
      <div><span className="font-light">LiveKit version: </span> <span className="font-semibold">{liveKitVersion}</span></div>
    </PageTitle>
  )
}

export default Version
