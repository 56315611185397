/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useSelector } from "react-redux"
import { Ledger } from "../../models/ledger.model"
import { getPreference } from "../../Services/userReducer"
import { useCurrencyHook } from "../../Hooks/CurrencyHooks"

/**
 * A component to display the transaction amount.
 * @param {object} props - The component props.
 * @param {Ledger} props.transaction - The transaction details to display.
 * @param {string} props.symbol - The symbol to use for the currency.
 * @returns {JSX.Element} - The component element.
 */
export default function TransactionAmount({ transaction, symbol }: { transaction: Ledger, symbol: string }) {
    const preference = useSelector(getPreference)
    const { formatCurrency } = useCurrencyHook();

    return (
      <>
        {transaction.currency === preference.currency && (
          <span
            data-testid={`card_currency-${transaction.id}`}
            className={`text-xs lg:text-base ${
              (transaction.transactionStatus === 'Failed' ||
                transaction.transactionStatus === 'Submitted') &&
              `line-through`
            } `}>
            {formatCurrency(
              transaction.transactionAmount,
              transaction.currency,
            )}
          </span>
        )}
        {transaction.currency !== preference.currency && (
          <div className="flex flex-col">
            <span
              data-testid={`card_cost-${transaction.id}`}
              id={`card_cost-${transaction.id}`}
              className={`text-xs lg:text-base ${
                (transaction.transactionStatus === 'Failed' ||
                  transaction.transactionStatus === 'Submitted') &&
                `line-through`
              }`}>
              {formatCurrency(
                transaction.transactionAmount,
                transaction.currency,
              )}
            </span>
            <span
              data-testid={`card_preference_currency-${transaction.id}`}
              id={`card_preference_currency-${transaction.id}`}
              className={`text-black text-xs lg:text-base ${
                (transaction.transactionStatus === 'Failed' ||
                  transaction.transactionStatus === 'Submitted') &&
                `line-through`
              }`}>
              {formatCurrency(
                parseFloat(
                  transaction.costLocal.toString().replace(/[^0-9.-]+/g, ''),
                ),
                preference.currency,
              )}
            </span>
          </div>
        )}
      </>
    )
}