/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React from 'react'
import { useToggleDropdown } from '../navbar/navbar'

interface Props {
  children: React.ReactNode
  variant?: 'default' | 'menu' | 'reminder' | 'sort'
  isAbsolute?: boolean
  keyId?: string
  dropDownMenuStyle?: React.CSSProperties
}

interface DropdownItemProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
}

  /**
   * Hook to handle toggling of a dropdown menu.
   *
   * @returns an object with the following properties:
   * - `show`: a boolean indicating whether the dropdown menu is shown
   * - `toggle`: a function to toggle the dropdown menu
   * - `ref`: a ref to the dropdown menu element
   */
const useToggle = () => {
  const [show, setShow] = React.useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement>(null)

  const toggle = React.useCallback(() => {
    setShow(prevState => !prevState)
  }, [])

  // close dropdown when you click outside
  React.useEffect(() => {
  /**
   * Handles clicks outside of the dropdown menu and hides it when it is visible.
   * @param {Event} event The event object.
   */
    const handleOutsideClick = (event: any) => {
      if (!ref.current?.contains(event.target)) {
        console.log('clicked outside', show)
        if (!show) return
        setShow(false)
      }
    }
    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [show])

  // // close dropdown when you click on "ESC" key
  React.useEffect(() => {
  /**
   * Handles keyup events on the document and hides the dropdown menu when the ESC key is pressed.
   * @param {Event} event The event object.
   */
    const handleEscape = (event: any) => {
      if (!show) return

      if (event.key === 'Escape') {
        setShow(false)
      }
    }
    document.addEventListener('keyup', handleEscape)
    return () => document.removeEventListener('keyup', handleEscape)
  }, [show])

  return {
    show,
    toggle,
    ref,
  }
}

const style = {
  menu: `absolute right-0 py-2 px-2 bg-white rounded-lg lg:w-44 xs:w-52 mt-0.5 mb-0 mx-0 bg-clip-padding w-auto`,
  sort: `absolute py-2 px-2 bg-white rounded-lg w-auto mt-0.5 mb-0 mx-0 bg-clip-padding`,
  item: `block w-full flex mb-1 rounded-md text-base font-normal whitespace-nowrap  hover:bg-gray-200 cursor-pointer`,
}

  /**
   * Dropdown component.
   * @param {React.ReactNode} children - The children of the component, expected to be a list of two children, first child is the dropdown toggle, second child is the dropdown menu.
   * @param {string} keyId - The key ID of the dropdown menu.
   * @returns {React.ReactElement} The rendered Dropdown component.
   */
export function Dropdown({ children, keyId }: Props) {
  const { show, toggle } = useToggle()
  /* First child contains the dropdown toggle */
  // @ts-ignore
  const dropdownToggle = children[0]

  /* Second child contains the dropdown menu */
  // @ts-ignore
  const dropdownMenu = children[1]

  return (
    <>
      <button
        onClick={toggle}
        type="button"
        className="text-gray-400 font-normal flex items-center"
        id={`dropMenu_lang_${keyId}`}
        aria-expanded="true"
        data-testid="drop_down"
        aria-haspopup="true">
        {dropdownToggle}
      </button>
      {show && <>{dropdownMenu}</>}
    </>
  )
}

  /**
   * DropdownToggle component.
   * @param {React.ReactNode} children - The children of the component, expected to be a single child, the dropdown toggle.
   * @returns {React.ReactElement} The rendered DropdownToggle component.
   */
export function DropdownToggle({ children }: Props) {
  return <>{children}</>
}

  /**
   * DropdownMenu component.
   * @param {React.ReactNode} children - The children of the component, expected to be a list of items in the dropdown menu.
   * @param {string} variant - The variant of the dropdown menu, can be either 'default', 'menu' or 'sort'.
   * @param {boolean} isAbsolute - Whether the dropdown menu is an absolute positioned element.
   * @param {React.CSSProperties} dropDownMenuStyle - Additional styles to apply to the dropdown menu.
   * @returns {React.ReactElement} The rendered DropdownMenu component.
   */
export function DropdownMenu({ children, variant, isAbsolute = false, dropDownMenuStyle }: Props) {
  const currentLocation = window.location.pathname
  let flag = false
  if (
    currentLocation === '/' ||
    currentLocation === '/signIn' ||
    currentLocation === '/notify' ||
    currentLocation === '/signUp'
  ) {
    flag = true
  }
  const dropdownStyle = {
    transform: 'translate3d(0px, 3px, 0px)',
    right: '-70px',
    marginTop: '2rem',
  }
  return (
    <div
      className={` z-40 ${isAbsolute ? ' absolute ' : ' relative '} bg-white`}>
      <div
        style={{
          ...(flag
            ? dropdownStyle
            : {
              transform: 'translate3d(0px, 3px, 0px)',
            }),
          ...dropDownMenuStyle,
        }}
        className={`${variant === 'sort' ? style.sort : style.menu} border`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu">
        {children}
      </div>
    </div>
  )
}

/* You can wrap the a tag with Link if you are using either Create-React-App, Next.js or Gatsby */
export function DropdownItem({ children }: DropdownItemProps) {
  const { toggleDropDown } = useToggleDropdown()
  return (
    <div tabIndex={0} className={style.item} role="menuitem" onClick={() => toggleDropDown && toggleDropDown()}>
      {children}
    </div>
  )
}

/**
 * A horizontal divider component to be used within a `Dropdown` component.
 *
 * @returns {React.ReactElement} The rendered horizontal divider.
 */
export function DropdownDivider() {
  return <hr />
}

