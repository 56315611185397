/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useTranslation} from 'react-i18next';

/**
 * Renders the cancellation details of a meeting.
 * If the cancellation policy is free, it renders a message indicating that the host has chosen a free cancellation policy.
 * If the cancellation policy is moderate, it renders a message indicating that the host has chosen a moderate cancellation policy.
 * If the cancellation policy is flex, it renders a message indicating that the host has chosen a flex cancellation policy.
 * If the cancellation policy is not applicable, it renders a message indicating that the host has not chosen a cancellation policy.
 * @param meetingDetails - The meeting details object.
 * @returns A JSX Element.
 */
export default function CancellationDetails({
  meetingDetails,
}: {
  meetingDetails: any;
}) {
  const {t} = useTranslation();
  return (
    <>
      <div className="mt-5">
        <p data-testid="things_to_know" className="text-[1.1rem] font-semibold">
          {t('thingsToKnow')}
        </p>
        <p
          data-testid="ttk_description"
          className="text-justify text-sm font-normal">
          {t(`internetAccess–BeeMGUsesVideoCommunication`)}
        </p>
        <div className="mt-5">
          <hr />
        </div>
      </div>
      <div className="mt-5">
        <p
          data-testid="txt_Cancellationterms"
          className="text-[1.1rem] font-semibold text-red-500">
          {t('cancellationTerms')}
        </p>
        <ul className="list-disc ml-5">
          <div className="text-sm font-normal">
            {!meetingDetails?.cancellationPolicy ? (
              <li data-testid="no_policy">
                {t(`cancellationIsNotApplicableForFreeAudioEvents`)}
              </li>
            ) : (
              <>
                {' '}
                {meetingDetails?.cancellationPolicy?.policyType === 'Free' && (
                  <>
                    <li data-testid="free_cancellation">
                      {t(`freeCancellation`)}
                    </li>
                    <li data-testid="default_cancellation">
                      {t(`upto`)} 5%{' '}
                      {t(`ofProcessingFeeWillBeApplicableUponCancellation`)}
                    </li>
                  </>
                )}
                {meetingDetails?.cancellationPolicy?.policyType !== 'Free' && (
                  <>
                    {meetingDetails?.cancellationPolicy?.policyType ===
                      'Flex' && (
                      <>
                        <li data-testid="txt_noCharges">
                          {t(`freeCancellationBefore`)}{' '}
                          {
                            meetingDetails?.cancellationPolicy
                              ?.chargePeriodToTime
                          }{' '}
                          {t('hours')}
                        </li>
                      </>
                    )}
                    {meetingDetails?.cancellationPolicy?.policyType ===
                      'Moderate' && (
                      <>
                        <li data-testid="txt_noCharges">
                          {t(`freeCancellationBefore`)}{' '}
                          {
                            meetingDetails?.cancellationPolicy
                              ?.chargePeriodToTime
                          }{' '}
                          {t('hours')}
                        </li>
                      </>
                    )}
                    <li data-testid="hostChosenPolicy">
                      {t('hostHaveChosen')}
                      {''} {meetingDetails?.cancellationPolicy?.policyType}
                      &nbsp;
                      {t(`cancellationPolicyForThisEvent`)}
                    </li>
                    <li data-testid="txt_deduction">
                      {t('ifYouCancelBetween')}
                      {''}{' '}
                      {meetingDetails?.cancellationPolicy?.chargePeriodFromTime}{' '}
                      {t('hours')} to{' '}
                      {meetingDetails?.cancellationPolicy?.chargePeriodToTime}{' '}
                      {t('hours')},{' '}
                      {meetingDetails?.cancellationPolicy?.deductionPercentage}
                      %&nbsp;
                      {t(`chargeWillBeDeductedFromRefund`)}
                    </li>
                    <li data-testid="txt_noRefund">
                      {t('cancellationwithin')}
                      {''}{' '}
                      {meetingDetails?.cancellationPolicy?.chargePeriodFromTime}{' '}
                      {t('hours')}
                      &nbsp;
                      {t(`ofTheEventwillNotBeRefunded`)}
                    </li>
                    <li data-testid="default_cancellation">
                      {t(`upto`)} 5%{' '}
                      {t(`ofProcessingFeeWillBeApplicableUponCancellation`)}
                    </li>
                  </>
                )}
              </>
            )}
          </div>
        </ul>
      </div>
    </>
  );
}
