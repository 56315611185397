/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client';
import i18n from './Translations'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { store, persistor } from './Store'
import { PersistGate } from 'redux-persist/integration/react'
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useGetGoogleAnalyticsIdQuery } from './Services/envApi';

// Sentry.init({
//   dsn: 'https://a47ad73d04e6d051dbf26d8772c860b4@dev.sentry.chefgarden.asia/2',
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes,
//       ),
//     }),
//     // new Sentry.Replay()
//   ],
//   tracesSampleRate: 1.0,
//   environment: window.location.host,
// })

function InitializeAnalytics() {
  const { data: measurementId } = useGetGoogleAnalyticsIdQuery();
  if (measurementId) {
    ReactGA.initialize(measurementId);
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }
  return null;
}

/**
 * The top-level component of the app.
 *
 * This component is responsible for rendering the main components of the app, including the
 * {@link App} component and the {@link PersistGate} component.
 *
 * The {@link PersistGate} component delays the rendering of the app's UI until the persisted state has been retrieved
 * and saved to redux.
 *
 * The `loading` prop can be `null` or any react instance to show during loading (e.g. a splash screen),
 * for example `loading={<SplashScreen />}`.
 * @see https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
 */
const RootApp = () => {
  return (
    <StrictMode>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          {/**
         * PersistGate delays the rendering of the app's UI until the persisted state has been retrieved
         * and saved to redux.
         * The `loading` prop can be `null` or any react instance to show during loading (e.g. a splash screen),
         * for example `loading={<SplashScreen />}`.
         * @see https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
        */}
          <PersistGate loading={null} persistor={persistor}>
            <InitializeAnalytics />
            <App />
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </StrictMode>
  )
}
const container = document.getElementById('root');
const root = createRoot(container!); 
root.render(<RootApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
