/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../Store';

const initialStateValue = {
  isConnected: false,
  isSocketLogined: false,
};

/**
 * Creates a slice of the Redux state for managing the socket connection.
 *
 * @return {Object} The socketSlice object containing reducers and actions for managing the socket state.
 *
 * @property {boolean} isConnected - Whether the socket is connected.
 * @property {boolean} isSocketLogined - Whether the socket is logged in.
 *
 * @action setSocketConnected - Updates the socket connection state.
 * @action setSocketLogined - Updates the socket login state.
 */

export const socketSlice = createSlice({
  name: 'socket',
  initialState: initialStateValue,
  reducers: {
    setSocketConnected: (state, action) => {
      if (action.payload && !state.isConnected) {
        state.isConnected = action.payload;
      } else if (!action.payload && state.isConnected) {
        state.isConnected = false;
        state.isSocketLogined = false;
      }
    },
    setSocketLogined: (state, action) => {
      state.isSocketLogined = action.payload;
    },
  },
});

export const isSocketConnected = (state: RootState) =>
  state.socket?.isConnected;
export const isSocketLogined = (state: RootState) =>
  state.socket?.isSocketLogined;
export const {setSocketConnected, setSocketLogined} = socketSlice.actions;

export default socketSlice.reducer;
