/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState} from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../Components'
import Field from '../Components/base/field/field'
import Loading from '../Components/base/loading/loading'
import { toastError, toastSuccess } from '../Components/toast'
import { useMeetingFeedBack } from '../Hooks/MeetingFeedbackHook'
import { getCurrentUser } from '../Services/userReducer'
import { useAppSelector } from '../Store/hooks'
import StarRatings from 'react-star-ratings'

// const StarRatings = lazy(() => import("react-star-ratings"));

/**
 * A component that displays a form for the user to submit a meeting feedback.
 * It also displays the meeting details and the user's name.
 * 
 * @param {string} meetingId - The ID of the meeting to submit feedback for.
 * @param {string} anonymous - Whether the user is submitting feedback as anonymous or not.
 */
function Feedback() {
  const { t } = useTranslation()
  const { meetingId, anonymous } = useParams()
  const navigate = useNavigate()
  const [rating, setRating] = useState<number>(0)
  const currentUser = useAppSelector(getCurrentUser)

  const {
    alreadyGiven,
    checkingIfAlreadyGiven,
    currentUserDetail,
    meeting,
    isMeetingLoading,
    submitFeedback,
    submitSuccess,
    register,
    handleSubmit,
    errors,
    isDirty,
    isValid,
    control,
    serverError,
    setValue,
    meetingHostedBy
  } = useMeetingFeedBack({ meetingId: meetingId! })

  useEffect(() => {
    if (currentUser) {
      if (anonymous && anonymous === "1") {
        setValue('name', currentUser?.nickName)
      } else {
        setValue('name', currentUser?.firstName)
      }
    }
  }, [currentUser, anonymous])

  useEffect(() => {
    if (submitSuccess) {
      toastSuccess(t('feedbackRegisteredSuccessfully'));
      navigate('/s/home');
    }
    if (alreadyGiven) {
      if (serverError) { // Check if serverError is not empty
        if (serverError === "BeeMG-ERR002") {
          toastSuccess(t('BeeMG-ERR002'));
        } else {
          toastError(t(serverError));
        }
        navigate('/s/home');
      }
      toastSuccess(t('BeeMG-ERR002'));
      navigate('/s/home');
    }
  }, [submitSuccess, alreadyGiven, serverError]);

  if (checkingIfAlreadyGiven || isMeetingLoading || !currentUser) {
    return (<div className='flex items-center justify-center h-full'>
      <Loading />
    </div>)
  }

  return (
    <div className="px-2 pt-10">
      <span className="flex lg:items-center mx-3 flex-col">
        <form onSubmit={handleSubmit(submitFeedback)}>
          <div className=" sm:mx-3 text-black mt-2">
            <span className="flex items-start flex-col">
              <span className="text-4xl sm:text-normal font-normal mb-4" data-testid="kindlyGiveYourFeedback">
                {t('kindlyGiveYourFeedback')}
              </span>
              <span id="chk_eventName" data-testid="chk_eventName" className="mb-1">
                {t('eventName')} :{' '}
                <span className="font-semibold">
                  {meeting?.meetingDetails.title}
                </span>
              </span>
              <span id="chk_hostName" data-testid="chk_hostName" className="mb-2">
                {t('hostedBy')} :{' '}
                <span className="font-semibold capitalize">
                  {meetingHostedBy}
                </span>
              </span>
              <div className="flex flex-row items-start mt-2">
                <div className="col-span-6 sm:col-span-3 mr-2 w-60">
                  <Field
                    {...register('name')}
                    disabled={true}
                    id="input_name"
                    className = "capitalize"
                    data-testid="input_name"
                    error={errors.name?.message}
                    name="name"
                    type="text"
                    placeholder={t('name') + " *"}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mr-2 w-60">
                  <Field
                    {...register('email')}
                    id="txt_email"
                    data-testid="input_email"
                    name="email"
                    type="text"
                    defaultValue={currentUserDetail.email}
                    disabled
                  />
                </div>
                <RatingCount />
              </div>
              <div className="w-full">
                <Field
                  {...register('review')}
                  id="input_review"
                  data-testid="input_review"
                  error={t((errors.review as any)?.message)}
                  name="review"
                  type="textarea"
                  rows={7}
                  placeholder={t(`yourComment`) + " *"}
                />
              </div>
            </span>
            <span className="flex justify-end mb-2 mt-3">
              <Button
                id="btn_submit"
                data-testid="btn_submit"
                color="save"
                type="submit"
                submit
                disabled={!isDirty || !isValid}
              >
                <div>{t('submit')}</div>
              </Button>
            </span>
          </div>
        </form>
      </span>
    </div>
  )

  /**
   * Component for rendering a rating count with star icons, and a control to handle
   * changes to the rating. The rating is stored in the component state, and
   * passed to the parent component as a callback.
   *
   * @returns {JSX.Element} A JSX element representing the rating component.
   */
  function RatingCount() {
    return (
      <>
        <div className="text-left cursor-pointer flex flex-col justify-center">
          <Controller
            render={({
              field: { onChange },
            }) => (
              // <Suspense fallback={<Loading />}>
                <StarRatings
                  rating={rating}
                  numberOfStars={5}
                  changeRating={(_ratingCount) => { setRating(_ratingCount); onChange(_ratingCount) }}
                  starDimension="1.25rem"
                  starSpacing="0.2rem"
                  starRatedColor="#fcae18"
                  starHoverColor="#fcae18"
                />
              // </Suspense>
            )}
            name="rating"
            control={control}
          />
          {errors.rating?.message && <div className='text-sm text-red-500 mt-2'>
            {t(errors.rating?.message)}
          </div>}
        </div>
      </>
    )
  }
}

export default Feedback
