/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {Preference} from './../models/preference.model';
import {allApis} from './allApis';

/**
 * Defines the endpoints for the preference API.
 *
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ getUserPreference: query, getPreferenceById: query, updatePreference: mutation }} - An object containing the defined endpoints.
 */

export const preferenceApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getUserPreference: builder.query<Preference, void>({
      query: () => `preference`,
      providesTags: ['Preference'],
    }),
    getPreferenceById: builder.query<Preference, {id: string}>({
      query: ({id}) => `preference/user/${id}`,
      providesTags: ['Preference'],
    }),
    updatePreference: builder.mutation<Preference, Partial<Preference>>({
      query: preference => {
        return {
          url: `preference/${preference.id}`,
          method: 'PUT',
          body: preference,
        };
      },
      invalidatesTags: ['Preference'],
    }),
  }),
});

export const {
  useGetUserPreferenceQuery,
  useGetPreferenceByIdQuery,
  useUpdatePreferenceMutation,
} = preferenceApi;
