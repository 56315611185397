/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React, { useEffect, useState } from 'react'
import { Meeting } from '../../models/meeting.model'
import { User } from '../../models/user.model'
import Avatar from '../base/avatar/avatar'

interface LiveEventCardProps {
  eventsTitle: string
  meeting: Meeting
}

  /**
   * This component renders a list of avatars for the users in the given meeting,
   * limited to 4 avatars.
   *
   * @param {LiveEventCardProps} props
   * @returns {JSX.Element}
   */
const LiveEventAvatars = (props: LiveEventCardProps) => {

  const { eventsTitle, meeting } = props;

  const [liveEventUsers, setLiveEventUsers] = useState<User[]>([])

  useEffect(() => {
    if (eventsTitle === 'Live events') {
      setLiveEventUsers([]);
      if (meeting) {
        if (meeting?.meetingParticipant && meeting.meetingParticipant?.length) {
          const participants = meeting.meetingParticipant.map((participant) => participant?.user)
          setLiveEventUsers((value) => [...value, ...participants])
        }
        if (meeting?.meetingDetails && meeting.meetingDetails?.host) {
          setLiveEventUsers((value) => [...value, meeting.meetingDetails.host])
        }
      }
    }
  }, [meeting, eventsTitle])

  return (
    <div className="beemg_wrap ml-5 ">
      {React.Children.toArray(liveEventUsers.map((user, index) => (
        <>
          {index <= 3 &&
            <div
              className="beemg_block inline-block mt-2 -mx-2.5 rounded-full bg-white hover:ring-2 hover:ring-white overflow-hidden"
            >
              <Avatar
              className='p-2'
                images={[user?.image as any]}
                avatar={user?.avatar}
                anonymous={user?.anonymous}
                size='md-small'
              />
            </div>
          }
        </>
      )))}
    </div>
  )
}

export default LiveEventAvatars