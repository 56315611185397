/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {Auth} from 'aws-amplify';

/**
 * Authenticates a user using AWS Amplify Auth.
 *
 * @param {string} email - The email address of the user to authenticate.
 * @param {string} password - The password of the user to authenticate.
 * @return {Promise} A promise that resolves with the authenticated user object or rejects with an error.
 */

export function login(email: string, password: string) {
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then(() => {
        Auth.currentAuthenticatedUser().then(resultCurrent => {
          resolve(resultCurrent);
        });
      })
      .catch(error => {
        if (error.message.indexOf('User is not confirmed') > -1) {
          Auth.resendSignUp(email)
            .then()
            .catch(errorObject => console.log(errorObject));
        }

        reject(error);
      });
  });
}
