/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { Children, HTMLAttributes, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../Components/base/carousel/whirligig/utils";

interface Props extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode,
    link?: string,
    keyId?: number | string,
}


    /**
     * Renders an event card
     *
     * @param children - The children to render. Can include EventImage, EventTitle, EventSubTitle, EventSubTitle1, EventIcons, EventRight, EventActions
     * @param link - The link to navigate to when the card is clicked
     * @param keyId - The key of the event
     * @returns The rendered event card
     */
export function EventCard({ children, link, keyId }: Props) {
    const navigate = useNavigate();
    let _image, _title, _subtitle, _subTitle_1,_icons, _right, _actions;

    const { width } = useWindowDimensions();

    Children.forEach(children, (child: any) => {
        if (child?.type === EventImage) {
            return _image = child
        }

        if (child?.type === EventTitle) {
            return _title = child
        }

        if (child?.type === EventSubTitle) {
            return _subtitle = child
        }
        if (child?.type === EventSubTitle1) {
            return _subTitle_1 = child
        }

        if (child?.type === EventIcons) {
            return _icons = child
        }

        if (child?.type === EventRight) {
            return _right = child
        }

        if (child?.type === EventActions) {
            return _actions = child
        }
    })

    return (
        width > 1023 ? (
            <div className={`flex w-full gap-3 overflow-hidden pl-2 pt-1 ${link ? 'cursor-pointer' : ''}`} onClick={() => link && navigate(link)}>
                <div className="justify-center items-center my-auto">{_image}</div>
                <div className='flex flex-col justify-start my-auto items-start flex-1 gap-y-0 w-3/5 ml-6'>
                    {_title}
                    {_subtitle&& <span data-testid={`txt_subtitle${keyId}`} id={`txt_subtitle${keyId}`} className="text-sm font-medium w-max">{_subtitle}</span>}
                    {_subTitle_1&& <span data-testid={`txt_subtitle${keyId}`} id={`txt_subtitle${keyId}`} className="text-sm font-normal ">{_subTitle_1}</span>}
                    {_icons&&<div className="flex whitespace-nowrap ">{_icons}</div>}
                </div>
                <div className="flex-1">{_right}</div>
                <div className="flex items-center justify-end min-w-cardRightText w-1/5" data-testid={'link_viewEvent'}>{_actions}</div>
            </div>
        ) : (
            <div className={`flex w-full gap-3 overflow-hidden pl-2 pb-2 ${link ? 'cursor-pointer' : ''}`} onClick={() => link && navigate(link)}>
                <div className="flex w-full gap-3">
                    <div>{_image}</div>
                    <div className='flex flex-col '>
                        {_title}
                        <span data-testid={`txt_subtitle${keyId}`} id={`txt_subtitle${keyId}`} className="text-xs font-normal">{_subtitle}</span>
                        <div className="flex whitespace-nowrap mt-1 lg:mt-0">{_icons}</div>
                        {_right}
                    </div>

                </div>

                <div className="flex md:flex-row items-center justify-center gap-2 lg:text-md text-sm mt-2 md:mt-0 whitespace-normal text-center md:w-1/5 lg:w-2/5" data-testid={'link_Event'}>{_actions}</div>
            </div>
        )
    )
}

const EventImage = ({ children }: { children: ReactNode }) => <>{children}</>
const EventTitle = ({ children }: { children: ReactNode }) => <>{children}</>
const EventSubTitle = ({ children }: { children: ReactNode }) => <>{children}</>
const EventSubTitle1 = ({ children }: { children: ReactNode }) => <>{children}</>
const EventIcons = ({ children }: { children: ReactNode }) => <>{children}</>
const EventRight = ({ children }: { children: ReactNode }) => <>{children}</>
const EventActions = ({ children }: { children: ReactNode }) => <>{children}</>

EventCard.Image = EventImage;
EventCard.Title = EventTitle;
EventCard.SubTitle = EventSubTitle;
EventCard.SubTitle1 = EventSubTitle1;
EventCard.Icons = EventIcons;
EventCard.Right = EventRight;
EventCard.Actions = EventActions;
