/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {allApis} from './allApis';
import {CancellationRequest} from '../models/cancellationRequest.model';
/**
 * Defines the endpoints for cancellation request API.
 *
 * @param {builder} builder - The builder object used to define the endpoints.
 * @return {{ createCancellation: mutation, checkCancellationRequest: query }} - An object containing the defined endpoints.
 */

export const cancellationRequestApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createCancellation: builder.mutation<
      CancellationRequest,
      Partial<CancellationRequest>
    >({
      query: cancellationRequest => ({
        url: `cancellationRequest`,
        method: 'POST',
        body: cancellationRequest,
      }),
      // invalidatesTags: ['CancellationRequest']
      invalidatesTags: (result, error, cancellationRequest) => [
        {type: 'CancellationRequest', id: cancellationRequest?.meeting?.id},
        {
          type: 'CancellationRequest',
          id: cancellationRequest?.meetingParticipant?.transaction?.id,
        },
      ],
    }),
    checkCancellationRequest: builder.query<CancellationRequest, string>({
      query: meetingId => `cancellationRequest/meeting/${meetingId}`,
      providesTags: (result, error, meetingId) => [
        {type: 'CancellationRequest', id: meetingId},
      ],
    }),
    // checkCancellationRequestByParticipant: builder.query<CancellationRequest, any>({
    //     query: (arg) => {
    //         const { meetingId, participantId } = arg;
    //         return `cancellationRequest/checkCancellation/${meetingId}/${participantId}`
    //     },
    //     providesTags: ['CancellationRequest']
    // })
  }),
});
export const {useCreateCancellationMutation, useCheckCancellationRequestQuery} =
  cancellationRequestApi;
