/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

import { TrackLoop, UseParticipantsOptions } from '@livekit/components-react'
import * as React from 'react'
import type { TrackReferenceOrPlaceholder } from '@livekit/components-core'
import useWindowDimensions, {
  useCustomStagedParticipantHooks,
  useReceiveStagedParticipantsList,
  useSendStagedParticipantsList,
} from '../../livekitHooks'
import { getAspectAndPositions } from '../../liveKitHelpers'
import { useCustomRoomContext } from '../CustomRoomContext'
import { Moderator } from './Moderator'
import { useTranslation } from 'react-i18next'
import { ParticipantSection } from './ParticipantSection'

/** @public */
export interface GridLayoutProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<UseParticipantsOptions, 'updateOnlyOn'> {
  children: React.ReactNode
  tracks: TrackReferenceOrPlaceholder[]
}

/**
 * CustomAudioGridLayout is a component that displays a grid layout of audio participants.
 * It handles the positioning and rendering of participants in different sections.
 *
 * @param {GridLayoutProps} props - The props for the GridLayout component, including tracks and children.
 * @return {JSX.Element} The JSX element representing the grid layout of audio participants.
 */

export function CustomAudioGridLayout({ tracks, ...props }: GridLayoutProps) {
  const {
    otherTracks,
    stagedTracks,
    moderatorTracks,
    otherCount,
    stagedParticipantsCount,
    handRaisedTracks,
    handraisedCount,
  } = useCustomStagedParticipantHooks(tracks as any)

  useSendStagedParticipantsList()
  useReceiveStagedParticipantsList()

  const { setOtherLayoutPositions, setStagedLayoutPositions, viewType } = useCustomRoomContext()

  const gridWrapperOthersRef = React.createRef<HTMLDivElement>()

  const { width: screenWidth, height: screenHeight } = useWindowDimensions()

  const [gridWrapDimensionsOthers, setGridWrapDimensionsOthers] = React.useState<{ width: number; height: number }>({
    height: 0,
    width: 0,
  })

  const maxBoxW = 150
  const boxHeight = 160
  const boxWidth = 100

  const [, setChanges] = React.useState(false)
  const { t } = useTranslation()
  const [showTracks, setShowTracks] = React.useState(false)

  React.useLayoutEffect(() => {
    if (gridWrapperOthersRef.current) {
      if (
        gridWrapDimensionsOthers.width !== gridWrapperOthersRef.current.offsetWidth ||
        gridWrapDimensionsOthers.height !== gridWrapperOthersRef.current.offsetHeight / 1.1
      ) {
        setGridWrapDimensionsOthers({
          width: gridWrapperOthersRef.current.offsetWidth,
          height: gridWrapperOthersRef.current.offsetHeight / 1.1,
        })
      }
    }
  }, [screenHeight, screenWidth, gridWrapperOthersRef, gridWrapDimensionsOthers.width, gridWrapDimensionsOthers.height])

  React.useLayoutEffect(() => {
    if (gridWrapDimensionsOthers.width > 0 && gridWrapDimensionsOthers.height > 0 && stagedParticipantsCount > 0) {
      setStagedLayoutPositions(
        getAspectAndPositions({
          boxW: boxWidth,
          boxH: boxHeight,
          usersPerPage: stagedParticipantsCount,
          screenWidth: gridWrapDimensionsOthers.width,
          screenHeight: gridWrapDimensionsOthers.height / 2,
          topOffset: 60,
          aspectType: '4:3',
          maxBoxW,
        })
      )
    }
  }, [tracks.length, gridWrapDimensionsOthers, stagedParticipantsCount, setStagedLayoutPositions])

  React.useEffect(() => {
    setChanges(true)

    const timeout = setTimeout(() => {
      setChanges(false)
    }, 100)

    return () => clearTimeout(timeout)
  }, [stagedParticipantsCount])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTracks(true)
    }, 2500)

    return () => clearTimeout(timeout)
  }, [])

  React.useLayoutEffect(() => {
    if (gridWrapDimensionsOthers.width > 0 && gridWrapDimensionsOthers.height > 0 && otherCount > 0) {
      setOtherLayoutPositions(
        getAspectAndPositions({
          boxW: boxWidth,
          boxH: boxHeight,
          usersPerPage: otherCount,
          screenWidth: gridWrapDimensionsOthers.width,
          screenHeight: gridWrapDimensionsOthers.height / 2,
          topOffset: gridWrapDimensionsOthers.height / 1.5,
          aspectType: '4:3',
          maxBoxW,
        })
      )
    }
  }, [tracks.length, gridWrapDimensionsOthers, otherCount, setOtherLayoutPositions])

  return (
    <>
      {viewType === 'grid' ? (
        <div className="flex flex-col w-full h-full overflow-hidden">
          <div className="w-full h-1/3 flex flex-row items-center justify-center">
            {showTracks && (
              <TrackLoop tracks={moderatorTracks}>
                <Moderator />
              </TrackLoop>
            )}
          </div>

          <div className="w-full h-2/3 flex flex-col overflow-y-scroll gap-y-1 scrollbar-hide">
            <div className="h-1/2 flex items-center justify-center gap-1">
              {showTracks && (
                <ParticipantSection title={t('staged')} count={stagedParticipantsCount} tracks={stagedTracks}>
                  {props.children}
                </ParticipantSection>
              )}
            </div>

            <div className="h-1/2 flex items-center justify-center gap-1">
              {showTracks && (
                <ParticipantSection title={t('others')} count={otherCount} tracks={otherTracks}>
                  {props.children}
                </ParticipantSection>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full h-full">
          <div className={`w-full h-full max-h-[70%] min-h-[50%] flex flex-row items-center justify-center gap-3`}>
            {showTracks && (
              <TrackLoop tracks={moderatorTracks}>
                <Moderator />
              </TrackLoop>
            )}
          </div>
          <div className="w-full h-full">
            {showTracks && (
              <ParticipantSection title={t('staged')} count={handraisedCount} tracks={handRaisedTracks}>
                {props.children}
              </ParticipantSection>
            )}
          </div>
        </div>
      )}
    </>
  )
}
