/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { HTMLAttributes, ReactNode } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

/**
 * A component that renders a title with a given text.
 *
 * @param {Object} props
 * @param {ReactNode} props.children - The text to display in the title.
 *
 * @returns A JSX element with a title and an id/data-testid.
 */
export function TitleComponent({ children }: Props) {
  return (
    <div id="txt_title" data-testid={`title_${children}`} className="text-xl md:text-2xl font-medium flex justify-center mt-2 mb-8">
      {children}
    </div>
  )
}
export default TitleComponent
