/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React from 'react'
import {NavLink, useNavigate} from 'react-router-dom'

interface Props {
  children: React.ReactNode
}

interface INavbarContextProps {
  open?: boolean
  toggle?: any
}

interface INavbarContextDropdownProps {
  toggleDropDown?: any
}
interface INavbarProps extends Props {
  className?: string
}

interface INavbarBrandProps extends Props {
  href: string
}

interface INavbarNavProps extends Props {
  orientation?: 'start' | 'middle' | 'end'
  className?: string
}

interface INavbarLinkProps extends INavbarBrandProps {
  active?: boolean
  activeClass?: string
  activeAt?: string
}

const style = {
  navbar: `relative py-2 top-0 w-full lg:flex lg:flex-row lg:items-center lg:justify-start lg:relative`,
  brand: `cursor-pointer font-bold inline-block mr-4 py-1.5 text-2xl whitespace-nowrap hover:text-gray-200`,
  toggler: `block float-right text-4xl p-2 rounded-lg lg:hidden focus:outline-none focus:shadow`,
  toggleMenu: `absolute w-full float-right top-10`,
  item: `whitespace-pre text-gray-400 cursor-pointer px-3 py-3 font-light text-lg hover:text-black hover:font-medium`,
  item2: `whitespace-pre cursor-pointer px-1 py-3 font-light text-lg`,
  collapse: {
    default: `border-t border-gray-500 fixed left-0 mt-2 shadow py-2 text-center lg:border-none lg:flex lg:flex-grow lg:items-center lg:mt-0 lg:py-0 lg:relative lg:shadow-none`,
    open: `h-auto visible w-full opacity-100 lg:transition-none`,
    close: `h-auto invisible w-0 lg:opacity-100 lg:transition-none lg:visible`,
  },
  nav: {
    start: `block mb-0 mr-auto pl-0 lg:flex lg:mb-0 lg:pl-0 pt-1 ml-4`,
    middle: `block mb-0 ml-auto pl-0 lg:flex lg:pl-0 lg:mb-0 lg:mx-auto pt-1`,
    end: `block pl-0 mb-0 ml-auto lg:flex lg:pl-0 lg:mb-0 flex items-center pt-1`,
  },
  active: `text-black font-medium`,
}

const Context = React.createContext<INavbarContextProps>({})
const ContextDropdown = React.createContext<INavbarContextDropdownProps>({})

  /**
   * The Navbar component.
   *
   * A responsive navbar component that can be used to render a navigation bar.
   * It is responsive and will be fixed to the top of the page when the viewport
   * is less than 1024px.
   *
   * @param {React.ReactNode} children The children of the component.
   * @param {string} [className] The class name of the component.
   *
   * @example
   * <Navbar>
   *   <NavbarBrand href="/">Brand</NavbarBrand>
   *   <NavbarNav>
   *     <NavbarLink href="/about">About</NavbarLink>
   *     <NavbarLink href="/blog">Blog</NavbarLink>
   *   </NavbarNav>
   * </Navbar>
   */
function Navbar({children, className}: INavbarProps) {
  const [open, setOpen] = React.useState(false)
  const navbarRef = React.useRef(null)

  // this is done intentionally to prevent nav-items to show up.
  // when viewport is less than 1024px, the navbar will be fixed and be positioned to the top
  // because of that, we can't show navbar toggle on website to prevent all navbar examples to be display on the same position.
  const toggle = React.useCallback(() => {
    if (window.innerWidth > 1024) {
      setOpen(prevState => !prevState)
    }
  }, [])

  // close navbar on click outside when viewport is less than 1024px
  React.useEffect(() => {
    /**
     * Handles clicks outside of the navbar and hides it when it is visible.
     * It is only called when the viewport is less than 1024px.
     * @param {Event} event The event object.
     */
    const handleOutsideClick = (event: any) => {
      if (window.innerWidth < 1024) {
        // @ts-ignore
        if (!navbarRef.current?.contains(event.target)) {
          if (!open) return
          setOpen(false)
        }
      }
    }
    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [open, navbarRef])

  return (
    <Context.Provider value={{open, toggle}}>
      <nav ref={navbarRef} className={`${className} ${style.navbar}`}>
        {children}
      </nav>
    </Context.Provider>
  )
}

const useToggle = () => React.useContext(Context)

/* You can wrap the a tag with Link and pass href to Link if you are using either Create-React-App, Next.js or Gatsby */
function NavbarBrand({children, href}: INavbarBrandProps) {
  const navigate = useNavigate();
  return (
    <span className={style.brand} onClick={() => navigate(href)}>
      <strong>{children}</strong>
    </span>
  )
}

  /**
   * The NavbarToggler component renders a button to toggle the navbar menu.
   * The children passed to this component are only rendered when the navbar is open.
   * The navbar is closed automatically when the viewport is larger than 1024px.
   * The navbar is also closed when the user clicks outside of the navbar,
   * unless the viewport is larger than 1024px.
   * @param {React.ReactNode} children - The content of the navbar menu.
   * @returns {React.ReactElement} A React element representing the navbar toggler.
   */
function NavbarToggler({children}: Props) {
  const [open, setOpen] = React.useState(false)
  const navbarRef = React.useRef(null)

  // close navbar on click outside when viewport is less than 1024px
  React.useEffect(() => {
    /**
     * Handles clicks outside of the navbar and hides it when it is visible.
     * It is only called when the viewport is less than 1024px.
     * @param {Event} event The event object.
     */
    const handleOutsideClick = (event: any) => {
      if (window.innerWidth < 1024) {
        // @ts-ignore
        if (!navbarRef.current?.contains(event.target)) {
          if (!open) return
          setOpen(false)
        }
      } else {
        setOpen(false)
      }
    }
    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [open, navbarRef])

  const toggleDropDown = React.useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
    <button
      type="button"
      aria-expanded="false"
      aria-label="Toggle navigation"
      className={style.toggler}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(!open)
      }}>
      &#8801;
    </button>
      <ContextDropdown.Provider value={{toggleDropDown}}>
        <div className={style.toggleMenu} ref={navbarRef}>
          {open && children}
        </div>
      </ContextDropdown.Provider>
    </>
  )
}

export const useToggleDropdown = () => React.useContext(ContextDropdown)

  /**
   * A component that conditionally renders its children based on the
   * navbar open/closed state.
   *
   * @param {React.ReactNode} children - The children of the component.
   * @returns {React.ReactElement} A JSX element that conditionally renders its children.
   */
function NavbarCollapse({children}: Props) {
  const {open} = useToggle()
  return (
    <div
      style={{backgroundColor: 'inherit'}}
      className={`${style.collapse.default}
        ${open ? style.collapse.open : style.collapse.close}`}>
      {children}
    </div>
  )
}

  /**
   * A component that renders a navigation list.
   *
   * @param {React.ReactNode} children - The children of the component.
   * @param {string} orientation - The orientation of the navigation.
   * @param {string} className - The class name of the component.
   * @returns {React.ReactElement} A JSX element that renders a navigation list.
   */
function NavbarNav({children, orientation, className}: INavbarNavProps) {
  return (
    <ul className={`${orientation ? style.nav[orientation] : ''} ${className}`}>{children}</ul>
  )
}

  /**
   * A component that renders a navigation item.
   *
   * @param {React.ReactNode} children - The children of the component.
   * @returns {React.ReactElement} A JSX element that renders a navigation item.
   */
function NavbarItem({children}: Props) {
  return <li className={style.item}>{children}</li>
}

  /**
   * A component that renders a navigation item, but without the active state.
   *
   * @param {React.ReactNode} children - The children of the component.
   * @returns {React.ReactElement} A JSX element that renders a navigation item.
   */
function NavbarItem2({children}: Props) {
  return <li className={style.item2}>{children}</li>
}

/* You can wrap the a tag with Link and pass href to Link if you are using either Create-React-App, Next.js or Gatsby */
function NavbarLink({children, href, active, activeAt}: INavbarLinkProps) {
  return (
    <NavLink
      to={href}
      className={({isActive}: any) => isActive && style.active}>
      {children}
    </NavLink>
  )
}

export {
  Navbar,
  NavbarBrand,
  NavbarCollapse,
  NavbarNav,
  NavbarItem,
  NavbarItem2,
  NavbarLink,
  NavbarToggler,
}
