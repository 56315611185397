/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
} from '@reduxjs/toolkit/query/react';
import {Config} from '../Config';

/**
 * Prepares the headers for an API request by adding an authorization token if available.
 *
 * @param {Headers} headers - The headers to be prepared for the API request.
 * @param {{ getState: Function }} getState - A function to get the current state.
 * @return {Headers} The prepared headers with the authorization token if available.
 */

const baseQuery = fetchBaseQuery({
  baseUrl: Config.API_URL,
  prepareHeaders: (headers, {getState}) => {
    const token = (getState() as any).token.value;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

/**
 * A base query function that intercepts and handles API requests.
 * If the request results in a 401 error, it dispatches a logout token and updates the redirect URL.
 *
 * @param {string | FetchArgs} args - The arguments for the query.
 * @param {object} api - The API object.
 * @param {object} extraOptions - Additional options for the query.
 * @return {object} The result of the query.
 */

export const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // const redirectURL = getLocation();
  let result = await baseQuery(args, api, extraOptions);

  // if (result.error && result.error.status === 401) {
  //   // store.dispatch(logoutToken());

  //   // updateRedirectURL(redirectURL);
  // }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithInterceptor,
  endpoints: () => ({}),
});
