/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Components';
import useToggle from '../Components/_utils/useToggle';
import Field from '../Components/base/field/field';
import Icon from '../Components/base/icon/icon';
import { Modal, ModalBody, ModalCloseButton, ModalFooter } from '../Components/base/modal/modal';
import { ToolTip } from '../Components/base/tooltip/tooltip';
import { toastError, toastSuccess } from '../Components/toast';
import { useFooterHook } from '../Hooks/FooterHook';
import { useSignUpHook } from '../Hooks/SignUp';
import { getSignUpToken } from '../Services/signUpReducer';
import { useAppSelector } from '../Store/hooks';
import { FeedbackCategory } from '../models/feedbackCategory.model';
import Chip from '../Components/Chip';
import { getCreatorFilter } from '../Services/creatorReducer';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

/**
 * The Footer component.
 *
 * This component is responsible for rendering the footer of the page.
 *
 * It displays the links to the about page, privacy policy, and terms and conditions.
 * It also renders a form for the user to submit feedback, which includes a text area for the user to enter their feedback,
 * a field for selecting a category, and a field for uploading an attachment.
 * The form is validated using Yup, and the user is required to enter a valid email address and select a category.
 * The form also includes a recaptcha field to prevent spam.
 *
 * The component uses the useFooterHook hook to access the data and functions needed to render the form.
 * It also uses the useSignUpHook hook to access the token needed to invite a user to sign up.
 *
 * @returns {JSX.Element} the footer component
 */
function Footer() {
  const { toggle, isOpen } = useToggle();

  const {
    currentUser,
    feedBackCategoryData,
    siteKey,
    chipsVisibility,
    setChipsVisibility,
    onChange,
    setRecaptchaValue,
    disable,
    setDisable,
    recaptchaValue,
    onSubmit,
    setFileValue,
    contactUsSuccess,
    contactUsLoading,
    onSelectedFile,
    removeFile,
    clearVisibility,
    fileValue,
    t,
    reset,
    handleSubmit,
    register,
    trigger,
    errors,
    setValue,
    nonLoginSuccess,
    watch,
    // setCurrentMail
  } = useFooterHook();

  const [showOtherCategory, setShowOtherCategory] = useState(false);
  const token = useAppSelector(getSignUpToken);
  // const { invite } = useSignUpHook(token);
  const creatorLocalFilter = useAppSelector(getCreatorFilter);

  useEffect(() => {
    if (isOpen) {
      if (currentUser) {
        setValue('email', currentUser.email);
      }
    }
  }, [currentUser,  isOpen]);

  useEffect(() => {
    if (contactUsSuccess || nonLoginSuccess) {
      toastSuccess(t('feedbackRegisteredSuccessfully'));
      toggle();
      resetForm();
    }
  }, [contactUsSuccess, nonLoginSuccess]);

  // useEffect(() => {
  //   if (invite && !currentUser) {
  //     setCurrentMail(true);
  //   }
  //   if (currentUser?.email) {
  //     setCurrentMail(true);
  //   }
  // }, [currentUser, invite]);

  const style = {
    footer: `flex flex-col md:flex-row justify-start text-center md:justify-between border-t text-gray-600 text-sm p-3 relative z-10`,
    footerItems: `hover:underline cursor-pointer hover:text-sky-800`,
  };

  /**
   * This function validates the selected file format
   * @param e - an event object
   * @returns void
   */
  const validateFormate = (e: any) => {
    const { value } = e.target;
    if (value.length > 0) {
      if (value.split('.').pop() !== 'png' && value.split('.').pop() !== 'jpeg' && value.split('.').pop() !== 'jpg') {
        setValue('file', '');
        toastError(t('attachmentShouldBePngOrJpeg'));
      } else {
        onSelectedFile(e);
      }
    }
  };

  /**
   * Resets the form state and closes the modal.
   *
   * Sets the recaptcha value to false, toggles the modal state, resets the form
   * values, and sets the file value to an empty array. If chipsVisibility is false,
   * it sets it to true.
   */
  const resetForm = () => {
    setRecaptchaValue(false);
    toggle();
    reset();
    setFileValue([])
    if (!chipsVisibility) {
      setChipsVisibility(true);
    }
  };

  /**
   * A function that removes a category from the list of selected categories.
   *
   * It takes an item as an argument, which is the category to be removed.
   * It then sets the value of meetingCategory to the filtered list of categories
   * that does not include the item.
   *
   * @param {any} item - the category to be removed
   * @returns {void}
   */
  const handleRemoveCategoryList = (item: any): void => {
    setValue('meetingCategory', creatorLocalFilter.meetingCategory?.filter((category: any) => category !== item));
  };

  return (
    <div className='w-full self-end'>
      <footer className={style.footer}>
        <div className="w-full justify-center md:justify-start md:w-3/5 flex space-x-3  md:mt-2.5 md:ml-5">
          <Link id="footerMenu_about" to={currentUser ? "/s/about" : "/about"}>
            <span className={style.footerItems}>{t('about')}</span>
          </Link>
          <a href="https://help.beemg.com/" id="footerMenu_priva" target="_blank" rel="noreferrer">
            <span className={style.footerItems}>{t('privacyPolicy')}</span>
          </a>
          <Link id="footerMenu_terms" to={currentUser ? "/s/termsAndConditions" : "/termsAndConditions"}>
            <span className={style.footerItems}>{t('termsAndConditions')}</span>
          </Link>
          <span id="btn_support" data-testid="btn_support" className={style.footerItems} onClick={toggle}>{t('support')}</span>
        </div>
        <span data-testid="txt_footer" className="mt-3 md:mt-2.5 mx-4">&copy; {new Date().getFullYear()} Himitsu Lab Inc.</span>
      </footer>
      <form data-testid="form_submit" onSubmit={handleSubmit(onSubmit)}>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          position="large"
          closeOnClickOutside={false}
        >
          <ModalBody>
            <div onClick={() => { resetForm() }}>
              <ModalCloseButton toggle={toggle} />
            </div>
            <div className="flex flex-col px-6">
              <span className="text-3xl flex items-center justify-center mt-0">{t(`getInTouch`)} !</span>
              <hr className='mt-4' />
              <div id="input_emails" className="col-span-6 flex justify-center mt-4">
               
                  {currentUser ? (
                    <span>{currentUser?.email}</span>
                  ) : (
                    <div className="w-full justify-start items-start rounded-md">
                      <Field
                        {...register('email')}
                        error={(errors?.email?.message)}
                        id="input_email"
                        data-testid="input_email"
                        type="text"
                        placeholder={t('email')}
                      />
                    </div>
                  )
                }
              </div>

              <div className={!chipsVisibility ? "" : "mt-4"}>
                <div data-testid={`categories_${feedBackCategoryData?.data?.length}`} className="flex flex-row flex-wrap gap-2">
                  {chipsVisibility && (
                    <>
                      {feedBackCategoryData && feedBackCategoryData.data?.map(
                        (categoryList: FeedbackCategory) => (
                          <Chip
                            onClickClose={() => handleRemoveCategoryList(categoryList.categoryName)}
                            value={t(`contactUsCategories.${categoryList.categoryName}`)}
                            key={categoryList.id}
                            onClick={e => {
                              setValue('category', (categoryList.categoryName));
                              trigger('category');
                              setChipsVisibility(false);
                              setDisable(true);
                            }}
                            addCatId={`input_${categoryList.categoryName}Category`}
                            removeCatId={`remove_${categoryList.categoryName}Category`}
                          />
                        ),
                      )}
                      <Chip
                        addCatId="btn_addOthers"
                        isSelected={showOtherCategory}
                        onClick={() => {
                          setShowOtherCategory(!showOtherCategory);
                          setChipsVisibility(false);
                        }}
                        value={t('others')}
                      />
                      {errors.category && <span id="add_category" className="text-red-500">{t((errors.category as any).message)}</span>}
                    </>
                  )}
                  {showOtherCategory &&
                    <div className="flex gap-2">
                      <div>
                        <Field data-testid="input_others" id="input_catOthers" type='text' placeholder={t('enterOtherCategory')} {...register('Others')} maxLength={50} />
                      </div>
                      <Button data-testid="btn_add" id="btn_addCatOthers" size="sm" className='m-1 rounded' color="save" onClick={() => {
                        if (watch('Others') !== '') {
                          setValue('category', watch('Others'));
                          setChipsVisibility(false);
                          setShowOtherCategory(false);
                        }
                        setValue('Others', '');
                      }} >{t('add')}</Button>
                      <Button data-testid="btn_cancelOthers" id="btn_cancelCatOthers" size="sm" className='m-1 rounded' color="save" onClick={() => setShowOtherCategory(false)} >{t('cancel')}</Button>
                    </div>
                  }
                </div>
                <div className="flex flex-row mb-4 my-2 gap-3 items-start">
                  {!chipsVisibility && !showOtherCategory && (
                    <>
                      <div className="col-span-6 w-3/5">
                        <Field
                          {...register('category')}
                          error={(errors?.category?.message)}
                          name="category"
                          id="input_category"
                          data-testid="input_category"
                          disabled={disable}
                          type="category"
                          placeholder={t('category')}
                          defaultValue={watch('category')}
                        />
                      </div>
                      <div>
                        <Button
                          id="btn_clear"
                          data-testid="btn_clear"
                          onClick={() => {
                            setChipsVisibility(true);
                            setValue('category', '');
                          }}
                          size="clear"
                          color="category"
                        >
                          {t('clear')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                <div className='my-2'>
                  <div className="col-span-6 w-full -mt-4">
                    <Field
                      {...register('subject')}
                      error={(errors?.subject?.message)}
                      id="input_subject"
                      data-testid="input_about"
                      name="subject"
                      type="subject"
                      placeholder={t('whatIsItAbout') + '?' + ' *'}
                    />
                  </div>
                </div>
                <div className='my-4 mt-0'>
                  <Field
                    {...register('feedback')}
                    error={(errors?.feedback?.message)}
                    id="input_feedback"
                    data-testid="input_subject"
                    name="feedback"
                    type="textarea"
                    rows={7}
                    placeholder={t(`elaborateYourSubject`) + ' *'}
                  />
                </div>
                <div className="my-6 items-center cursor-pointer">
                  <div>
                    <label htmlFor="file-upload-contact" className='justify-center flex-1'>
                      <div
                        className="my-2 w-full flex justify-center p-2 border-2 border-dashed rounded-md cursor-pointer">
                        <div className="space-y-1 text-center">
                          <div id="input_file" className="flex w-full justify-center text-center text-xs text-gray-300 py-2">
                            <div>
                              <input
                                type="file"
                                id="file-upload-contact"
                                data-testid="file-upload-contact"
                                accept=".png,.jpeg,.jpg"
                                className='hidden'
                                onChange={validateFormate}
                              />
                            </div >
                            <div className="flex items-center">
                              <ToolTip tip={t('attachmentShouldBePngOrJpeg')} keyId="feedback">
                                <div className="flex items-center">
                                  <Icon icon="ADD" size="x-small" height="small" stroke='#010101' />
                                  <span className="ml-2">{t('addAttachment')}</span>
                                </div>
                              </ToolTip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div>
                    {!clearVisibility && fileValue && Array.isArray(fileValue) && fileValue.length > 0 && (
                      <div>
                        {fileValue.map((file, index) => (
                          <div key={index} className='inline-block mr-2 mb-2'>
                            {file && file.name && (
                              <Chip
                                data-testid={`fileName-${file.name}`}
                                onClickClose={() => removeFile(file.name)} // Pass file name as an argument
                                isSelected={true}
                                key={index}
                                removeCatId={`btn_removeCat_${index}`}
                                value={file.name}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex justify-center col-span-6 w-full -mt-4">
                  {siteKey && (
                    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                      <GoogleReCaptcha onVerify={onChange} />
                    </GoogleReCaptchaProvider>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="p-5 flex gap-2 -mt-2">
              <Button
                onClick={resetForm}
                color="transparent"
                id="btn_cancel"
                data-testid="btn_goBack"
                className='uppercase text-sm'
              >
                {t(`goBack`)}
              </Button>
              <Button
                submit autoFocus disabled={!recaptchaValue || contactUsLoading} id="btn_submit" data-testid="btn_submit"
                color="filteredData"
                className='uppercase text-sm w-52'
              >
                {t(`confirm`)}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </form>
    </div>
  );
}

export default Footer;
