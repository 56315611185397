/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {
  LiveKitUser,
  UserType,
  ConnectParamsType,
  MetaDataType,
  LiveKitEvent,
  LiveKitEventsType,
  LiveKitCommand,
  CommandTypes,
  LiveKitChat,
} from './../../../models/livekitTypes';
import {
  getRemoteMeetingUsers,
  setCurrentMeetingUser,
  setMeetingConnected,
  setMeetingJoined,
  getMeetingInfo,
  getCurrentMeetingUser,
  changeHandRaiseState,
  toggleMeetingChat,
  setShowAllowedUnmuteToast,
} from './../../../Services/livekitReducer';
import {
  LocalParticipant,
  RemoteParticipant,
  Room,
  RoomEvent,
  RoomOptions,
  VideoPresets,
} from 'livekit-client';
import {RootState, store} from '../../../Store';
import {createAction, nanoid} from '@reduxjs/toolkit';

/**
 * To get the options for LiveKitRoom
 * @returns RoomOptions Object
 */
export const getLiveKitRoomOptions = ({
  isPeerToPeer,
}: {
  isPeerToPeer?: boolean | undefined;
}) => {
  const liveKitRoomOptions: RoomOptions = {
    adaptiveStream: true,
    dynacast: true,
    publishDefaults: {
      simulcast: false,
    },
    videoCaptureDefaults: {
      resolution: isPeerToPeer
        ? VideoPresets.h540.resolution
        : VideoPresets.h1080.resolution,
    },
    audioCaptureDefaults: {
      noiseSuppression: true,
      echoCancellation: true,
    },
  };

  return liveKitRoomOptions;
};

/**
 * Checks if the given user is a host or a co-host.
 *
 * @param {LiveKitUser} user - The user to check.
 * @return {boolean} True if the user is a host or a co-host, false otherwise.
 */

export const isHostOrCohost = (user: LiveKitUser) =>
  user.userType === UserType.Host || user.userType === UserType.CoHost;

/**
 * Executed once when the room is connected successfully.
 */
export async function onConnected(
  room: Room,
  connectParams: ConnectParamsType,
) {
  store.dispatch(
    setCurrentMeetingUser({
      participantId: room.localParticipant.sid,
    } as LiveKitUser),
  );
  store.dispatch(setMeetingConnected(true));
  store.dispatch(setMeetingJoined(true));
  onLiveKitRoomConnected(room);

  // !only web
  (window as any).currentRoom = room;
  const {audioEnabled, videoEnabled, videoDeviceId, audioDeviceId} =
    connectParams;

  if (audioEnabled) {
    if (audioDeviceId && room.options.audioCaptureDefaults) {
      room.options.audioCaptureDefaults.deviceId = audioDeviceId;
    }
    await room.localParticipant.setMicrophoneEnabled(true);
  }

  if (videoEnabled) {
    if (videoDeviceId && room.options.videoCaptureDefaults) {
      room.options.videoCaptureDefaults.deviceId = videoDeviceId;
    }
    await room.localParticipant.setCameraEnabled(true);
  }
}

/**
 * Retrieves the LiveKit user(s) with the specified user ID.
 *
 * @param {string} userId - The user ID to search for.
 * @return {LiveKitUser[]} An array of LiveKit users with the specified user ID.
 */

export const getLiveKitUserByUserId = (userId: string) => {
  const remoteMeetingUsers = getRemoteMeetingUsers(
    store.getState() as RootState,
  );
  const keys = Object.keys(remoteMeetingUsers);
  const participantIds = keys.filter(
    participantId => remoteMeetingUsers[participantId].userId === userId,
  );
  return participantIds.map(participantId => remoteMeetingUsers[participantId]);
};

/**
 * LiveKit action to extraReducer
 */
export const liveKitEventAction = createAction(
  'livekit/events/action',
  function prepare(event: LiveKitEvent) {
    return {payload: event};
  },
);

/**
 * To create liveKit commands
 */
const createLiveKitCommand = (command: CommandTypes): LiveKitCommand => {
  const meetingInfo = getMeetingInfo(store.getState() as RootState);
  const currentMeetingUser = getCurrentMeetingUser(
    store.getState() as RootState,
  );

  const commandMessage: LiveKitCommand = {} as LiveKitCommand;
  commandMessage.command = command;
  commandMessage.meetingId = meetingInfo.meetingId;
  commandMessage.participantId = currentMeetingUser?.participantId ?? '';

  return commandMessage;
};

/**
 * Perform additional functions on room connect
 */
export const onLiveKitRoomConnected = async (room: Room) => {
  sendUserInfo(room.localParticipant); // send user info to the room once connected.
  sendUserInfoRequest(room.localParticipant); // send user info request to the room once connected for others data

  room.on(
    RoomEvent.ParticipantConnected,
    (connectedParticipant: RemoteParticipant) => {
      sendUserInfo(room.localParticipant, [connectedParticipant]);
      if (connectedParticipant.metadata && connectedParticipant.sid) {
        const metadata = JSON.parse(
          connectedParticipant.metadata,
        ) as MetaDataType;
        const sid = connectedParticipant.sid;

        const remoteUser = {
          avatar: metadata.avatar,
          displayName: metadata.displayName,
          participantId: sid,
          userId: metadata.userId,
          userType: metadata.userType,
          isMuted: connectedParticipant.isMicrophoneEnabled,
          isVideoMuted: connectedParticipant.isCameraEnabled,
          isAllowedUnmute:
            metadata.userType === UserType.Host ||
            metadata.userType === UserType.CoHost,
          isHandRaised: false,
        } as LiveKitUser;

        store.dispatch(
          liveKitEventAction({
            type: LiveKitEventsType.USER_JOINED,
            data: remoteUser,
          } as LiveKitEvent),
        );
      }
    },
  );
  room.on(
    RoomEvent.ParticipantDisconnected,
    (disconnectedParticipant: RemoteParticipant) => {
      if (disconnectedParticipant.sid) {
        store.dispatch(
          liveKitEventAction({
            type: LiveKitEventsType.USER_LEFT,
            data: disconnectedParticipant.sid,
          } as LiveKitEvent),
        );
      }
    },
  );
  room.on(RoomEvent.DataReceived, payload => {
    onDataReceived(room, payload);
  });
};

/**
 * Toggle the handraise state and send userInfo to the room.
 */
export const toggleHandRaise = (
  localParticipant: LocalParticipant,
  value?: boolean | undefined,
) => {
  const currentMeetingUser = getCurrentMeetingUser(
    store.getState() as RootState,
  );
  const isHandRaised = currentMeetingUser.isHandRaised;
  store.dispatch(changeHandRaiseState(value ?? !isHandRaised));

  const refreshedUser = getCurrentMeetingUser(store.getState() as RootState);
  const handRaiseCommand: LiveKitCommand = createLiveKitCommand(
    CommandTypes.HAND_RAISE,
  );
  handRaiseCommand.params = refreshedUser;

  sendToRoom(handRaiseCommand, localParticipant);
};

/**
 * Toggle the chat modal open state state
 */
export const toggleLiveKitChat = () => {
  store.dispatch(toggleMeetingChat());
};

/**
 * Send Text chat to room
 */
export const sendLiveKitChat = (
  localParticipant: LocalParticipant,
  message: string,
) => {
  const meetingInfo = getMeetingInfo(store.getState() as RootState);
  const currentMeetingUser = getCurrentMeetingUser(
    store.getState() as RootState,
  );

  const liveKitChatMessage = {} as LiveKitChat;
  liveKitChatMessage.userName = currentMeetingUser.displayName;
  liveKitChatMessage.chatId = nanoid();
  liveKitChatMessage.text = message;
  liveKitChatMessage.senderUserId = currentMeetingUser.userId ?? '';
  liveKitChatMessage.meetingId = meetingInfo.meetingId;
  liveKitChatMessage.dateTimeUtc = new Date().toUTCString();

  const chatCommand: LiveKitCommand = createLiveKitCommand(
    CommandTypes.CHAT_MESSAGE,
  );
  chatCommand.participantId = currentMeetingUser.participantId;
  chatCommand.meetingId = meetingInfo.meetingId;
  chatCommand.params = liveKitChatMessage;

  sendToRoom(chatCommand, localParticipant);

  store.dispatch(
    liveKitEventAction({
      type: LiveKitEventsType.RECEIVED_CHAT_MESSAGE,
      data: chatCommand,
    } as LiveKitEvent),
  );
};

/**
 * Send unmuteAllowed to the remoteParticipant if currentMeetingUser is moderator.
 */
export const sendUnmuteAllowed = (
  localParticipant: LocalParticipant,
  remoteParticipantId: string,
  allowedUnmute: boolean,
) => {
  const sendUnmuteAllowedCommand: LiveKitCommand = createLiveKitCommand(
    CommandTypes.UNMUTE_ALLOWED,
  );
  sendUnmuteAllowedCommand.params = {};
  sendUnmuteAllowedCommand.params.participantId = remoteParticipantId;
  sendUnmuteAllowedCommand.params.isAllowedUnmute = allowedUnmute;

  sendToRoom(sendUnmuteAllowedCommand, localParticipant, [remoteParticipantId]);
};

/**
 * Send mute to the remoteParticipant if currentMeetingUser is moderator.
 */
export const sendMuteOne = (
  localParticipant: LocalParticipant,
  remoteParticipantId: string,
) => {
  const sendMuteOneCommand: LiveKitCommand = createLiveKitCommand(
    CommandTypes.MUTE_ONE,
  );
  sendMuteOneCommand.params = {};
  sendMuteOneCommand.params.participantId = remoteParticipantId;

  sendToRoom(sendMuteOneCommand, localParticipant, [remoteParticipantId]);
};

/**
 * Send the currentMeetingUser's data to the room.
 */
export const sendUserInfo = (
  localParticipant: LocalParticipant,
  destination?: string[] | RemoteParticipant[] | undefined,
) => {
  const currentMeetingUser = getCurrentMeetingUser(
    store.getState() as RootState,
  );
  const currentUserInfoCommand: LiveKitCommand = createLiveKitCommand(
    CommandTypes.USER_INFO,
  );
  currentUserInfoCommand.params = currentMeetingUser;

  sendToRoom(currentUserInfoCommand, localParticipant, destination);
};

/**
 * Send a request for other user's info from the room.
 */
export const sendUserInfoRequest = (localParticipant: LocalParticipant) => {
  sendToRoom(
    createLiveKitCommand(CommandTypes.USER_INFO_REQUEST),
    localParticipant,
  );
};

/**
 * Send endForAll to the room.
 */
export const sendEndForAll = (localParticipant: LocalParticipant) => {
  sendToRoom(createLiveKitCommand(CommandTypes.END_FOR_ALL), localParticipant);
};

/**
 * Send muteAll to the room.
 */
export const sendMuteAll = (localParticipant: LocalParticipant) => {
  sendToRoom(createLiveKitCommand(CommandTypes.MUTE_ALL), localParticipant);
};

/**
 * Send unMuteAll to the room.
 */
export const sendUnMuteAll = (localParticipant: LocalParticipant) => {
  sendToRoom(createLiveKitCommand(CommandTypes.UNMUTE_ALL), localParticipant);
};

/**
 * Triggers when participant receives data from the room.
 */
const onDataReceived = (room: Room, payload: Uint8Array) => {
  const currentMeetingUser = getCurrentMeetingUser(
    store.getState() as RootState,
  );

  const decoder = new TextDecoder();
  const decodedData = decoder.decode(payload);

  const receivedCommand = JSON.parse(decodedData) as LiveKitCommand;

  switch (receivedCommand.command) {
    case CommandTypes.USER_INFO: {
      //add to the state of remote users.
      store.dispatch(
        liveKitEventAction({
          type: LiveKitEventsType.RECEIVED_USER_INFO,
          data: receivedCommand,
        } as LiveKitEvent),
      );
      break;
    }
    case CommandTypes.USER_INFO_REQUEST: {
      //send user info to the room.
      sendUserInfo(room.localParticipant);
      break;
    }
    case CommandTypes.END_FOR_ALL: {
      //disconnect the currentMeeting and navigate to respective routes
      store.dispatch(
        liveKitEventAction({
          type: LiveKitEventsType.RECEIVED_END_FOR_ALL,
          data: receivedCommand,
        } as LiveKitEvent),
      );
      break;
    }
    case CommandTypes.HAND_RAISE: {
      //update the remoteUser's handraise state and show only to moderators
      store.dispatch(
        liveKitEventAction({
          type: LiveKitEventsType.RECEIVED_HAND_RAISED,
          data: receivedCommand,
        } as LiveKitEvent),
      );
      sendUserInfo(room.localParticipant);
      break;
    }
    case CommandTypes.MUTE_ALL: {
      //mute the localparticipant and set the allowUnmute to false.
      if (!isHostOrCohost(currentMeetingUser)) {
        room.localParticipant.setMicrophoneEnabled(false);
        room.localParticipant.setCameraEnabled(false);
        store.dispatch(
          liveKitEventAction({
            type: LiveKitEventsType.RECEIVED_MUTE_ALL,
            data: receivedCommand,
          } as LiveKitEvent),
        );
        sendUserInfo(room.localParticipant);
      }
      break;
    }
    case CommandTypes.MUTE_ONE: {
      //mute the localparticipant and set the allowUnmute to false
      const isCurrentMeetingUser =
        receivedCommand?.params?.participantId ===
        currentMeetingUser.participantId;
      if (isCurrentMeetingUser && !isHostOrCohost(currentMeetingUser)) {
        room.localParticipant.setMicrophoneEnabled(false);
        room.localParticipant.setCameraEnabled(false);
        store.dispatch(
          liveKitEventAction({
            type: LiveKitEventsType.RECEIVED_MUTE_ONE,
            data: receivedCommand,
          } as LiveKitEvent),
        );
        sendUserInfo(room.localParticipant);
      }
      break;
    }
    case CommandTypes.UNMUTE_ALLOWED: {
      //set the allowUnmute to true and set the handRaised to false if raised.
      const isCurrentMeetingUser =
        receivedCommand?.params?.participantId ===
        currentMeetingUser.participantId;

      if (isCurrentMeetingUser && !isHostOrCohost(currentMeetingUser)) {
        handleAllowedUnmute({room, receivedCommand});
      }
      break;
    }
    case CommandTypes.UNMUTE_ALL: {
      //set the allowUnmute to true and set the handRaised to false if raised.
      if (!isHostOrCohost(currentMeetingUser)) {
        handleAllowedUnmute({room, receivedCommand});
      }
      break;
    }
    case CommandTypes.CHAT_MESSAGE: {
      //add the chat payload to the state
      store.dispatch(
        liveKitEventAction({
          type: LiveKitEventsType.RECEIVED_CHAT_MESSAGE,
          data: receivedCommand,
        } as LiveKitEvent),
      );
      break;
    }
  }
};

/**
 * Sender function for the localparticipant to publish data to others in room.
 */
export const sendToRoom = (
  data: LiveKitCommand,
  localParticipant: LocalParticipant,
  destination?: string[] | RemoteParticipant[] | undefined,
) => {
  const dataAsString = JSON.stringify(data);
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(dataAsString);

  localParticipant.publishData(encodedData);
};

/**
 * To get the gridAspectRatio layout size for VideoMeeting gridView
 * @returns boxw: number,boxh: number | boolean
 */

export const getGridAspect = (
  boxw: number,
  screenWidth: number,
  screenHeight: number,
  userCount: number,
): {boxw: number; boxh: number} | boolean => {
  boxw += 5;
  const boxh = (boxw * 9) / 16;

  const colUsers = Math.floor(screenWidth / boxw);

  if (userCount > colUsers) {
    const rowUsers = Math.ceil(userCount / colUsers);
    const nH = boxh * rowUsers;
    if (nH > screenHeight) {
      return false;
    }
  }

  const res = getGridAspect(boxw, screenWidth, screenHeight, userCount);
  if (res === false) {
    return {boxw, boxh};
  }
  return res;
};

/**
 * To get the aspectRatio layout size for VideoMeeting PinnedParticipant / SingleParticipantView
 * @returns width: number,: number
 */

export const getAspectSize = ({w, h}: {w: number; h: number}) => {
  const u = 2;
  w = w - 50;
  h = h - 50;

  const cw = w / u;
  const ch = h / u;

  const ach = (cw * 9) / 16;
  const acw = (ch * 16) / 9;

  if (ach > ch) {
    return {width: cw, height: ach};
  }

  return {width: acw, height: ch};
};

//common function to handle allowedUnmute event
const handleAllowedUnmute = ({
  room,
  receivedCommand,
}: {
  room: Room;
  receivedCommand: LiveKitCommand;
}) => {
  store.dispatch(
    liveKitEventAction({
      type: LiveKitEventsType.RECEIVED_UNMUTE_ALLOWED,
      data: receivedCommand,
    } as LiveKitEvent),
  );

  //boolean change for notifying the participant only once.
  store.dispatch(setShowAllowedUnmuteToast(true));

  setTimeout(() => {
    store.dispatch(setShowAllowedUnmuteToast(false));
  }, 2000);

  sendUserInfo(room.localParticipant);
};
