/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { ToolTip } from '../../Components/base/tooltip/tooltip'
import { useTranslation } from 'react-i18next'
import Field from '../../Components/base/field/field'
import Button from '../../Components/base/button/button'
import { useNavigate } from 'react-router-dom'

interface _BeemgSwitchProps {
  errors: any
  register: any
  checked: boolean | undefined
  htmlFor?: string | undefined
  beemgSwitchText: string
  name: string
  disabled?: boolean
  registerValue: string
  onClick?: any
  showAnonymous?: boolean
  navigateTo?: any
  buttonText?: string
  toolTipText?: string
}

/**
 * A react component for a switch with a label and optional tooltip.
 * When showAnonymous is true, a button is displayed with the text
 * specified by buttonText. When the button is clicked, the user is
 * navigated to the location specified by navigateTo.
 *
 * @param {object} props - Component props
 * @param {object} props.errors - The errors from react-hook-form
 * @param {function} props.register - The register function from react-hook-form
 * @param {boolean | undefined} props.checked - The value of the switch
 * @param {string | undefined} props.htmlFor - The htmlFor attribute of the label
 * @param {string} props.beemgSwitchText - The label text
 * @param {string} props.name - The name of the input
 * @param {boolean} [props.disabled=false] - Whether the switch is disabled
 * @param {string} props.registerValue - The value of the switch
 * @param {function} [props.onClick] - The function to call when the switch is clicked
 * @param {boolean} [props.showAnonymous=false] - Whether to show the button
 * @param {string} [props.navigateTo] - The location to navigate to when the button is clicked
 * @param {string} [props.buttonText] - The text of the button
 * @param {string} [props.toolTipText] - The text of the tooltip
 *
 * @return {JSX.Element} The BeemgSwitch component
 */
export default function BeemgSwitch(props: _BeemgSwitchProps) {
  const { errors, register, checked, htmlFor, beemgSwitchText, name, disabled, registerValue, onClick, showAnonymous, navigateTo, buttonText, toolTipText } = props
  const { t } = useTranslation()
  const navigate = useNavigate();
  
  return (
    <>
      <div className={`${showAnonymous ? "flex flex-col md:flex-row w-full" : 'flex flex-row items-center w-full'}`}>
        {toolTipText && (
          <div className='w-full justify-start'>
            <ToolTip tip={toolTipText ?? ''} keyId="share">
              <div className="flex-row flex items-center justify-between">
                <label htmlFor={htmlFor}>{t(beemgSwitchText)}</label>
                <Field
                  {...register(registerValue)}
                  error={errors}
                  name={name}
                  type="checkbox"
                  defaultChecked={checked}
                  readOnly={disabled}
                  onClick={onClick}
                />
                {showAnonymous && buttonText && (
                  <div className='ml-8 md:ml-0'>
                    <Button size="sm" rounded color="join" onClick={() => {navigate(navigateTo)}}>
                      <p id="btn_anonymousProfile" data-testid="btn_anonymousProfile" className="text-xs md:text-sm ">{t(buttonText)}</p>
                    </Button>
                  </div>
                )}
              </div>
            </ToolTip>
          </div>
        )}
        {!toolTipText && (
          <div className='flex flex-col w-full'>
            <div className="flex items-center justify-between">
                <label htmlFor={htmlFor}>{t(beemgSwitchText)}</label>
                <Field
                  {...register(registerValue)}
                  error={errors}
                  name={name}
                  type="checkbox"
                  defaultChecked={checked}
                  readOnly={disabled}
                  onClick={onClick}
                />
            </div>
            {showAnonymous && buttonText && (
              <div className="items-start">
                <Button size="sm" rounded color="join" onClick={() => {navigate(navigateTo)}}>
                  <p id="btn_anonymousProfile" data-testid="btn_anonymousProfile" className="text-xs md:text-sm">{t(buttonText)}</p>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}