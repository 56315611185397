/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "(clickOrDropVideo)": "（ビデオをクリックまたはドロップ）",
  "(clickOrDropImage)": "(画像をクリックまたはドロップ)",
  "(guests)": "（ゲスト) ",
  "about": "約",
  "accept": "受け入れる",
  "accepted": "承認済み",
  "activate": "活性化",
  "acceptRequest": "要請を受け入れます",
  "accountName": "アカウント名",
  "accountNameCanBeMax25CharactersLong": "アカウント名は25文字以下にしてください",
  "accountNumber": "口座番号",
  "accountNumberCanBeMax16Digits": "アカウント番号は16桁以下にしてください",
  "accountWillBeActivatedInADay": "アカウントは1日で有効になります",
  "actions": "アクション",
  "activateYourRazorpayAccount": "Razorpayアカウントをアクティブ化する",
  "activateYourStripeAccount": "ストライプアカウントをアクティブ化する",
  "activateYourPaymentAccountFromSettingsPageToReceivePayment": "設定ページから支払いアカウントをアクティブ化して支払いを受け取ります",
  "activateYourPaymentAccountToCreatePaidEvents": "支払いアカウントをアクティブ化して、有料イベントを作成します",
  "activeRequests": "アクティブなリクエスト",
  "actor": "俳優",
  "actualTime": "実時間",
  "add": "追加",
  "add/editMeetingDetails": "会議の詳細を追加/編集",
  "addAllMeetingsToCalendar": "すべての会議をカレンダーに追加する",
  "addAttachment": "添付ファイルを追加する",
  "addCohost": "共同ホストを追加",
  "imagesandvideoupload":"画像とビデオのアップロード",
  "addImage": "画像を追加",
  "addMeetingURL": "会議URLを追加",
  "addMoreOptions": "さらにオプションを追加する",
  "addNewEvent": "新しいイベントを追加する",
  "addNewGuest": "新しいゲストを追加",
  "addNewImage": "新しい画像を追加する",
  "addNewMeeting": "新しい会議を追加する",
  "addNewSchedule": "新しいスケジュールを追加する",
  "addNewSession": "新しいセッションを追加する",
  "youCanChooseToCreateTheEventWithoutAddingAnyImage": "要件を追加する",
  "newSession": "新しいセッション",
  "addSelfIntroductionAndStyleItUsingBelowButtons": "自己紹介を追加し、下のボタンを使用してスタイルを設定します。",
  "addTheBelowURLInYourCalendar": "カレンダーに以下のURLを追加してください",
  "addThisMeetingToCalendar": "この会議をカレンダーに追加する",
  "addToCalendar": "カレンダーに追加",
  "addToFavorites": "お気に入りに追加",
  "addRequirements": "追加要件",
  "removeFromFavorites": "お気に入りから削除",
  "addVideo": "ビデオを追加",
  "addYours": "あなたのものを追加する",
  "administrator": "管理者",
  "after": "後",
  "afternoon": "午後",
  "ageMustBe": "年齢は",
  "agree": "同意",
  "all": "全て",
  "allHosts": "すべてのホスト",
  "allHostsLeftTheMeeting": "すべてのホストが会議を離れたため、セッションは終了します",
  "allOnlineUsers": "すべてのオンラインユーザー",
  "allowFreeConnectionWithOtherGuests": "他のゲストとの無料接続を許可する",
  "allowOtherUserToConnectWithYou": "他のユーザーに自分との接続を許可する",
  "allowOtherPeopleToSendYouRequestForVideo": "他のユーザーがあなたにビデオのリクエストを送信できるようにする",
  "allowOtherUsersToMessageYou": "他のユーザーがあなたにメッセージを送ることを許可する",
  "allReviews":"すべてのレビュー",
  "allPrivateSessionReviews":"すべての非公開セッションレビュー",
  "SelectCategoriesOfYourInterest": "興味のあるカテゴリーを選択する",
  "alreadyAMember": "すでにメンバー",
  "alreadyInvited": "すでに招待されています",
  "alreadyMember": "すでにメンバー",
  "amount": "料金",
  "amountCredited": "クレジット金額",
  "amountCreditedThisMonth": "今月の入金金額",
  "amountDebited": "借方記入額",
  "amountDebitedThisMonth": "今月の引き落とし額",
  "amountRefundedAfterCancellationCharges": "キャンセル後の返金額",
  "amountPaid": "払込金額",
  "amountFullyRefunded": "全額返金額",
  "andBefore": "そして以前",
  "andMeetLikeMindedPeople": "志を同じくする人々に会う",
  "apply": "適用する",
  "approve": "承認する",
  "approved": "承認済み",
  "approx": "約",
  "areYouSureYouWantToExitTheCall?": "通話を終了してもよろしいですか ？",
  "areyouSureToCancelTheRequest?": "リクエストをキャンセルしてもよろしいですか ?",
  "areYouSureToCancelThisEven?t": "このイベントをキャンセルしてもよろしいですか ?",
  "areYouSureToDeleteThisEvent": "これを削除してもよろしいですか",
  "areyouSureToSendCancellationRequestForThisEvent": "このイベントのキャンセルリクエストを送信してもよろしいですか ?",
  "attachmentShouldBePngOrJpeg": "添付ファイルはPNGまたはJPEGである必要があります",
  "atLeast7CalendarDaysBeforeTheStartOfTheEvent": "イベント開始の少なくとも7暦日前",
  "atleastOneLowerCaseLetter": "少なくとも1つの小文字",
  "atleastOneNumber": "少なくとも1つの番号",
  "atleastOneSpecialSymbol": "少なくとも1つの特別なシンボル",
  "atleastOneUpperCaseLetter": "少なくとも1つの大文字",
  "attemptLimitExceededPleaseTryAfterSomeTime": "試行回数が上限を超えました。しばらく後に再度お試しください",
  "attemptLimitExceededPleaseTryagainlater": "試行回数が上限を超えました。しばらく後に再度お試しくください",
  "attending": "出席する",
  "attendingEvents": "イベントへの参加",
  "audio": "オーディオ",
  "audioEvent": "オーディオイベント",
  "audioEventDurationMax": "最大オーディオイベント期間",
  "autoDelete": "自動削除",
  "autoDeleteOnMinimumLimitNotReached": "最小ユーザー制限に達していない場合",
  "autoDeleteTurnedOff": "自動削除がオフになりました",
  "autoDeleteTurnedOn": "自動削除がオンになっている",
  "audioMeetingPermissionError": "ミーティングを続行するには、マイクへのアクセスを許可してください",
  "audioSetting": "音声設定",
  "avatarImageUpdatedSuccessfully": "アバター画像が正常に更新されました",
  "anonymous": "匿名",
  "anonymousProfile": "匿名プロフィール",
  "anonymousProfileUpdatedSuccessfully": "匿名プロフィールが正常に更新されました",
  "awaitingCancellation":"キャンセル待ち",
  "accountNameIsRequired": "アカウント名が必要です",
  "accountNumberIsRequired": "アカウント番号が必要です",
  "accountNameShouldContainOnlyLetters": "アカウント名には英字のみ使用できます",
  "accountNumberShouldContainOnlyNumericCharacters": "アカウント番号には数字のみ使用できます",
  "businessNameIsRequired": "商号が必要です",
  "beneficiaryNameIsRequired": "受取人名が必要です",
  "beneficiaryNameShouldContainOnlyLetters": "受取人名には英字のみ使用できます",
  "back": "バック",
  "backToMeetingDetailsScreen": "会議の詳細画面に戻る",
  "basedEvent": "ベースのイベント",
  "before": "前",
  "beeMG,AVideoCommunication": "BeeMG、ビデオコミュニケーション",
  "beneficiaryName": "受取人名",
  "between":"間",
  "balanceTotal": "残高合計",
  "blankSpacesAreNotAllowed": "空白は使用できません",
  "block": "ブロック",
  "blockUser": "ユーザをブロックする",
  "bluetooth":"ブルートゥース",
  "businessName": "商号",
  "bookYourSlot":"スロットを予約する",
  "by": "による",
  "codeIsRequired": "IFSC コードが必要です",
  "camera": "カメラ",
  "cameraOn": "カメラオン",
  "cameraOff": "カメラオフ",
  "cardNumber": "カード番号",
  "canBeAdded": "追加できます",
  "cancel": "キャンセル",
  "cancelFreeEvent": "無料イベントをキャンセルする",
  "cancelInvite": "招待をキャンセルする",
  "cancellation": "キャンセル",
  "cancellationTerms": "キャンセル条件",
  "cancellationBetween": "間のキャンセル",
  "cancellationCharges": "キャンセル料",
  "cancellationPolicies": "キャンセルポリシー",
  "cancellationPolicy": "取り消し規約",
  "cancellationBefore12HoursWillBeCharged50PercentAndCancellationWithin12HoursWillBeCharged100Percent": "12時間前のキャンセルは50% 12時間以内のキャンセルは100%",
  "cancellationBefore24HoursTo3daysWillBeCharged50PercentAndCancellationWithin24HoursWillBeCharged100Percent": "24時間前～3日前のキャンセルは50% 24時間以内のキャンセルは100%",
  "cancellationPolicyChosen": "キャンセルポリシーが選択されました",
  "cancellationPolicyForThisEvent": "このイベントのキャンセルポリシー",
  "cancellationPolicyTerms": "キャンセルポリシー条件",
  "cancellationRequested": "キャンセルが要求されました",
  "cancellationRequestSentSuccessfully": "キャンセルリクエストが正常に送信されました",
  "cancellationProcessedSuccessfully": "キャンセル処理が正常に完了しました",
  "cancellationwithin": "キャンセル間",
  "cancellationIsNotApplicableForFreeAudioEvents": "キャンセルは無料のオーディオイベントには適用されません",
  "cancelled": "キャンセルされました",
  "cancelEvent":"イベントのキャンセル",
  "cancelledSuccessfully": '正常にキャンセルされました',
  "cancelRequest": "リクエストのキャンセル",
  "catchPhrase": "キャッチフレーズ",
  "catchPhraseMustBeAtMost150Characters": "キャッチフレーズは最大150文字である必要があります",
  "categories": "カテゴリー",
  "categoriesYouAreInterestedIn": "興味のあるカテゴリー",
  "categoryNameShouldBeMax50Characters": "カテゴリー名は最大50文字である必要があります",
  "category": "カテゴリー",
  "upto": "まで",
  "ofProcessingFeeWillBeApplicableUponCancellation": "キャンセルにより、処理費用が適用されます",
  "refundRequestCreatedSuccessfully": "返金依頼が正常に作成されました",
  "refundRequestCreationFailed": "返金依頼の作成に失敗しました",
  "elaborateYourRefundReason": "返金の理由を説明してください",
  "requestaRefund": "返金依頼",
  "requestRaised": "リクエストが発生しました",
  "requestInProgress": "リクエストが進行中です",
  "requestClosed": "リクエストが閉鎖されました",
  "refundReasonCanBeMax2000CharactersLong": "返金理由最大 2000 文字まで可能",
  "chat": "チャット",
  "change": "変更",
  "changeAllowOtherUsersToMessageYouInSettingsToChatWithOthers": "他のユーザーとチャットするには、設定で [他のユーザーがあなたにメッセージを送信できるようにする] を変更します。",
  "changePhoneNumber": "電話番号を変更する",
  "chargeable": "有料",
  "charactersLong": "長文字",
  "chargeWillBeDeductedFromRefund": "料金は返金から差し引かれます",
  "chatVideoRequest": "チャットビデオリクエスト",
  "CVV": "CVV",
  "checkoutHostProfile": "チェックアウトホストプロファイル",
  "checkYourTransactionDetails": "取引の詳細を確認する",
  "chef": "シェフ",
  "choose": "選ぶ",
  "chooseAValidLanguage": "有効な言語を選択してください",
  "chooseAudioInput": "オーディオ入力を選択",
  "chooseAudioOutput": "オーディオ出力を選択",
  "chooseEventPhoto": "イベント写真を選択",
  "chooseCategory": "カテゴリを選択",
  "chooseYourInterests": "興味のあるカテゴリーを選択",
  "chooseYourInterestsFromTheListGivenBelow": "以下のリストからあなたの興味を選択してください",
  "chooseYourAvatar": "あなたのアバターを選択",
  "city": "市",
  "city/town": "市/町",
  "clear": "クリア",
  "clearAllFilters": "すべてのフィルターをクリアする",
  "clickHereToActivate": "アクティベートするには、ここをクリックしてください",
  "clickHereToFindPeople": "人を見つけるにはここをクリックしてください",
  "clickHereToPayForRequestedMeeting": "要求された会議の支払いをするには、ここをクリックしてください",
  "clickHereToSelectYourInterests": "あなたの興味を選択するには、ここをクリックしてください",
  "clickHereToVerify": "確認するには、ここをクリックしてください",
  "clickHereToViewYourRequests": "リクエストを表示するには、ここをクリックしてください",
  "clickHereToSendYourRequests": "ここをクリックしてリクエストを送信",
  "clickUploadIconToSave": "アップロードアイコンをクリックして保存します",
  "close": "終了する",
  "codeSentSuccessfully": "コードが正常に送信されました",
  "codeSentTo": "に送信されたコード",
  "co-Host": "共催",
  "coHostAddedSuccessfully": "共同ホストが正常に追加されました",
  "coHostDeletedSuccessfully": "共同ホストが正常に削除されました",
  "collaborate": "協力する",
  "collaborators": "コラボレーター",
  "comment": "コメント",
  "company": "会社",
  "companyLtd": "カンパニーリミテッド.",
  "Completed": "完了",
  "confirm": "確認",
  "confirmationStatus": "確認状況",
  "confirmPassword": "パスワードを確認する",
  "confirmPhoneNumber": "電話番号を確認する",
  "confirmThatYourPhoneNumberIs": "電話番号を確認する",
  "continuePayment": "支払いを続ける",
  "connect": "接続する",
  "connectionIssue": "接続の問題",
  "connecting": "接続する",
  "connectingPeople": "人をつなぐ",
  "connectThroughVideo": "ビデオでつながる",
  "connectWithPeople": "人とつながる",
  "connectingWithPeople": "人とつながる",
  "connectWithRazorpay": "Razorpayとつながる",
  "connectWithStripe": "Stripeと接続する",
  "contact": "連絡先",
  "contactInformation": "連絡先情報",
  "contactUs": "お問い合わせ",
  "copyURL": "URLをコピー",
  "cost": "費用",
  "costMustBeAPositiveNumber": "コストは正の数でなければなりません",
  "coverVideo": "ビデオを覆う",
  "country": "国",
  "countryCode": "国コード",
  "create": "作成する",
  "createNewSession":"新しいセッションを作成する",
  "createEvent": "イベントを作成する",
  "createConnectCollaborate": "作成します。接続します。コラボレーション",
  "createNewEvent": "新しいイベントを作成する",
  "createYourNewEvent": "新しいイベントを作成する",
  "createYourEvent": "イベントを作成する !",
  "createSessionsForYourEvent": "イベントのセッションを作成する",
  "createSessions":"セッションの作成",
  "forYourEvent":"あなたのイベント",
  "creators": "クリエイター",
  "credited": "クレジット",
  "crop": "作物",
  "cropAndSave": "切り抜いて保存する",
  'credit': 'クレジット',
  "currencyIsNotSupported": "通貨はサポートされていません",
  "currencyPreference": "通貨設定",
  "customSearch": "カスタム検索",
  "date": "日付",
  "dateAndTime": "日時",
  "dateAndTimeShouldNotEmpty": "日付と時刻は空にしないでください",
  "dateOfBirth": "生年月日",
  'debit': '借方',
  "debited": "借方記入",
  "decline": "辞退する",
  "declined": "断った",
  "delete": "削除する",
  "deleteVideo": "ビデオを削除する",
  "deletedSuccessfully": "正常に削除されました",
  "deleteEvent": "イベントの削除",
  "deleteOnMinimumLimit": "最小制限で削除",
  "deleteRequest": "リクエストの削除",
  "describeYourPurposeOfTheRequest": "リクエストの目的を説明してください",
  "description": "説明",
  "details": "細部",
  "detailsEntered": "入力された詳細",
  "done": "終わり",
  "doYouWantToEndTheActiveSession?": "アクティブなセッションを終了しますか ?",
  "doYouWantToEndThisSession?": "このセッションを終了しますか ？",
  "doYouWantToLeaveAndEndMeeting?": "会議を終了して終了しますか ？",
  "doYouWantToDeleteTheMeeting": "この会議を削除しますか？",
  "doYouWantToDeleteThis": "これを削除しますか",
  "duration": "期間",
  "eachUser": "各ユーザー",
  "earningStatistic": "収益統計",
  "earpiece": 'イヤホン',
  "edit": "編集",
  "editEvent": "イベントの編集",
  "editSession": "セッションの編集",
  "editRequest": "リクエストの編集",
  "elaborateYourSubject": "主題を詳しく説明する",
  "email": "Eメール",
  "emailAddressesAreNotAllowed": "メールアドレスは許可されていません",
  "emailAlreadyInvited": "メールはすでに招待されています",
  "emailID": "電子メールID",
  "email/Name": "メールアドレス/名前",
  "email/Username": "メールアドレス/ユーザー名",
  "emailAndPhoneVerificationNotComplete": "メールと電話による確認が完了していません",
  "emailAndFaceVerificationNotCompletedForThisUser": "このユーザーのメールと顔の確認が完了していません",
  "emailPhoneAndFaceVerificationNotCompletedForThisUser": "このユーザーのメール、電話、および顔の確認が完了していません",
  "emailAndPhoneVerificationNotCompletedForThisUser": "このユーザーのメールアドレスと電話番号の確認が完了していません",
  "emailAlreadyRegistered": "すでに登録された電子メール",
  "emailAlreadyVerified": "メールはすでに確認済み",
  "emailCanBeMax50CharactersLong": "メールの長さは最大50文字です",
  "emailOrPasswordIsIncorrect": "メールアドレスまたはパスワードが間違っています",
  "emailVerification": "メールによる確認",
  "emailVerificationMailSendSuccessfully": "メール確認メールの送信に成功",
  "emailVerificationNotComplete": "メールの確認が完了していません",
  "emailVerificationNotCompletedForThisUser": "このゲストのメール確認は完了していません",
  "end": "終了",
  "endAt": "で終了",
  "endCall": "通話終了",
  "endMeeting": "会議を終了する",
  "endsIn": "で終わる-",
  "endTime": "終了時間",
  "endTimeIsLessThanStartTime": "終了時間が開始時間より短い",
  "endTimeShouldBeGreaterThanStartTime": "終了時間は開始時間より長い",
  "engageDirectlyWithExperiencedPeople": "経験豊富な人々と直接交流する",
  "enterAValidDate": "有効な日付を入力してください",
  "enterCode": "コードを入力する",
  "enterOtherCategorys": "他のカテゴリを入力してください",
  "enterCodeSentToYour": "あなたに送信するコードを入力してください",
  "enterCodeSentToYourEmail": "メールに送信されたコードを入力してください",
  "enterDate": "日付を入力",
  "enterEmails": "メールアドレスを入力してください",
  "enterEmailsToInvite":"招待するメールアドレスを入力してください",
  "enteredPasswordsAreDifferent": "入力したパスワードが異なります",
  "enteredRequestedUserEmailId": "要求されたユーザーの電子メールIDを入力しました",
  "enteredYourEmailId": "メールIDを入力しました",
  "enterAValidSelfIntro": "有効な自己紹介を入力してください",
  "enterAValidFacebookName": "有効なFacebook名を入力してください",
  "enterAValidInstagramName": "有効なInstagram名を入力してください",
  "enterAValidTwitterName": "有効なTwitter名を入力してください",
  "enterAValidLinkedInName": "有効なLinkedIn名を入力してください",
  "enterEmailID": "メールIDを入力",
  "enterEmailIDAbove": "上部にメールIDを入力してください",
  "enterEmailToSendInvitation": "メールを入力して招待状を送信",
  "enterEventTitle":"イベントタイトルを入力",
  "enterOTP": "OTPを入力してください",
  "enterOTPCode": "OTPコードを入力してください",
  "enterPassword": "パスワードを入力してください",
  "enterSomeOtherEmailIdToRegister": "登録する他のEメールIDを入力してください",
  "enterValidCountryCode": "有効な国コードを入力してください",
  "enterValidCountryCodeAndPhoneNo": "有効な国コードと電話番号を入力してください",
  "enterThe6DigitCodeFromYourMail": "メールに記載されている6桁のコードを入力してください",
  "enterThe6DigitCodeFromYourPhone": "携帯電話から6桁のコードを入力してください",
  "enterTheCardNumber": "カード番号を入力してください",
  "enterTheNumber": "番号を入力してください",
  "enterValidDateOrAgeMustBe": "有効な日付または年齢を入力する必要があります",
  "enterValidEmailID": "有効なメールIDを入力してください",
  "enterYourCode": "コードを入力してください",
  "enterYourEmailToReceive": "受信するメールアドレスを入力してください",
  "enterTheOtpReceivedOnMobile": "モバイルで受信した OTP を入力してください",
  "errorTryingToUpload": "アップロードしようとしてエラーが発生しました",
  "errorOccured": "エラーが発生しました。後でもう一度お試しください",
  "evening": "夕方",
  "event": "イベント",
  "eventLanguage": "イベント言語",
  "gstinNumber": "GSTIN番号",
  "enterYourGSTINNumber": "GSTIN番号を入力してください",
  "eventDurationMaxMinutes": "イベント 最大継続時間 {{maxDuration}} 分",
  "eventCreatedSuccessfully": "イベントが正常に作成されました",
  "eventCreationAgreement": "イベント作成契約",
  "eventDate": "開催日", 
  "eventDeletedSuccessfully": "イベントが正常に削除されました",
  "eventDescription": "イベントの説明",
  "EventEndTimeIsLessThanTheStartTime": "イベント終了時間が開始時間より短い",
  "eventImageDeletedSuccessfully": "イベント画像が正常に削除されました",
  "eventImageUploadedSuccessfully": "イベント画像が正常にアップロードされました ",
  "eventDescriptionShouldBeMax2000CharactersLong": "イベントの説明は最大2000文字にする必要があります",
  "eventVideoUploadedSuccessfully": "イベントビデオが正常にアップロードされました",
  "eventVideoDeletedSuccessfully": "イベントビデオが正常に削除されました",
  "eventDetails": "イベントの詳細",
  "eventName": "イベント名",
  "eventOn": "〜のイベント",
  "eventPolicyAgreed": "合意されたイベントポリシー",
  "events": "イベント",
  "eventRequirements":"イベント要件",
  "eventReviews":"イベントレビュー",
  "eventTime": "イベント時間",
  "eventTitleShouldBeMax100Characters": "イベントタイトルは最大100文字である必要があります",
  "eventTitleShouldBeMin2Characters": "イベントタイトルは2文字以上にする必要があります",
  "eventType": "イベントタイプ",
  "eventUpdatedSuccessfully": "イベントが正常に更新されました",
  "expandYour": "あなたの〜拡大する",
  "expandYourHive": "作成-接続<br>コラボレーション",
  "expandYourSocialNetwork": "あなたのソーシャルネットワークを拡大する",
  "expectedTotalMeetingPrice": "予想される総会議費",
  "expired": "期限切れ",
  'expiryMonth': '有効期限月',
  "explore": "探検する",
  "faceToFace": "面と向かって",
  "facebookAccountName": "Facebookアカウント名",
  "failed": "失敗しました",
  "Failed": "失敗しました",
  "favorite": "お気に入り",
  "faceVerification": "顔認証",
  "faceVerificationNotCompletedForThisUser": "このユーザーの顔の検証が完了していません",
  "favoriteHosts": "お気に入りのホスト",
  "feedback": "フィードバック",
  "feedbacks": "フィードバック",
  "feedbackCanBeMax500CharactersLong": "フィードバックの長さは最大500文字です",
  "feedbackRegisteredSuccessfully": "フィードバックが正常に登録されました",
  "female": "女性",
  "festiveSeasonPlans": "ホリデーシーズンプラン",
  "filter": "フィルター",
  "fileName": "ファイル名",
  "fileType": "ファイルの種類",
  "findSomeoneToTextTo...": "テキストメッセージを送る相手を見つける",
  "firefoxMeetingError": "Firefox はサポートされていません。他のブラウザで開いてください",
  "firstName": "ファーストネーム",
  "firstNameCanBeMax20CharactersLong": "名の長さは最大20文字です",
  "findSomeoneToTextTo": "テキストメッセージを送る相手を見つけてください...",
  "flatAsPlatformFeeByDefault": "デフォルトでは平面プラットフォーム料金",
  "speaker": "スピーカー",
  "specificReasonsforCancellationIfAny": "キャンセルの具体的な理由がある場合",
  "specificReasonsForCancellation": "キャンセルの具体的な理由",
  "for": "ために",
  "forgotPassword": "パスワードをお忘れですか ?",
  "forThisEvent": "このイベントのために",
  "freeAudio": "無料オーディオ",
  "freeCancellation": "ユーザーはイベントの 72 時間前までにキャンセルできます。管理コストをカバーするために、5% の手数料が返金から差し引かれます。",
  "freeAudioEvent": "無料オーディオイベント",
  "freeCancellationBefore": "前の無料キャンセル",
  "freeEvent" : "無料イベント",
  "freeEvents": "無料イベント",
  "freeEventCancellation": "無料のイベントキャンセル",
  "freeMeetingRequest": "無料の会議依頼",
  "freePrice": "無料価格",
  "freeRequest": "無料リクエスト",
  "from": "から",
  "fromDate": "日付から",
  "fromTimeShouldBeLessThanToTime": "開始時間は終了時間より短くなければなりません",
  "fullRefund": "全額払い戻し",
  "fullFee":"全額",
  "fromYourRefundAmount": "返金金額から",
  "futureMeetings": "未来の会議",
  "gender": "性別",
  "getInTouch": "連絡する",
  "getYourEmailAndPhoneNumberVerifiedToAddMeeting": "メールアドレスと電話番号を検証確認後、会議を追加します",
  "getYourEmailAndPhoneNumberVerifiedToSendRequestForMeeting": "メールアドレスと電話番号を検証確認後、会議をリクエストします",
  "goBack": "戻る",
  "globalCreators": "グローバルクリエーター",
  "greaterThanOrEqualTo8Characters": "8文字以上",
  "groupchat": "グループチャット",
  "guest": "ゲスト",
  "guestList": "ゲストリスト",
  "guests": "ゲスト",
  "guestsHaveBeenInvited": "ゲストが招待されました",
  "guestsInvitedSuccessfully": "ゲストは正常に招待されました",
  "guestsWhomYouPaidToAttendTheEventUsingBeeMG": "BeeMGを使用してイベントに参加するために支払ったゲスト.",
  "handraise": "挙手",
  "hasRaisedTheirHand": "は挙手をしています",
  "haveAChat": "チャットをする",
  "headset":"ヘッドセット",
  "help": "ヘルプ",
  "heldOn": "開催日",
  "hi": "こんにちは",
  "hive": "棲家",
  "higherThanLastMonth": "先月より高い",
  "history":"歴史",
  "home": "ホーム",
  "host": "ホスト",
  "hostCancellationFee": "ホストキャンセル料",
  "hostTimeZone": "ホストタイムゾーン",
  "hostAnonymously": "匿名でホストする",
  "hostHaveChosen": "ホストが選択しました",
  "hostedBy": "〜が主催する",
  "hostIntroduction": "ホストの紹介",
  "hostGrantedPermissionToTalk": "ホストが権限を与えることができます",
  "hostRemovedPermissionToTalk": "ホストが権限を剥奪することができます",
  "hour": "時間",
  "hours": "時間",
  "hoursRemaining":"残り時間",
  "IFSCCode": "IFSC コード",
  "IFSC": "IFSC",
  "IFSCCodeShouldBeValid" :"IFSCコードは有効である必要があります",
  "IfYouAgreeToTheAbove,PleaseClickNext":"上記に同意する場合は、「次へ」をクリックしてください",
  "IfYouAgreeToThisCancellationPolicyTermsAndConditions,YouCanGoThroughTheRequest": "このキャンセルポリシーの利用規約に同意する場合は、リクエストに応じることができます",
  "ifYouCancelBetween": "間でキャンセルした場合",
  "ifYouCancelThisMeeting": "この会議をキャンセルした場合",
  "ifYouCancelThisMeetingBefore": "以前にこの会議をキャンセルした場合",
  "ifYouCancelWith":"でキャンセルした場合",
  "ifYouCancel":"キャンセルする場合",
  "ifYouAgreeToTheAbove,PleaseTapSend": "上記内容に同意される場合は「送信」をタップしてください",
  "ifYouAgreeToTheAbove,PleaseTapNext": "上記内容に同意する場合は「次へ」をタップしてください",
  "iHaveReadAndAgreedToThe": "私は読み、同意しました",
  "identityVerified": "本人確認済み",
  "imageSizeLimitIs": "画像サイズの制限は",
  "imagesOrderSavedSuccessfully": "画像の順序が正常に保存されました",
  "imageFormatsOf.PNGAnd.jpegOnlySupported": ".PNGおよび.jpeg画像形式のみがサポートされています",
  "inProgress": "進行中",
  "instagramAccountName": "Instagramアカウント名",
  "instructions": "説明書",
  "interest": "興味",
  "interested": "興味がある",
  'inconvenienceFee': "不便料金",
  "interestedEvents": "興味のあるイベント",
  "interests": "興味",
  "interestsAlreadyExits": "興味はすでに存在します",
  "internet": "インターネット",
  "internetAccess–BeeMGUsesVideoCommunication": "インターネットアクセス– BeeMGはビデオ通信を使用して人々を接続します。スマートフォン、タブレット、またはコンピューターでビデオおよびオーディオ機能が有効になっているブロードバンドインターネット接続が必要です。",
  "introductionCanBeMax1500CharactersLong": "イントロダクションの長さは最大1500文字です",
  "InvalidCharacters": "無効な文字",
  "invalidEmail": "無効なメール",
  "invalidInput": "無効入力",
  "invalidPhoneNumber": "無効な電話番号",
  "invalidTime": "無効な時間",
  "invalidTimings": "無効なタイミング",
  "invalidToken": "無効なトークン",
  "invitationsLeft": "招待状が残っています",
  "invitations": "招待状",
  "invite": "招待する",
  "invited": "招待された",
  "inviteCountExceeded": "招待数を超えました",
  "invitedBeemgGuests": "BeeMG ゲストの招待",
  "invitedGuests":"招待ゲスト",
  "inviteFrom": "招待する",
  "inviteGuests":"ゲストを招待する",
  "invitedForEvent": "イベントに招待されました",
  "invitedOn": "招待された",
  "invitedSuccessfully": "正常に招待されました",
  "invitedYouTo": "にあなたを招待しました",
  "inviteEmail must be an email": "招待メールはメールである必要があります",
  "yourInvitees": "招待者",
  "inviteNewGuest": "新しいゲストを招待する",
  "invitePeople": "人を招待する",
  "inviteYourFriendsForThisEvent": "このイベントに友達を招待する",
  "inviteYourFriends": "友達を招待",
  "inviteRemaining": "招待する",
  "ipAddress": "IPアドレス",
  "iPadMeetingError": "BeeMGモバイルアプリをご利用ください",
  "isApplied":"適用される",
  "isDeducted": "差し引かれます",
  "isAskingToSpeak": "話すことを求めている",
  "isFreeAudioMeeting": "無料の音声会議です",
  "join": "参加する",
  "joinAnonymous": "匿名ユーザーとして参加しますか",
  "joined": "参加しました",
  "Joined": "参加しました",
  "joinTheMeeting": "会議に参加する",
  "joinUsingYourComputer/Tablet/Smartphone": "コンピューター/タブレット/スマートフォンを使用して参加する",
  "joinZoom": "会議に参加する",
  "justNow": "ちょうど今",
  "kidFriendly": "子供に優しい",
  "kidsEvents": "キッズイベント",
  "kidsOnly": "子供のみ",
  "kindlyGiveYourFeedback": "フィードバックをお寄せください",
  "language": "言語",
  "lastName": "苗字",
  "lastNameCanBeMax20CharactersLong": "姓は最大20文字です",
  "latestEvents": "最新のイベント",
  "latestUpdatedEvent": "最新の更新されたイベント",
  "launchNotification": "起動通知",
  "leave": "離れる",
  "lessThan":"未満",
  "lessThanOrEqualTo25Characters": "25文字以下",
  "limit": "制限",
  "limitedTo": "限定",
  "limitAlert": "制限アラート",
  "limitReached": "制限に達しました",
  "littlescape": "リトルスケープ",
  "linksAreNotAllowed": "リンクは許可されていません",
  "linkedInAccountName": "LinkedInアカウント名",
  "liveEvents": "ライブイベント",
  "login": "ログインする",
  "loginSuccessful": "ログイン成功",
  "makeThisEventAsAudioOnly": "このイベントを音声のみとして作成する",
  "male": "男性",
  "match": "一致",
  "maximum": "最大",
  "maximum1VideoOfLessThan": "未満の最大1本のビデオ",
  "maximum5ImagesOfLessThan": "未満の最大5枚の画像",
  "maximumUsersIs": "最大ユーザー数は {{maxParticipants}}",
  "maximumPrice": "最高価格",
  "maximumLimitForRequestIs": "リクエストの上限は",
  "mb": "メガバイト",
  "maybe": "多分",
  "meet": "会う",
  "meeting": "会議",
  "meetingCompleted": "会議が完了しました",
  "meetingOngoingDisconnectToJoin": "会議が進行中です。参加するには接続を解除してください",
  "meetingDate": "打ち合わせ日",
  "meetingDeleted": "会議が削除されました",
  "meetingDeletedSuccessfully": "会議は正常に削除されました",
  "meetingDetails": "会議の詳細",
  "meetingDuration": "ミーティング時間",
  "meetingDurationForAudioEventIs": "オーディオイベントの会議時間は",
  "meetingDurationForVideoEventIs": "ビデオイベントの会議時間は",
  "meetingEnded": "会議が終了しました",
  "meetingHasBeenCancelledBy": "ミーティングはキャンセルされました",
  "meetingHostedBy": "主催の会議",
  "meetingID": "ミーティングID",
  "meetingList": "ミーティングリスト",
  "meetingNotificationRemainder": "会議通知の残り",
  "meetingOver": "ミーティングオーバー",
  "meetingRequest": "会議出席依頼",
  "meetingRequestReceivedFrom": "から受け取った会議出席依頼",
  "meetings": "ミーティング",
  "meetingScheduledSuccessfully": "会議は正常にスケジュールされました",
  "meetingRequestSentTo": "に送信された会議出席依頼",
  "meetingsRemaining":"残りの会議",
  "meetingStartsAt": "会議は〜から始まります",
  "meetingUpdatedSuccessfully": "会議が正常に更新されました",
  "meetingURL": "会議のURL",
  "meetingWillEndIn": "会議は〜で終了します",
  "meetingWith": "との出会い",
  "message": "メッセージ",
  "messages": "メッセージ",
  "messageCanBeMax": "メッセージは最大にすることができます",
  "mic": "マイク",
  "microphoneOn": "マイクオン",
  "microphoneOff": "マイクオフ",
  "midnight": "真夜中",
  "minimum": "最小",
  "minimumAmount": "最小額",
  "minimumLimitSet": "最小制限セット",
  "minimumNoOfUsers": "最小ユーザー数",
  "userShouldBeMoreThanTheGivenCount": "ユーザーは指定された数を超える必要があります{{minParticipants}}",
  "minimumOfUsersMustBeAtleast1": "最小ユーザー数は少なくとも 1 人である必要があります",
  "minimumUsersLimitNotReached": "最小ユーザー制限に達していません",
  "minimumPrice": "最低価格",
  "maximumPriceIs": "最高価格は {{maxPrice}}",
  "maximumPriceNeedToBeGreaterThanMinimumPrice": "最高価格は最低価格より高くする必要があります",
  "minimumPriceShouldBeGreaterThanZero": "最低価格はゼロより大きくする必要があります",
  "maximumPriceShouldBeGreaterThanZero": "最高価格はゼロより大きくする必要があります",
  "minimumPriceShouldBeLessThanMaxPrice": "最低価格は最高価格より小さくする必要があります",
  "maximumPriceCanBe": "最高価格は{{maxPrice}}",
  "min": "分",
  "minute": "分",
  "minutes": "議事録",
  "mobileNumbersAreNotAllowed": "携帯電話番号は許可されていません",
  "month": "月",
  "more": "もっと",
  "moreThanTotalUsers": "総ユーザー数を超える",
  "moreInvitationsLeft": "残りの招待状",
  "morning": "朝",
  "multiSession": "マルチセッション",
  "multipleSessionVideoMeeting": "複数のセッションが原因でセッションが終了しました",
  "musician": "音楽家",
  "mute": "ミュート",
  "muteAll": "すべてミュート",
  "myEvents": "私のイベント",
  "mySettings": "私の設定",
  "myProfile": "私のプロフィール",
  "name": "名前",
  "needOfRequest": "リクエストの必要性",
  "needOfRequestCanBeMax150CharactersLong": "リクエストの必要性は最大150文字の長さにすることができます",
  "netTotal": "合計額",
  "network": "通信網",
  "new": "新着",
  "newArrival": "新しい仲間",
  "newSchedule": "新しいスケジュール",
  "next": "次",
  "nextEventStartsIn": "次のイベント",
  "nextScheduledDateOn": "次の予定日",
  "next10Days": "次の10日間",
  "nickName": "ニックネーム",
  "nickNameCanBeMax25CharactersLong": "ニックネームの長さは最大25文字です",
  "no": "ナンバー",
  "noCancellationCharge": "キャンセル料なし",
  "noDebitTransactions": "デビット取引なし",
  "noCreditTransactions": "信用取引はありません",
  "noEvents": "イベントなし",
  "noEventsToDisplay": "表示するイベントはありません",
  "notAMember": "会員ではありません",
  "noTransactionFound": "トランザクションが見つかりません",
  "noFeedbacks": "フィードバックはありません",
  "noFiltersToApply": "適用するフィルターはありません",
  "noMeetingUsersToDisplay": "表示する会議ユーザーがありません",
  "noMeetings": "会議はありません",
  "Non-registeredUsersShouldCheckMailAndThenRegisterInBeeMGAtTheEarliestBeforeTheEventBegins.": "未登録の方はイベント開始前までにメールを確認の上、BeeMGに登録してください。",
  "noOfUsers": "ユーザー数",  
  "noOfUsersMustBeAtleast1": "ユーザーの数は少なくとも 1 人である必要があります",
  "noOfUsersMustBeANumber": "ユーザー数は数値である必要があります",
  "noMoreInvitationsLeft": "招待状はもうありません",
  "noon": "正午",
  "noUsers": "ユーザーがいません",
  "noRecords": "記録なし",
  "noRefund": "返金なし",
  "noReviewsForTheEvent": "イベントのレビューはありません",
  "noReviewsForTheMeeting": "会議のレビューはありません",
  "noSessions": "セッションなし",
  "noschedules": "利用可能なスケジュールはありません！",
  "notAccepted": "受け入れられません",
  "notification": "通知",
  "notify": "通知する",
  "notifyThem": "通知する",
  "notifyMeWhenItsUpAndRunning": "稼働したら通知してください",
  "notJoined": "参加していません",
  "noTransactions": "トランザクションなし",
  "notRegistered": "未登録",
  "notRegisteredUser": "登録されていないユーザー",
  "noUpcomingEvents": "今後のイベントはありません",
  "numberOfFreeMeetingsRemaining": "残りの無料会議の数",
  "numberOfUsers": "ユーザー数",
  "noResultsFoundForTheName": "名前に一致する結果は見つかりませんでした。",
  "notSupportedFileFormat": "サポートされていないファイル形式",
  "ok": "わかった",
  "ofAMultitudeOfASelectedCategory": "多数の選択されたカテゴリ",
  "offline": "オフライン",
  "ofTheEventwillNotBeRefunded": "イベントには返金されません",
  "ofTheTotalFee":"合計料金のうち",
  "on": "オン",
  "oneToOneMeeting": "ワンツーワンミーティング / 面会希望",
  "onGoingMeeting": "ミーティングに行く",
  "online": "オンライン",
  "option": "オプション",
  "options": "オプション",
  "orAbove": "以上",
  "others": "その他",
  "orIfYouDoWantToAddThenClick" : "または、追加したい場合は、「次へ」をクリックします。",
  "otpResentSuccessfully": "OTPは正常に再送されました",
  "otpSentSuccessfully":"OTP が正常に送信されました",
  "paidAudio": "有料オーディオ",
  "paidVideo": "有料動画",
  "paid": "支払済",
  "paidOn": "支払われた",
  "paidBy": "によって支払われました",
  "paidFor": "のために支払われた",
  "paidRequest": "有料リクエスト",
  "participantsMeeting": "参加者ミーティング",
  "participantMeeting" : "参加者ミーティング",
  "userConfirmation": "ユーザーの確認",
  "usersAreThere": "ユーザーはそこにいる",
  "userIsThere": "ユーザーがそこにいる",
  "usersMustBeAnInteger": "ユーザーは整数である必要があります",
  "participants": "参加者",
  "password": "パスワード",
  "passwordHasBeenReset": "パスワードがリセットされました",
  "passwordDoesnotMatch": "パスワードが一致しません",
  "password must be at least 8 characters": "パスワードは8文字以上でなければなりません",
  "password must contain at least 1 uppercase letter": "パスワードには少なくとも1つの大文字が含まれている必要があります",
  "password must contain at least 1 lowercase letter": "パスワードには少なくとも1つの小文字が含まれている必要があります",
  "password must contain at least 1 symbol": "パスワードには少なくとも1つの記号が含まれている必要があります",
  "password must contain at least 1 number": "パスワードには少なくとも1つの数字が含まれている必要があります",
  "pastEvents": "過去のイベント",
  "pastSchedules": "過去のスケジュール",
  "pay": "支払い",
  "payAmount": "金額を支払う",
  "paymentDetails": "支払詳細",
  "paymentMethodIsNotActiveForThisUser": "このユーザーの支払い方法はアクティブではありません",
  "paymentSuccessful": "支払い完了",
  "payNow": "今支払う",
  "payNowForRequestedMeeting": "リクエストされた会議の料金を今すぐ支払う",
  "pending": "保留中",
  "userShouldBeMoreThanGivenCount": "ユーザーは与えられた数より多くなければなりません",
  "pleaseTryADifferentSearchTerm":"別の検索語を試してください。",
  "people": "人々",
  "peopleLikeYou": "あなたのような人",
  "peopleNearYou": "あなたの近くにいる人",
  "peopleYouMetRecently": "最近出会った人",
  "person": "人",
  "phone": "電話",
  "phoneNo": "電話番号",
  "phoneNumberVerification": "電話番号の確認",
  "phoneNumberMustBeAValidPhoneNumber": "電話番号は有効な電話番号である必要があります",
  "phoneVerificationNotComplete": "電話による確認が完了していません",
  "phoneAndFaceVerificationNotCompletedForThisUser": "このユーザーの電話と顔の確認が完了していません",
  "phoneVerificationNotCompletedForThisUser": "このユーザーの電話認証は完了していません",
  "pin": "ピン",
  "plannedTime": "予定時間",
  "platformFee": "プラットフォーム料金",
  "platformFee+stripeFee": "プラットフォーム料金+ストライプ料金",
  "pleaseCheckYourEmailToVerify": "メールをチェックして確認してください",
  "pleaseChooseYourInterestsToFindPeopleLikeYou": "あなたと同じような興味を持つ人を見つけるためにあなたの興味を選択してください",
  "pleaseConfirmRequestDetails": "リクエスト内容をご確認ください",
  "pleaseConfirmYourPassword": "パスワードを確認してください",
  "pleaseEnterEmailsAndPressEnter": "メールアドレスを入力して Enter を押してください",
  "pleaseGiveCorrectPrice": "正しい費用を教えてください",
  "pleaseSelectOneCategory": "カテゴリを1つ選択してください",
  "pleaseUpload5ImagesAndaVideo": "5枚の画像と1本のビデオをアップロードしてください",
  "pleasetateTheReason": "理由を述べてください",
  "pleaseVerifyYourAccount": "アカウントを確認してください",
  "PleaseCheckWithYourBank": "***** 利用規約が適用されます *****\n\n最新の換算レートについては、銀行にお問い合わせください。 ウェブサイトに表示されている料金はデモンストレーションを目的としたものです。 BeeMG は、換算レートの変更に対して一切の責任を負いません。",
  "pleaseTryAgainLater": "後でもう一度やり直してください",
  "pointsToBeNoted": "注意点",
  "popularGroupEvents": "人気のグループイベント",
  "popularInYourCountry": "あなたの国で人気",
  "popularity": "人気",
  "post": "役職",
  "purpose": "目的",
  "purposeForYourprivateSession": "個人セッションの目的",
  "preference": "好み",
  "priceMustBeAnInt": "価格はintでなければなりません",
  "price": "価格",
  "print": "印刷",
  "priceMustBeGreaterThan": "価格は以上である必要があります {{minPrice}}",
  "priorThingsNeeded": "以前必要なもの",
  "privateChat": "プライベートチャット",
  "privateSessionRequest": "個人セッションお申し込み",
  "theminimumAmountCanBeOf": "最低金額は{{minPrice}} {{currency}} である必要があります",
  "pricing": "価格設定",
  "privacyPolicy": "個人情報保護方針",
  "proceed": "続行します",
  "profile": "プロフィール",
  "profileInformation": "プロフィール情報",
  "profileImage": "プロフィール画像",
  "profileImageDeletedSuccessfully": "プロフィール画像が正常に削除されました",
  "profileImageUploadedSuccessfully": "プロフィール画像が正常にアップロードされました",
  "profileUpdatedSuccessfully": "プロファイルが正常に更新されました",
  "queueLength": "列の長さ",
  "rating": "評価",
  "raiseYourHandAndWaitForTheHostToUnmuteYou": "ホストがあなたをミュート解除するまであなたの腕を上げてください",
  "razorpayAccountDetails": "Razorpayアカウントの詳細",
  "Processing": "処理中",
  "processingFee": "処理料金",
  "razorpayProcessingFee": "Razorpay処理料金",
  "razorpayAccountIsActive": "Razorpayアカウントがアクティブです",
  "reachedForToday": "今日に達した",
  "readLess": "少なく読む",
  "readMore": "続きを読む",
  "reason": "理由",
  "raisedTheirHand": "手を挙げた",
  "reasonCanBeMax500CharactersLong": "理由は最大500文字の長さにすることができます",
  "feedbackReplyCanBeMax500CharactersLong": "フィードバックレポートは最大500文字の長さにすることができます",
  "received": "受け取りました",
  "receivedFrom": "から受け取りました",
  "receivedOn": "受信日",
  "receivedRequests": "受け取った",
  "recommendedToAdd5Images": "5つの画像を追加することをお勧めします",
  "redirecting": "リダイレクト中",
  "reEnterPassword": "パスワード再入力",
  "regarding": "に関して",
  "regd.user": "ユーザーを登録する",
  "register": "登録",
  "registerFor": "登録します",
  "registered": "登録済み",
  "registration": "登録",
  "registerForInvite": "招待に登録する",
  "registerForPublicLaunch": "一般公開に登録する",
  "registerUser": "登録ユーザー",
  "registeredSuccessfully": "正常に登録",
  "registeredUsersShouldCheck’AttendingEvents’ToAcceptTheInvitation" : "登録ユーザーは、招待を受け入れるために「イベントへの参加」をチェックする必要があります",
  "remove": "削除する",
  "reject": "拒否する",
  "rejected": "拒否されました",
  "rejectRequest": "リクエストを拒否する",
  "rejoin": "再参加",
  "replied": "返答済",
  "repliedSuccessfully": "正常に返答",
  "reply": "返事",
  "replyShouldBeMax800Characters": "返信は最大800文字である必要があります",
  "reportAbuse": "虐待を報告する",
  "reportUser": "レポートユーザー",
  "reportedSuccessfully": "正常に報告されました",
  "request": "リクエスト",
  "requestARefundFor": "払い戻しをリクエストする",
  "requestFor":"の要求",
  "requestCancellation": "キャンセルをリクエストする",
  "requestCancelledSuccessfully":"リクエストは正常にキャンセルされました",
  "requestedSession": "リクエストされたセッション",
  "requestConfirmation": "確認をリクエストする",
  "requestAcceptedSuccessfully": "リクエストは正常に受け入れられました",
  "requestDeclinedSuccessfully": "リクエストは正常に拒否されました",
  "requestDeletedSuccessfully": "リクエストは正常に削除されました",
  "requestPrivateSession": "プライベートセッションをリクエストする",
  "requestForMeetingConfirmation": "会議確認のリクエスト",
  "requestForPartialCancellation": "部分キャンセルのリクエスト",
  "requestRejectedSuccessfully": "リクエストは正常に拒否されました",
  "requestedBy": "に要求された",
  "requestedDateAndTime": "要求された日時",
  "requestedForAMeeting": "リクエストされた会議",
  "requestedOn": "リクエスト済み",
  "requesterCanPay": "リクエスターは支払うことができます",
  "requestForCancellation": "キャンセルのリクエスト",
  "requestedForCancellation": "キャンセルをリクエスト",
  "requestForMeeting": "会議のリクエスト",
  "requestPartialCancellation": "部分的なキャンセルをリクエストする",
  "requestReceivedFrom": "から受け取ったリクエスト",
  "requestRejected": "リクエストが拒否されました",
  "requests": "リクエスト",
  "requestSeen": "リクエストが表示されました",
  "requestSentSuccessfully": "リクエストは正常に送信されました",
  "requestSent": "リクエストが送信されました",
  "requestSentTo": "リクエストの送信先",
  "requestType": "リクエストの種類",
  "requestUpdatedSuccessfully": "リクエストが正常に更新されました",
  "privateSessionRequestAs(Anonymous)": "プライベート セッション リクエスト (匿名)",
  "required": "必須",
  "requirements":"必要条件",
  "resend": "再送信",
  "refundRequest": "再送信",
  "resendCode": "コードを再送する",
  "resendInvitation": "招待状を再送信",
  "resendInviteSentSuccessfully": "招待メールを正常に再送",
  "resendVerificationMail": "確認メールを再送する",
  "resendMail": "メールを再送信する",
  "reset": "リセット",
  "resetLinkHasBeenSentToYourEmail": "リセットリンクが登録メールに送信されました",
  "resetPassword": "パスワードを再設定する",
  "resetPasswordLinkWillBeSentToYourEmail": "パスワードのリセットリンクがメールに送信されます",
  "goHereAndEnterYourPreviousPasswordToResetThePassword": "ここに移動して、パスワードをリセットするには以前のパスワードを入力してください。",
  "reviewFor": "のレビュー",
  "reviews": "レビュー",
  "reviewShouldBeMax800Characters": "レビューは最大800文字である必要があります",
  "salesOrder": "販売注文",
  "save": "保存する",
  "saveAndUploadImagesOrVideo": "画像や動画を保存してアップロードする",
  "saveEventDetails": "イベントの詳細を保存する",
  "saveOrder": "注文を保存",
  "saveProfile": "プロフィールを保存",
  "sayHello": "こんにちは",
  "schedule": "スケジュール",
  "scheduledDateIsDeleted": "予定日を削除します",
  "scheduledDateisExpired": "予定日の期限が切れました",
  "scheduledEvents": "予定されているイベント",
  "scheduledMeetings": "スケジュールされた会議",
  "scheduledOn": "予定日",
  "scheduledTime": "予定時間",
  "scheduleSessions": "セッションのスケジュール",
  "screenShare": "画面共有",
  "screenShareOn": "画面共有オン",
  "screenShareOff": "画面共有オフ",
  "scrollToTop":"トップにスクロールします",
  "searchByCountry": "国で検索",
  "searchByName": "名前で検索",
  "searchByTitle": "タイトルで検索",
  "security": "セキュリティ",
  "select": "選択する",
  "selectAnAvailableMeetingOrRequestForANewOne": "利用可能な会議を選択するか、新しい会議をリクエストしてください",
  "selectAValidDate": "有効な日付を選択してください",
  "selectedDateisExpired": "選択された日付は期限切れです",
  "selectMultipleCategoryFromTheListandSubCategoryIfPresent": "リストから複数のカテゴリを選択し、存在する場合はサブカテゴリを選択します",
  "selectNameFromTheList": "リストから名前を選択します",
  "selectTheUsersForCancellation": "キャンセルするユーザーを選択します",
  "selectUsersForCancellation": "キャンセルするユーザーを選択してください",
  "self": "自己",
  "selfIntro": "自己紹介",
  "selfIntroduction": "自己紹介",
  "send": "送る",
  "sendOTP": "ワンタイムパスワードを送信する",
  "sendRequest": "リクエストを送信",
  "sendRequestRFM": "リクエストを送信",
  "sent": "送付済",
  "sentRequests": "送信済み",
  "series": "シリーズ",
  "sessionOptions": "セッションオプション",
  "setMaxPrice*": "最大価格を設定 *",
  "setMinPrice*": "最低価格を設定 *",
  "setRemainder": "リマインダーを設定する",
  "settings": "設定",
  "settingsUpdatedSuccessfully": "設定が正常に更新されました",
  "session": "セッション",
  "sessionRequests":"セッション要求",
  "sessions": "セッション",
  "scheduleMeetings": "会議のスケジュール",
  "share": "共有",
  "shareAbout": "について共有する",
  "shareEvent": "イベントを共有する",
  "shareProfile": "プロフィールを共有する",
  "shareHostProfile": "ホストプロファイルを共有する",
  "shouldBeGreaterThan18": "18より大きくする必要があります",
  "showLess": "表示を減らす",
  "showMore": "もっと見せる",
  "showUpOnOtherUsersOnlineList": "他のユーザーのオンライン リストに表示",
  "signIn": "サインインする",
  "signInWith": "でサインイン",
  "signInWithG": "Gのサインイン",
  "signOut": "サインアウト",
  "signUp": "サインアップ",
  "signUpWith": "に登録する",
  "siteUnderMaintenance": "サイトメンテナンス中",
  "size": "サイズ",
  "sizeEachCanBeAdded.": "サイズ追加可能.",
  "socialMedia": "ソーシャルメディア",
  "socialNetwork": "ソーシャルネットワーク",
  "socialNetworkThatProvides.....": "ソーシャルネットワークが提供する.....",
  "sorry...!HostHaveAnotherSessionAtTheTime": "ごめん...！ホストはその時に別のセッションを持っています",
  "soldOut": "完売",
  "start": "開始",
  "startTyping": "タイピング",
  "staged": "上演された",
  "startAt": "〜から開始",
  "started": "開始",
  "startMeeting": "会議を開始する",
  "startingInNext6Hours": "次の6時間で",
  "startingNextDay": "翌日から",
  "laterToday": "今日あとで",
  "startInviting": "招待を開始!",
  "startTime": "開始時間",
  "startTimeAndEndTimeCannotBeSame": "開始時間と終了時間を同じにすることはできません",
  "status": "状態",
  "stripAccountIsActive": "ストライプアカウントがアクティブです",
  "stripeNotSupportedInYourCountry": "お住まいの国ではストライプはサポートされていません",
  "stripeIsNotActiveForThisUser": "このユーザーのストライプはアクティブではありません",
  "stripeProccessingFee": "ストライプ処理手数料",
  "suitableForAgeGroupsFrom6-12": "6 - 12 歳の年齢層に適しています",
  "suitableForAgeGroupsFrom6-12&AdultsAsWell": "6 - 12 歳から大人までの年齢層に適しています",
  "success": "成功",
  "subject": "件名",
  "subjectCanBeMax100CharactersLong": "件名は最大100文字まで可能です",
  "submit": "提出する",
  "Submitted": "提出済み",
  "suggestions": "提案",
  "summary": "概要",
  "support": "サポート",
  "supportedImageFormats.JPG,.JPEG,.PNG,.BMP": "サポートされている画像形式：.JPG、.JPEG、.PNG、.BMP",
  "supportedVideoFormats.MP4": "サポートされているビデオ形式：.MP4",
  "somethingWentWrong": "エラーが発生しました",
  "switchAudioOutput": "オーディオ出力を切り替える",
  "terms": "条項",
  "termsAndConditions": "利用規約",
  "termsAndConditionsUpdatedSuccessfully": "利用規約が正常に更新されました",
  "termsAndConditionsAcceptedSuccessfully": "利用規約に同意しました",
  "thankYouForProvidingTheDetails.We’llNotifyYouAboutThePublicLaunchThroughTheRegisteredEmail": "詳細をご提供いただきありがとうございます。登録されたメールで一般公開についてお知らせします",
  "thatIsReachedForToday": "今日はそれが達成されます",
  "eventHostIsOpenToConnect": "イベント主催者は接続可能です",
  "theCreators": "作成者",
  "thingsNeeded/ToBeDoneInAdvance": "必要なこと、または事前に行う必要があること",
  "thingsToKnow": "知っておくべきこと",
  "ThisShouldNotBeaNumberAlone": "これは数字ではありません",
  "thisWeek": "今週",
  "thisMonth": "今月",
  "TheMinimumEntryAgeIs18": "最低入場年齢は18歳です",
  "time": "時間",
  "times": "タイムズ",
  "timeZone": "タイムゾーン",
  "title": "題名",
  "to": "に",
  "today": "今日",
  "toDate": "現在まで",
  "toHost": "ホストに",
  "tokenExpired": "トークンの有効期限が切れました",
  "topTrending": "トップトレンド",
  "toShareYourScreenKindlyRequestTheHostToUnmuteYou": "画面を共有するには、ホストにミュート解除を依頼してください。",
  "total": "合計",
  "totalAmountCredited": "クレジットされた合計金額",
  "totalAmountDeducted": "差し引かれる合計金額",
  "totalUsers": "総ユーザー数",
  "totalPrice": "合計金額",
  "totalCostOf": "総費用",
  "transactionDate": "取引日",
  "transactionDetails": "取引の詳細",
  "transactionDescription": "ションの詳細",
  "transactionHistory": "取引履歴",
  "transactionList": "取引一覧",
  "transactions": "取引",
  "transactionStatus": "トランザクションステータス",
  "twitterAccountName": "Twitterアカウント名",
  "type": "タイプ",
  "typeAMessage": "メッセージを入力してください",
  "typeEventTitle": "イベントタイトルを入力してください",
  "typeYourPreferredLanguage": "希望する言語を入力してください",
  "thanks": "ありがとう",
  "users": "ユーザー",
  "unitPrice": "単価",
  "unitPriceMustBeaPositiveNumber": "単価は正の数字でなければなりません",
  "unmute": "ミュートを解除する",
  "unmuteAll": "すべてのミュートを解除する",
  "unpin": "ピン留めを外す",
  "unBlock": "ブロックを解除",
  "unBlockTheUserToChat": "ユーザーのチャットのブロックを解除する",
  "unBlockTheUserToSendVideoRequest": "ユーザーのブロックを解除してビデオ リクエストを送信する",
  "unBlockTheUserToSendChatRequest": "ユーザーのブロックを解除してチャット リクエストを送信する",
  "under":"下",
  "upcoming": "今後の",
  "upcomingMeeting": "今後の会議",
  "updateAnonymousProfile": "匿名プロファイルを更新する",
  "updatedSuccessfully": "更新成功",
  "upload": "アップロード",
  "uploadImageAndVideo": "画像とビデオをアップロードする",
  "uploaded": "アップロードしました",
  "uploadEventImage": "イベント画像をアップロードする",
  "uploadEventVideo": "イベントビデオをアップロードする",
  "uploadVideo": "ビデオをアップロードする",
  "upcomingEvents": "今後のイベント",
  "userAlreadyInvited": "ユーザーはすでに招待されています",
  "User not found": "ユーザーが見つかりません",
  "userBlockedSuccessfully": "ユーザーをブロックしました",
  "user": "ユーザー",
  "userForRfm": "ユーザー",
  "usersForRfm": "ユーザー",
  "userId": "ユーザーID",
  "userList": "ユーザーリスト",
  "userProfile": "ユーザープロファイル",
  "upcomingSessions": "今後のセッション",
  "userShouldCheckAttendingEventsToAcceptTheRequest": "ユーザーは、イベントへの参加を確認してリクエストを受け入れる必要があります.",
  "userShouldHaveOnlyOneActiveRequest": "ユーザーはアクティブなリクエストを1つだけ持つ必要があります",
  "validFor": "に有効",
  "verification": "検証",
  "verificationCode": "検証コード",
  "verificationEmailResendSuccessfully": "確認メールが正常に再送信されます",
  "verificationLinkHasBeenSentToYourEmail": "確認リンクがメールに送信されました",
  "verificationNotComplete": "検証が完了していません",
  "verify": "確認",
  "verifyYourPhoneNumber": "電話番号を確認する",
  "verifyYourEmail": "あなたの電子メールを確認します",
  "verifyYourFace": "顔認証",
  "video": "ビデオ",
  "videoOn": "ビデオオン",
  "videoOff": "ビデオオフ",
  "yourIntro/ProfleVideoDeletedSuccessfully": "あなたのイントロ/プロフィール動画は正常に削除されました",
  "yourIntro/ProfleVideoUploadedSuccessfully": "あなたのイントロ/プロフィールビデオは正常にアップロードされました",
  "videoEvent": "ビデオイベント",
  "videoEventDurationMax": "ビデオイベントの最大継続時間",
  "videoFormatsOf.MPG,.GIF,.WMV,.VOB,.WebVTTAnd.AVIAreNotSupported": ".MPG、.GIF、.WMV、.VOB、.WebVTT、および.AVIビデオ形式はサポートされていません",
  "videoMeetConfirmDialogHeader": "確認してください",
  "videoMeetConfirmDialogLeaveMeeting": "会議を終了しますか？",
  "videoMeetHostEnds": "主催者が会議を終了しました",
  "videoMeetHostEndsHeader": "会議が終了しました！",
  "videoMeetingPermissionError": "ミーティングを続行するには、カメラとマイクへのアクセスを許可してください",
  "videoRequest": "ビデオリクエスト",
  "videoRequested": "動画リクエスト！",
  "videoRequestReceived": "受信したビデオリクエスト",
  "videoRequests": "ビデオリクエスト",
  "videoRequestSent": "ビデオリクエスト送信",
  "videoRequestAccepted": "ビデオリクエストを承認しました",
  "videoRequestDeclined": "ビデオリクエストを拒否しました",
  "videoRequestExpired": "ビデオリクエストは期限切れです",
  "videoSizeLimitIs": "ビデオサイズの制限は",
  "videoSizeShouldBeLessThan": "ビデオサイズは以下でなければなりません",
  "view": "見る",
  "viewEvent": "イベントの詳細を表示",
  "viewFeedbacks": "フィードバックを見る",
  "viewGuest": "ゲストを見る",
  "viewGuests": "ゲストを見る",
  "viewLess": "表示を減らす",
  "viewMore": "もっと見る",
  "viewMoreReviews": "レビューをもっと見る",
  "viewLessReviews": "レビューの表示を減らす",
  "viewUsers": "ユーザーの表示",
  "viewProfile": "プロフィールを見る",
  "viewMoreEvents": "イベントをもっと見る",
  "warning!SizeExceedsThan": "警告！サイズを超える",
  "warning": "警告",
  "weekendPlans": "週末プラン",
  "weValueYourFeedback,ThankyouForYourResponse!": "私たちはあなたのフィードバックを大切にします、あなたの応答に感謝します！",
  "whatIsItAbout": "どんな内容ですか",
  "whatYouNeedToPrepare?": "何を準備する必要がありますか？",
  "whatYouWillBeDoing?": "あなたは何をするつもりですか?",
  "whatTypeOfEventYouWantToHost?": "主催したいイベントの種類は？",
  "whyDoYouWantToReport": "なぜ報告したいのですか",
  "within24HoursOfPurchase": "購入後24時間以内",
  "willBeDeducted": "差し引かれます",
  "willBeProvidedAfter": "後に提供されます",
  "wontBeAttending": "参加しません",
  "writeYourConcern/Suggestion": "あなたの懸念/提案を書いてください",
  "wrongCodeEntered": "間違ったコードが入力されました。もう一度やり直してください。",
  "yes": "はい",
  "year": "年",
  "you": "君は",
  "YOU": "君は",
  "youDontHaveAnyMessages": "メッセージがありません",
  "youAlreadyHaveMeetingInThisTimeSlot": "このタイムスロットですでに会議があります",
  "youHaveAlreadySentYourRequestForThisHostAndItIsInProgress": "このユーザーへのリクエストはすでに送信されており、進行中です",
  "selectedTimeSlotIsExpired" : "選択されたタイムスロットが期限切れです",
  "youHaveAnActiveRequestAlready": " あなたはアクティブなリクエストを1つだけ持つ必要があります",
  "youHavePaidFor": "あなたは支払いをしました",
  "youCanHostBothPaidAndFreeEventsOnPaymentAccountActivation": "支払いアカウントのアクティブ化で、有料イベントと無料イベントの両方をホストできます",
  "youCannotInviteTheEventHost": "イベント主催者を招待することはできません",
  "youCanNowConnectThroughVideoWithThisUser": "このユーザーにビデオを接続できます",
  "youCannotSendMessagesToThisUser": "このユーザーにメッセージを送信することはできません",
  "youCanOnlyHostFreeEventsTryToConnectWithUSDForPaymentActivation": "無料のイベントのみを主催できます, USD との接続を試行支払いアクティベーション用 !",
  "YouCanSendANewRequestAfterTheEarlierRequestIsCancelled/Completed": "以前のリクエストがキャンセル/完了した後で、新しいリクエストを送信できます.",
  "youDon'tHaveAnyGuestsAttending": "出席するゲストはいません",
  "youHaveAlreadyChosenThisDate": "あなたはすでにこの日付を選択しています",
  "youHaveAlreadyChosenSameTimeSlot": "あなたはすでに同じタイムスロットを選択しています",
  "youHaveChosenPastFromTime": "過去の時間を選択しました",
  "youHaveChosenPastToTime": "過去の時間を選択しました",
  "youHaveChosenPreviousDate": "前の日付を選択しました",
  "youHaveEnteredSameEmailIdTwice": "同じメールIDを2回入力しました",
  "youHaveNoActiveEvents": "アクティブなイベントはありません",
  "youHaveNoActiveRequests": "アクティブなリクエストはありません",
  "youHaveNoPastEvents": "過去のイベントはありません",
  "youngnest": "最年少",
  "youShouldBeEitherAHostorCohostToStartWith": "ミーティングを開始するには、主催者または共同主催者のいずれかである必要があります",
  "your": "あなたの",
  "yourLocalCurrencyIsNotSupportedByStripe": "あなたの現地通貨は Stripe でサポートされていません",
  "yourOldPasswordAreInCorrect": "古いパスワードが正しくありません",
  "yourBrowserDoesNotSupportHTMLvideo.": "お使いのブラウザはHTMLビデオをサポートしていません。",
  "yourComment": "あなたのコメント",
  "yourEmailHasBeenVerified": "あなたの電子メールが確認されました",
  "yourEvents": "あなたのイベント",
  "yourIntro": "あなたの紹介",
  "yourIntroVideo": "あなたの紹介ビデオ",
  "yourInvitations": "あなたの招待状",
  "yourInvitedGuests": "招待客",
  "youHaveNone": "あなたは何も持っていません",
  "yourPaymentConfirmed": "お支払いが確認されました",
  "yourPaymentHasFailed.PleaseTryAgainLater.": "お支払いに失敗しました。後でもう一度やり直してください。",
  "yourPaymentHasFailed": "お支払いが失敗しました",
  "yourPaymentIsSuccessful": "お支払いが完了しました",
  "yourProfileWillBeAnonymousToOtherUsers": "あなたのプロフィールは他のユーザーに対して匿名になります",
  "yourRequestHasBeenCancelled": "リクエストはキャンセルされました",
  "YourTotalEarnings": "あなたの総収入",
  "youWillStartSeeingYourEventsAsSoonAsSomethingComesUp": "何かが起こるとすぐに、イベントが表示されるようになります。",
  "youMustHaveAtleastOneCategory": "少なくとも1つのカテゴリが必要です",
  "zoomMeetingID": "ズーム会議ID",
  "cookie": {
    "header": "ウェブサイトで使用されるCookie！",
    "message": "このWebサイトでは、Cookieを使用して、Webサイトで最高のエクスペリエンスを確実に得られるようにしています。",
    "dismiss": "了解しました！",
    "allow": "クッキーを許可する",
    "deny": "辞退",
    "link": "もっと詳しく知る",
    "policy": "クッキーポリシー"
  },

  "userCategories": {
    "Business and Industry": "ビジネスと産業",
    "Advertising": "広告",
    "Agriculture": "農業",
    "Architecture": "建築",
    "Aviation": "航空",
    "Banking": "銀行",
    "Investment banking": "投資銀行",
    "Mutual funds": "共同基金",
    "Online banking": "オンラインバンキング",
    "Retail banking": "リテールバンキング",
    "Business": "仕事",
    "Construction": "建設",
    "Design": "設計",
    "Fashion design": "ファッションデザイン",
    "Graphic design": "グラフィックデザイン",
    "Interior design": "インテリア・デザイン",
    "Economics": "経済",
    "Engineering": "エンジニアリング",
    "Entrepreneurship": "起業家精神",
    "Health care": "健康管理",
    "Higher education": "高等教育",
    "Management": "管理",
    "Marketing": "マーケティング",
    "Nursing": "看護",
    "Online": "オンライン",
    "Digital marketing": "デジタルマーケティング",
    "Display advertising": "ディスプレイ広告",
    "Email marketing": "メールマーケティング",
    "Online advertising": "オンライン広告",
    "Search engine optimization": "検索エンジン最適化",
    "Social media": "ソーシャルメディア",
    "Social media marketing": "ソーシャルメディアマーケティング",
    "Web design": "ウェブデザイン",
    "Web development": "ウェブ開発",
    "Web hosting": "ウェブホスティング",
    "Personal finance": "個人融資",
    "Credit cards": "クレジットカード",
    "Insurance": "保険",
    "Investment": "投資",
    "Mortgage loans": "住宅ローン",
    "Real estate": "不動産",
    "Retail": "小売",
    "Sales": "販売",
    "Science": "科学",
    "Small business": "小規模なビジネス",
    "Entertainment": "エンターテイメント",
    "Games": "ゲーム",
    "Action games": "アクションゲーム",
    "Board games": "ボードゲーム",
    "Browser games": "ブラウザゲーム",
    "Card games": "トランプ",
    "Casino games": "カジノゲーム",
    "First-person shooter games": "ファーストパーソンシューティングゲーム",
    "Gambling": "ギャンブル",
    "Massively multiplayer online games": "大規模マルチプレイヤーオンラインゲーム",
    "Massively multiplayer online role-playing games": "大規模マルチプレイヤーオンラインロールプレイングゲーム",
    "Online games": "オンラインゲーム",
    "Online poker": "オンラインポーカー",
    "Puzzle video games": "パズルビデオゲーム",
    "Racing games": "レーシングゲーム",
    "Role-playing games": "ロールプレイングゲーム",
    "Shooter games": "シューティングゲーム",
    "Simulation games": "シミュレーションゲーム",
    "Sports games": "スポーツゲーム",
    "Strategy games": "ストラテジーゲーム",
    "Video games": "ビデオゲーム",
    "Word games": "ワードゲーム",
    "Role-Playing Games": "ロールプレイングゲーム",
    "Live events": "ライブイベント",
    "Ballet": "バレエ",
    "Bars": "バー",
    "Concerts": "コンサート",
    "Dance halls": "ダンスホール",
    "Music festivals": "音楽祭",
    "Night clubs": "ナイトクラブ",
    "Parties": "関係者",
    "Plays": "演劇",
    "Theatre": "シアター",
    "Movies": "映画",
    "Action movies": "アクション映画",
    "Animated movies": "アニメーション映画",
    "Anime movies": "アニメ映画",
    "Bollywood movies": "ボリウッド映画",
    "Comedy movies": "コメディ映画",
    "Documentary movies": "ドキュメンタリー映画",
    "Drama movies": "ドラマ映画",
    "Fantasy movies": "ファンタジー映画",
    "Horror movies": "ホラー映画",
    "Musical theatre": "ミュージカルシアター",
    "Science fiction movies": "SF映画",
    "Thriller movies": "スリラー映画",
    "Music": "音楽",
    "Blues music": "ブルース音楽",
    "Classical music": "クラシック音楽",
    "Country music": "カントリーミュージック",
    "Dance music": "ダンスミュージック",
    "Electronic music": "電子音楽",
    "Gospel music": "ゴスペル音楽",
    "Heavy metal music": "ヘビーメタル音楽",
    "Hip hop music": "ヒップホップミュージック",
    "Jazz music": "ジャズ音楽",
    "Music videos": "ミュージックビデオ",
    "Pop music": "ポップ・ミュージック",
    "Rhythm and blues music": "リズムアンドブルース音楽",
    "Rock music": "ロックミュージック",
    "Soul music": "ソウルミュージック",
    "Reading": "読む",
    "Books": "本",
    "Comics": "漫画",
    "E-books": "電子書籍",
    "Fiction books": "フィクションの本",
    "Literature": "文学",
    "Magazines": "雑誌",
    "Manga": "マンガ",
    "Mystery fiction": "ミステリーフィクション",
    "Newspapers": "新聞",
    "Non-fiction books": "ノンフィクションの本",
    "Romance novels": "ロマンス小説",
    "TV": "テレビ",
    "TV comedies": "テレビコメディ",
    "TV game shows": "テレビゲーム番組",
    "TV reality shows": "テレビのリアリティ番組",
    "TV talkshows": "テレビのトークショー",
    "Family and relationships": "家族と人間関係",
    "Dating": "デート",
    "Family": "家族",
    "Fatherhood": "父権",
    "Friendship": "友情",
    "Marriage": "結婚",
    "Motherhood": "母性",
    "Parenting": "子育て",
    "Weddings": "結婚式",
    "Fitness and wellness": "フィットネスとウェルネス",
    "Bodybuilding": "ボディービル",
    "Meditation": "瞑想",
    "Physical exercise": "体操",
    "Physical fitness": "体力",
    "Running": "ランニング",
    "Weight training": "ウェイトトレーニング",
    "Yoga": "ヨガ",
    "Food and drink": "飲食",
    "Alcoholic beverages": "アルコール飲料",
    "Beer": "ビール",
    "Distilled beverage": "蒸留飲料",
    "Wine": "ワイン",
    "Beverages": "飲料",
    "Coffee": "コーヒー",
    "Energy drinks": "エネルギー・ドリンク",
    "Juice": "ジュース",
    "Soft drinks": "ソフトドリンク",
    "Tea": "お茶",
    "Cooking": "調理",
    "Baking": "ベーキング",
    "Recipes": "レシピ",
    "Cuisine": "料理",
    "Chinese cuisine": "中華料理",
    "French cuisine": "フランス料理",
    "German cuisine": "ドイツ料理",
    "Greek cuisine": "ギリシャ料理",
    "Indian cuisine": "インド料理",
    "Italian cuisine": "イタリア料理",
    "Japanese cuisine": "日本料理",
    "Korean cuisine": "韓国料理",
    "Latin American cuisine": "ラテンアメリカ料理",
    "Mexican cuisine": "メキシコ料理",
    "Middle Eastern cuisine": "中東料理",
    "Spanish cuisine": "スペイン料理",
    "Thai cuisine": "タイ料理",
    "Vietnamese cuisine": "ベトナム料理",
    "Food": "食べ物",
    "Barbecue": "バーベキュー",
    "Chocolate": "チョコレート",
    "Desserts": "デザート",
    "Fast food": "ファストフード",
    "Organic food": "自然食品",
    "Pizza": "ピザ",
    "Seafood": "シーフード",
    "Veganism": "ビーガン主義",
    "Vegetarianism": "菜食主義",
    "Restaurants": "レストラン",
    "Coffeehouses": "喫茶店",
    "Diners": "食事客",
    "Fast casual restaurants": "ファストカジュアルレストラン",
    "Fast food restaurants": "ファストフードレストラン",
    "Hobbies and activities": "趣味と活動",
    "Arts and music": "芸術と音楽",
    "Acting": "演技",
    "Crafts": "工芸品",
    "Dance": "ダンス",
    "Drawing": "描く",
    "Drums": "ドラム",
    "Fine art": "美術品",
    "Guitar": "ギター",
    "Painting": "ペインティング",
    "Performing arts": "舞台芸術",
    "Photography": "写真撮影",
    "Sculpture": "彫刻",
    "Singing": "歌う",
    "Writing": "書き込み",
    "Current events": "時事問題",
    "Home and garden": "家と庭",
    "Do it yourself (DIY)": "自分でやる（DIY",
    "Furniture": "家具",
    "Gardening": "園芸",
    "Home Appliances": "家電",
    "Home improvement": "家の修繕",
    "Pets": "ペット",
    "Birds": "鳥",
    "Cats": "猫",
    "Dogs": "犬",
    "Fish": "魚",
    "Horses": "馬",
    "Pet food": "ペットフード",
    "Rabbits": "うさぎ",
    "Reptiles": "爬虫類",
    "Politics and social issues": "政治と社会問題",
    "Charity and causes": "慈善と原因",
    "Community issues": "コミュニティの問題",
    "Environmentalism": "環境保護主義",
    "Law": "法",
    "Military": "軍隊",
    "Politics": "政治",
    "Religion": "宗教",
    "Sustainability": "持続可能性",
    "Veterans": "退役軍人",
    "Volunteering": "ボランティア",
    "Travel": "旅行",
    "Adventure travel": "冒険旅行",
    "Air travel": "空の旅",
    "Beaches": "ビーチ",
    "Car rentals": "カーレンタル",
    "Cruises": "クルーズ",
    "Ecotourism": "エコツーリズム",
    "Hotels": "ホテル",
    "Lakes": "湖",
    "Mountains": "山",
    "Nature": "自然",
    "Theme parks": "テーマパーク",
    "Tourism": "観光",
    "Vacations": "休暇",
    "Vehicles": "車両",
    "Automobiles": "自動車",
    "Boats": "ボート",
    "Electric vehicle": "電気自動車",
    "Hybrids": "ハイブリッド",
    "Minivans": "ミニバン",
    "Motorcycles": "オートバイ",
    "RVs": "RV",
    "SUVs": "SUV",
    "Scooters": "原付",
    "Trucks": "トラック",
    "Shopping and fashion": "ショッピングとファッション",
    "Beauty": "美しさ",
    "Beauty salons": "ビューティーサロン",
    "Cosmetics": "化粧品",
    "Fragrances": "フレグランス",
    "Hair products": "ヘアケア製品",
    "Spas": "スパ",
    "Tattoos": "入れ墨",
    "Clothing": "衣類",
    "Children's clothing": "子供服",
    "Men's clothing": "紳士服",
    "Shoes": "靴",
    "Women's clothing": "女性の服装",
    "Fashion accessories": "ファッションアクセサリー",
    "Dresses": "ドレス",
    "Handbags": "ハンドバッグ",
    "Jewelry": "ジュエリー",
    "Sunglasses": "サングラス",
    "Shopping": "買い物",
    "Boutiques": "ブティック",
    "Coupons": "クーポン",
    "Discount stores": "ディスカウントストア",
    "Luxury goods": "高級品",
    "Online shopping": "オンラインショッピング",
    "Shopping malls": "ショッピングモール",
    "Toys": "おもちゃ",
    "Sports and outdoors": "スポーツとアウトドア",
    "Outdoor recreation": "野外レクリエーション",
    "Boating": "ボート遊び",
    "Camping": "キャンプ",
    "Fishing": "釣り",
    "Horseback riding": "乗馬",
    "Hunting": "狩猟",
    "Mountain biking": "マウンテンバイク",
    "Surfing": "サーフィン",
    "Sports": "スポーツ",
    "American football": "アメリカンフットボール",
    "Soccer": "サッカー",
    "Auto racing": "自動車レース",
    "Baseball": "野球",
    "Basketball": "バスケットボール",
    "College football": "カレッジフットボール",
    "Golf": "ゴルフ",
    "Marathons": "マラソン",
    "Skiing": "スキー",
    "Snowboarding": "スノーボード",
    "Swimming": "水泳",
    "Tennis": "テニス",
    "Thriathlon": "トライアスロン",
    "Volleyball": "バレーボール",
    "Badminton": "バドミントン",
    "Cricket": "クリケット",
    "Hockey": "ホッケー",
    "Table Tennis": "卓球",
    "Technology": "テクノロジー",
    "Computers": "コンピューター",
    "AI": "人工知能",
    "Blockchain": "ブロックチェーン",
    "Cloud technology": "クラウドテクノロジー",
    "Data Science & analytics": "データサイエンスと分析",
    "Extended Reality": "エクステンデットリアリティ",
    "IoT": "モノのインターネット",
    "Machine Learning": "機械学習",
    "Open Source Software": "オープンソースソフトウェア",
    "Robotics": "ロボット工学",
    "Quantum Computing": "量子コンピューティング",
    "UI/UX Design": "UI / UXデザイン",
    "Consumer electronics": "家庭用電化製品",
    "Audio equipment": "オーディオ機器",
    "Camcorders": "ビデオカメラ",
    "Cameras": "カメラ",
    "E-book readers": "電子書籍リーダー",
    "GPS devices": "GPSデバイス",
    "Game consoles": "ゲーム機",
    "Mobile phones": "携帯電話",
    "Portable media players": "ポータブルメディアプレーヤー",
    "Projectors": "プロジェクター",
    "Smartphones": "スマートフォン",
    "Televisions": "テレビ",
    "ESports": "Eスポーツ",
    "Agent": "エージェント",
    "Battle": "戦い",
    "Royale": "ロワイヤル",
    "Coach": "コーチ",
    "First Person Shooter": "ファーストパーソンシューティングゲーム",
    "Fighting Game": "格闘ゲーム",
    "Mass Multiplayer Online": "マスマルチプレイヤーオンライン",
    "Role-Playing Game": "ロールプレイングゲーム",
    "Multiplayer Online Battle Arena": "マルチプレイヤーオンラインバトルアリーナ",
    "Online Trading Card Game": "オンライントレーディングカードゲーム",
    "Player vs. Player": "プレイヤー対プレイヤー",
    "Professional player": "プロ選手",
    "Real-time Strategy": "リアルタイム戦略",
    "Shoutcaster": "シャウトキャスター",
    "Sports Game": "スポーツゲーム",
    "Third-Person Shooter": "サードパーソンシューティングゲーム",
    "Master Class": "マスタークラス",
    "Culinary Arts" : "料理芸術",
    "Business Leadership" : "ビジネスリーダーシップ",
    "Green Living" : "グリーンリビング",
    "Renewable energy" : "再生可能エネルギー",
    "Sustainable Living" : "持続可能な生活",
    "Gadgets" : "ガジェット",
    "Sleep Health" : "睡眠の健康",
    "Holistic Health" : "ホリスティックヘルス",
    "Nutrition" : "栄養",
    "Mental Health" : "メンタルヘルス",
    "Podcasts": "ポッドキャスト",
    "Streaming Services": "ストリーミングサービス",
    "Virtual Reality (VR)": "仮想現実 (VR)",
    "Education": "教育",
    "E-learning"  : "Eラーニング",
    "Language Learning": "言語学習",
    "STEM Education": "STEM教育",
    "Cryptocurrency": "暗号通貨",
    "Fintech": "フィンテック",
 },

  "contactUsCategories": {
    "SignUp and SignIn": "サインアップとサインイン",
    "Profile and Settings": "プロファイルと設定",
    "Host Bank Activation": "ホストバンクのアクティベーション",
    "Event and User": "イベントとユーザー",
    "Collaboration": "コラボレーション",
    "Hosting Event": "ホスティングイベント",
    "Invitation": "招待",
    "Payment": "支払い",
    "Users": "ユーザー",
    "Cancellation / Refund": "キャンセル/返金",
    "Technical": "テクニカル",
    "Suggestions": "提案",
    "Event and Participation" : "イベントと参加"
  },

  "meetingCategories": {
    "Great for Groups": "グループに最適",
    "Flowers Arrangement & Floristry": "フラワーアレンジメント＆フローリストリー",
    "Festive Season": "ホリデーシーズン",
    "Learning": "学ぶ",
    "Drinks": "飲み物",
    "Health & Wellness": "健康とウェルネス",
    "Fitness": "フィットネス",
    "Family Friendly": "家族向けの",
    "Culture & History": "文化と歴史",
    "Fashion & Beauty": "ファッション＆ビューティー",
    "Magic": "魔法",
    "Arts & Crafts": "アート＆クラフト",
    "Baking & Cooking": "ベーキング＆クッキング",
    "Entertainment": "エンターテイメント",
    "Dance": "ダンス",
    "Writing": "書き込み",
    "Music": "音楽",
    "Photography": "写真撮影",
    "Others": "その他"
  },

  "BeeMG-ERR001": "会議が見つかりません",
  "BeeMG-ERR002": "あなたはすでにこの会議にフィードバックしています、ありがとうございました",
  "BeeMG-ERR003": "すでにBeeMGに招待されています",
  "BeeMG-ERR004": "ユーザーはすでに登録されています",
  "BeeMG-ERR005": "招待制限に達しました",
  "BeeMG-ERR006": "登録は招待ベースのみです",
  "BeeMG-ERR007": "BeeMGに登録済みの電子メール",
  "BeeMG-ERR008": "起動通知用にすでに登録されている電子メール",
  "BeeMG-ERR009": "ゲストバリューは最小１である必要があります",
  "BeeMG-ERR010": "完売",
  "BeeMG-ERR011": "このイベントはすでに完売しています",
  "BeeMG-ERR012": "キャンセル期間が終了しました",
  "BeeMG-ERR013": "すでに返金された金額",
  "BeeMG-ERR014": "間違った署名",
  "BeeMG-ERR015": "ミーティングの最大ユーザー数と最小ユーザー数を明記してください",
  "BeeMG-ERR016": "会議の詳細IDは提供されていません",
  "BeeMG-ERR017": "他のユーザーの会議を作成できません",
  "BeeMG-ERR018": "オーディオイベントのみ無料",
  "BeeMG-ERR019": "1日以内に正しいタイムスロットを選択してください",
  "BeeMG-ERR020": "無料の音声会議のみ無料",
  "BeeMG-ERR021": "会議の言語を教えてください",
  "BeeMG-ERR022": "有効な日時を入力してください",
  "BeeMG-ERR023": "無効な入力データ",
  "BeeMG-ERR024": "現在のユーザー会議ではありません",
  "BeeMG-ERR025": "このユーザーはイベントポリシーを受け入れません",
  "BeeMG-ERR026": "確認済みのユーザーのみがイベントを作成できます",
  "BeeMG-ERR027": "他のユーザーのイベントを編集できません",
  "BeeMG-ERR028": "このユーザーによって作成されていないイベント",
  "BeeMG-ERR029": "イベントは削除できません。会議がスケジュールされています",
  "BeeMG-ERR030": "今回はすでに残りを設定しています",
  "BeeMG-ERR031": "あなたはこの会議の参加者ではありません",
  "BeeMG-ERR032": "現在のユーザー通知の残りではありません",
  "BeeMG-ERR033": "確認済みのユーザーのみが無料イベントに登録できます",
  "BeeMG-ERR034": "この無料イベントにはすでに登録済みです",
  "BeeMG-ERR035": "このユーザーはすでにこのイベントに招待されています",
  "BeeMG-ERR036": "BeeMGにいないユーザー",
  "BeeMG-ERR037": "ユーザーが確認されていません。他のユーザーを追加してください",
  "BeeMG-ERR038": "このイベントの主催者は共同主催者になることはできません",
  "BeeMG-ERR039": "あなたはすでにこのCoHostを追加しました",
  "BeeMG-ERR040": "共催者はオーディオイベントにのみ追加できます",
  "BeeMG-ERR041": "他のユーザーのイベントにCoHostを追加できません",
  "BeeMG-ERR042": "CoHostsの追加制限を超えました",
  "BeeMG-ERR043": "会議をスケジュールしてから、共同ホストを追加します",
  "BeeMG-ERR044": "共同ホストを削除するための現在のユーザー会議ではありません",
  "BeeMG-ERR045": "招待されたユーザーIDが指定されていません",
  "BeeMG-ERR046": "ミーティングのユーザー数を記載してください",
  "BeeMG-ERR047": "ゲストは空であってはなりません",
  "BeeMG-ERR048": "あなたはもう会議を要求することはできません",
  "BeeMG-ERR049": "他のユーザーリクエストを編集できません",
  "BeeMG-ERR050": "リクエストが見つかりません",
  "BeeMG-ERR051": "現在のユーザーは自分のプロファイルを報告できません",
  "BeeMG-ERR052": "確認済みのユーザーのみが他のユーザーを報告できます",
  "BeeMG-ERR053": "ユーザーを編集できません",
  "BeeMG-ERR054": "ユーザーが作成されていません",
  "BeeMG-ERR055": "ミーティング時間の期限が切れています",
  "BeeMG-ERR056": "ホストが会議を開始するのをお待ちください",
  "BeeMG-ERR057": "開始時間をお待ちください",
  "BeeMG-ERR058": "アクティブセッションはすでに存在します",
  "BeeMG-ERR059": "ミーティングはすでに同じ時間に存在します。別のタイムスロットを選択してください。",
  "BeeMG-ERR060": "参加者はこの会議に参加していますが、会議を編集できません",
  "BeeMG-ERR061": "日時形式が無効です",
  "BeeMG-ERR062": "他のユーザーの会議通知の残りを削除できません",
  "BeeMG-ERR063": "自分の会議にフィードバックを与えることはできません",
  "BeeMG-ERR064": "未払いのイベントについてフィードバックを提供することはできません",
  "BeeMG-ERR065": "開始されていない会議についてフィードバックを提供できません",
  "BeeMG-ERR066": "Googleでログイン",
  "BeeMG-ERR067": "~のみ支払済みです",
  "BeeMG-ERR068": "ユーザー",
  "BeeMG-ERR069": "メールIDを入力しました",
  "BeeMG-ERR070": "ホストを招待することはできません",
  "BeeMG-ERR071": "ゲストに支払いはできません",
  "BeeMG-ERR072": "他のユーザーリクエストを編集できません",
  "BeeMG-ERR073": "すでに同じタイムスロットを選択しています",
  "BeeMG-ERR074": "自分の会議をリクエストすることはできません",
  "BeeMG-ERR075": "有効な費用を入力してください",
  "BeeMG-ERR076": "他の人にフィードバックすることはできません",
  "BeeMG-ERR077": "ユーザーはアクティブなリクエストを1つだけ持つ必要があります",
  "BeeMG-ERR078": "理由は空であってはなりません",
  "BeeMG-ERR079": "フィールドは空であってはなりません",
  "BeeMG-ERR080": "確認済みのユーザーのみが招待できます",
  "BeeMG-ERR081": "匿名のイベントは作成できません",
  "BeeMG-ERR082": "開始時間と終了時間を同じにすることはできません",
  "BeeMG-ERR083": "会議費は最小限にする必要があります",
  "BeeMG-ERR084": "大きなファイルサイズ",
  "BeeMG-ERR085": "有料イベントのキャンセルポリシーは空にしないでください",
  "BeeMG-ERR086": "ユーザーはメッセージを許可しません",
  "BeeMG-ERR087": "最大参加者数を超えています",
  "BeeMG-ERR088": "保留中/拒否されたリクエストの残りを設定できません",
  "BeeMG-ERR089": "ストライプアカウントをアクティブにしてから、有料イベントを作成します",
  "BeeMG-ERR090": "1日の動画リクエスト制限に達しました",
  "BeeMG-ERR091": "過去の会議の費用を支払うことはできません",
  "BeeMG-ERR092": "会議IDまたは会議詳細IDを空にしないでください",
  "BeeMG-ERR093": "イベントの料金を支払い、キャンセルしてみてください",
  "BeeMG-ERR094": "このユーザーは偽のプロファイルとしてブロックされています",
  "BeeMG-ERR095": "理由の長さがを超えています",
  "BeeMG-ERR096": "払い戻しに失敗しました",
  "BeeMG-ERR097": "すでにキャンセルをリクエストしています",
  "BeeMG-ERR098": "すでに登録されているため、ユーザーを共同ホストとして追加できません",
  "BeeMG-ERR099": "すでに支払われているため、ユーザーを共同ホストとして追加することはできません",
  "BeeMG-ERR100": "アカウントが有効になっていません。後でもう一度お試しください",
  "BeeMG-ERR101": "試行回数が多すぎます。しばらくしてからお試しください",
  "BeeMG-ERR106": "共同主催者を招待することはできません",
  "BeeMG-ERR107": "自分から招待することはできません",
  "BeeMG-ERR108": "ユーザーはすでにイベントの支払いを済ませています",
  "BeeMG-ERR109": "既に支払いを受けているため、ユーザーを共同主催者として追加できません",
}
