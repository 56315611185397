/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useEffect} from 'react';
import {useUpdatePreferenceMutation} from '../Services/preferenceApi';
import {getPreference} from './../Services/userReducer';
import {useAppSelector} from './../Store/hooks';

/**
 * A hook to manage the current timezone and update the user's preference accordingly.
 *
 * @param {string} currentTimeZone - The current timezone to be used.
 * @return {{ currentTimeZone: string, useCurrentTimezoneHook: function }} - An object containing the current timezone and the hook function itself.
 */

export const useCurrentTimezoneHook = (currentTimeZone: string) => {
  const [updatePreference] = useUpdatePreferenceMutation();
  const preference = useAppSelector(getPreference);

  useEffect(() => {
    let interval: any = null;
    interval = setInterval(() => {
      if (
        preference?.id &&
        preference?.currency &&
        (preference?.timezone !== currentTimeZone ||
          preference?.timezoneUtc !== new Date().getTimezoneOffset().toString())
      ) {
        updatePreference({
          id: preference.id,
          currency: preference.currency,
          timezone: currentTimeZone,
          timezoneUtc: new Date().getTimezoneOffset().toString(),
          language: preference?.language,
          showAudioGuide: preference?.showAudioGuide,
          meetingLanguage: preference?.meetingLanguage,
        });
      }
    }, 10000); // every 10 seconds
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeZone, preference]);

  return {
    currentTimeZone,
    useCurrentTimezoneHook,
  };
};
