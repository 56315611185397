/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {allApis} from './allApis';

/**
 * Defines the API endpoints for settings.
 *
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ getAllSettings: query, getByCUserCurrency: query, getServerDateTime: query }} - An object containing the defined endpoints.
 */

export const settingApi = allApis.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    getAllSettings: builder.query<any, any>({
      query: key => `setting/all/key`,
      // keepUnusedDataFor: 300,
    }),
    getByCUserCurrency: builder.query<number, void>({
      query: () => `setting/stripe/stripeAmount`,
    }),
    getServerDateTime: builder.query<string, void>({
      query: () => `setting/get/serverDateTime`,
      providesTags: ['ServerDateTime'],
    }),
  }),
});

export const {
  useGetAllSettingsQuery,
  useGetByCUserCurrencyQuery,
  useGetServerDateTimeQuery,
} = settingApi;
