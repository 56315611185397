/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */
import React, { useEffect, useState } from 'react';
import { Ledger, TransactionType } from '../../models/ledger.model';
import { useTranslation } from 'react-i18next';
import { useGetPayToHostByMeetingIdQuery } from '../../Services/ledgerApi';
import { AccordionBorder, AccordionItem, AccordionPanel } from '../../Components/base/accordion/accordion';
import Loading from '../../Components/base/loading/loading';

const HostCreditDetailsCollapse = ({ MeetingId }: { MeetingId: any }) => {
  const { t } = useTranslation();
  const { data: hostCreditData, isLoading: hostCreditLoading, isError } = useGetPayToHostByMeetingIdQuery(MeetingId ?? MeetingId, { skip: !MeetingId });

  const [relatedTransactions, setRelatedTransactions] = useState<
  { credit: Ledger; relatedDebit: Ledger | null; relatedRefunds: Ledger[] | null }[]
>([]);

  useEffect(() => {
    if (hostCreditData) {
      const creditTransactions = hostCreditData.filter(
        (item: any) => item.transactionType === TransactionType.Credit && item.toTransferId !== null
      );

      const updatedTransactions = creditTransactions.map((credit: Ledger) => {
        const relatedDebit = hostCreditData.find(
          (debit: Ledger) => debit.transactionType === TransactionType.Debit && debit.balanceTransactionId === credit.toTransferId
        );

        const relatedRefunds = hostCreditData.filter(
          (refund: Ledger) => refund.transactionType === TransactionType.Credit && refund.balanceTransactionId === credit.toTransferId
        );
        return { credit, relatedDebit, relatedRefunds };
      });

      setRelatedTransactions(updatedTransactions);
    }
  }, [hostCreditData]);

  const debitTransactions = hostCreditData?.filter((item: any) => item.transactionType === TransactionType.Debit);
  const refundTransactions = hostCreditData?.filter((item: any) => item.transactionType === TransactionType.Credit && item.toTrasferId == null && item.cancellation !== null);
  const creditTransactions = hostCreditData?.filter((item: any) => item.transactionType === TransactionType.Credit && item.toTrasferId !== null && item.cancellation === null || undefined);

  const totalGuestsPaid = debitTransactions?.reduce((acc: any, transaction: Ledger) => acc + transaction.guestCount, 0);
  const totalGuestRefund = refundTransactions?.reduce((acc: any, transaction: Ledger) => acc + transaction.guestCount, 0);
  const totalGuestCredit = creditTransactions?.reduce((acc: any, transaction: Ledger) => acc + transaction.guestCount, 0);
  if (hostCreditLoading) {
    return <Loading></Loading>;
  }
  

  return (
    <AccordionBorder >
      <h2 className="text-2xl font-bold text-center mb-2 mt-4">{t('invoiceDetails')}</h2>
      <div className="flex flex-col col-span-3 px-8 w-full mx-auto space-y-1">
        <p>{t('Total Guests Paid')}: {totalGuestsPaid}</p>
        <p>{t('Total Guest Refund')}: {totalGuestRefund}</p>
        <p>{t('Total Guest Credit')}: {totalGuestCredit}</p>
      </div>
      {relatedTransactions.map(({ credit, relatedDebit, relatedRefunds }, index) => (
        <><AccordionItem keyId={credit.transactionId} toggle={`panel-${credit.transactionId}`} color="card" className="w-full">
          {/* Accordion Toggle with Border */}
          <div className="flex w-full overflow-hidden pl-1 pb-1">
            <div className="border border-gray-300 rounded-lg bg-white p-4 w-full flex bg-red-500">
              <div className="w-11/12">
                <h3 className="text-lg font-semibold">{t('guest') + ' ' + (index + 1)}</h3>
                <p className="text-sm text-gray-500 ">{t('transactionID')}: {credit.transactionId}</p>
                <p className="text-sm text-gray-500">{t('date')}: {new Date(credit.transactionDateTime).toLocaleString()}</p>
              </div>

              <div className="w-1/12 flex items-center justify-center">
                <div className="text-base font-bold text-BeeMG-green text-center">
                  ₹ {credit.transactionAmount}
                </div>
              </div>
            </div>
          </div>
        </AccordionItem>
          <AccordionPanel id={`panel-${credit.transactionId}`}>

            <div className="flex flex-col gap-y-2 border-b px-5">

              {/* Debit Section */}
              <div className="bg-gray-100 p-4 rounded-lg w-full">
                <h4 className="text-md font-semibold">{t('debitDetails')}</h4>
                {console.log('**', relatedDebit)}
                {relatedDebit ? (
                  <div className="flex">

                    {/* Left Side */}
                    <div className="w-1/2 space-y-2">
                      <div className='text-sm text-gray-700'>
                        <p>
                          {t('transactionID')}:
                          <span className="ml-1">{relatedDebit.transactionId}</span>
                        </p>
                        <p>
                          {t('guestCount')}:
                          <span className="ml-1">{relatedDebit.guestCount}</span>
                        </p>
                        <p>{t('date')}:
                          <span className="ml-1">{new Date(relatedDebit.transactionDateTime).toLocaleString()}</span>
                        </p>
                      </div>
                    </div>

                    {/* Right Side */}
                    <div className="w-1/2 space-y-2">
                      <p className="text-sm text-gray-700 flex justify-between">
                        {t('amount')}:
                        <span className="ml-1">₹{relatedDebit.transactionAmount}</span>
                      </p>
                      <div className="text-sm text-gray-700">
                        <span className="font-semibold">{t('feeDetails')}:</span>
                        {relatedDebit.transactionLedgerDetail?.length > 0 ? (
                          <div className="pl-4">
                            {relatedDebit.transactionLedgerDetail
                              .filter((detail: any) => detail.transactionType === 'DEBIT')
                              .map((detail: any, index: number) => (
                                <div key={index} className="flex justify-between">
                                  <span>{detail.accountType}:</span>
                                  <span>₹{detail.amount}</span>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <span>{t('noFeeDetails')}</span>
                        )}
                      </div>
                      <p className="text-sm text-gray-700 flex justify-between px-2">
                        {t('processingFee')}:
                        <span className="ml-1">₹{relatedDebit.processingFee}</span>
                      </p>
                      <p className="text-base font-semibold text-gray-700 flex justify-between">
                        {t('netTransactionAmount')}:
                        <span className="ml-1">₹{relatedDebit.netTransactionAmount}</span>
                      </p>
                    </div>

                  </div>
                ) : (
                  <p className="text-sm text-gray-500">{t('noRelatedDebitTransactionFound')}</p>
                )}
              </div>

              {/* Refund Section */}

              <div className="bg-gray-100 p-4 rounded-lg w-full mt-4 mb-2">
                <h4 className="text-md font-semibold">{t('refundDetails')}</h4>
                {relatedRefunds && relatedRefunds.length > 0 ? (
                  relatedRefunds.map((refund: Ledger, index: number) => (
                    <div key={index} className="flex justify-between mt-2">
                      {/* Left Side */}
                      <div className="w-1/2 space-y-1">
                        <p className="text-sm text-gray-700">
                          {t('transactionID')}:
                          <span className="ml-1">{refund.transactionId}</span>
                        </p>
                        <p className="text-sm text-gray-700">
                          {t('date')}:
                          <span className="ml-1">{new Date(refund.transactionDateTime).toLocaleString()}</span>
                        </p>
                      </div>

                      {/* Right Side */}
                      <div className="w-1/2 space-y-1 text-right">
                        <p className="text-sm text-gray-700 flex justify-between">
                          {t('amount')}:
                          <span className="ml-1">₹{refund.transactionAmount} {refund.currency}</span>
                        </p>
                        <p className="text-sm text-gray-700 flex justify-between">
                          {t('cancellationCharges')}:
                          <span className="ml-1">{refund.cancellation.chosenCancellationPercent}%</span>
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-sm text-gray-500">{t('noRefundTransactionFound')}</p>
                )}
              </div>

            </div>
          </AccordionPanel></>
      ))}
    </AccordionBorder>
  );
};

export default HostCreditDetailsCollapse;
