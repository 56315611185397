/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {toastSuccess} from '../../Components/toast'
import {
  getCurrentMeetingUser,
  getIsMeetingEnded,
  setMeetingMinimized,
  setShowStats,
} from '../../Services/livekitReducer'
import {useAppDispatch, useAppSelector} from '../../Store/hooks'
import LiveKitJoinView from './LiveKitJoinView'
import {useLeaveLiveKitHook} from './hooks/livekitWebOnlyHooks'

/**
 * Handles LiveKit navigation between the meeting join view and the meeting view based on the meetingId URL parameter.
 *
 * When the meetingId URL parameter is present, the LiveKitJoinView component is rendered.
 * When the meetingId URL parameter is not present, the LiveKitMeeting component is rendered.
 *
 * The component uses the useLeaveLiveKitHook hook to handle the leave meeting action.
 * The component also uses the isMeetingEnded selector to determine if the meeting has ended.
 * If the meeting has ended, the component will automatically leave the meeting and show a toast message.
 *
 * The component also uses the setShowStats action to show/hide the meeting stats.
 * If the stats URL parameter is 'quality', the component will show the meeting stats.
 * If the stats URL parameter is not 'quality', the component will hide the meeting stats.
 *
 * The component uses the setMeetingMinimized action to minimize the meeting when the component is unmounted.
 * The component also uses the useEffect hook to minimize the meeting when the component is unmounted.
 */
const LiveKitNavigation = () => {
  const dispatch = useAppDispatch()
  const {t} = useTranslation()
  const {meetingId, stats} = useParams()

  const isMeetingEnded = useAppSelector(getIsMeetingEnded)
  const {handleLeaveMeeting} = useLeaveLiveKitHook()

  useEffect(() => {
    dispatch(setMeetingMinimized(false))

    if (stats === 'quality') {
      dispatch(setShowStats(true))
    } else {
      dispatch(setShowStats(false))
    }

    return () => {
      dispatch(setMeetingMinimized(true))
    }
  }, [])

  useEffect(() => {
    if (isMeetingEnded) {
      toastSuccess(t('meetingEnded'))
      handleLeaveMeeting()
    }
  }, [isMeetingEnded])

  return (
    <>
      {meetingId && <LiveKitJoinView meetingId={meetingId} />}
    </>
  )
}

export default React.memo(LiveKitNavigation)
