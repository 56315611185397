/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Chip from "../../../Components/Chip";
import Loading from "../../../Components/base/loading/loading";
import { sortingCategories, useCategoryListForSelectHook, useUpdateCategoryHook } from "../../../Hooks/SelectCategoriesHook";
import { getUserCategories } from "../../../Services/userReducer";
import { useAppSelector } from "../../../Store/hooks";
import { Category } from "../../../models/category.model";
import { UserCategory } from "../../../models/userCategory.model";
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../Assets/icons/beeMG-icons-close.svg';

/**
 * A component that displays a list of user categories as chips.
 * Each chip can be closed to remove the category from the user.
 *
 * @param {Object} props - An object containing a function to remove a user category.
 * @param {function} props.removeCategory - A function that takes a UserCategory as an argument and removes it from the user's categories.
 * @return {JSX.Element} A JSX element representing the list of user category chips.
 */
function DisplaySelectedCategories({ removeCategory }: { removeCategory: (cat: UserCategory) => void }) {
  const userCategories = useAppSelector(getUserCategories)
  const sortedCategories = sortingCategories(userCategories)
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false); 

  const handleRemoveCategory = (userCategory: UserCategory) => {
    if (userCategories.length > 1) {
      removeCategory(userCategory);
      setShowTooltip(false); 
    } else {
      setShowTooltip(true); 
    }
  };

  return (
    <>
      <div className="flex flex-row gap-1 flex-wrap">
        {sortedCategories?.map((userCategory) => {

          if (userCategory.category) {
            return (
              <div key={userCategory.id} className="relative"
              onMouseEnter={() => {
                if (userCategories.length === 1) {
                  setShowTooltip(true); 
                }
              }}
              onMouseLeave={() => {
                setShowTooltip(false);
              }}
            >
              <Chip
                isSelected={true}
                value={t(`userCategories.${userCategory.category.name}`)}
                key={userCategory.id}
                removeCatId={`remove_${userCategory.category?.name}`}
                onClickClose={() => handleRemoveCategory(userCategory)}
              />
              {showTooltip && (
                <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 w-max bg-gray-800 text-white text-xs rounded-md p-2">
                  {t('youMustHaveAtleastOneCategory')}
                </div>
              )}
            </div>
            )
          }
          return null;
        }
        )}

      </div>
    </>
  )
}

/**
 * A component that displays a list of sub categories as chips.
 *
 * @param {Object} props - An object containing a list of categories and a function to set the selected category.
 * @param {Category[]} props.categories - A list of categories to display as chips.
 * @param {function} props.setSelected - A function that takes a category as an argument and sets it as the selected category.
 * @return {JSX.Element} A JSX element representing the list of sub category chips.
 */
function SubCategoryDisplay({ categories, setSelected }: { categories: Category[], setSelected: (cat: Category) => void }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row gap-1 flex-wrap">
      {categories?.map((category) =>
        <Chip
          key={category.id}
          onClick={() => setSelected(category)}
          addCatId={`category_${category?.name.split(' ')[0]}`}
          value={t(`userCategories.${category.name}`)}
        />
      )}
    </div>
  )
}

/**
 * A component that displays a list of sub categories as chips.
 * The component will recursively show sub categories until a selected category is chosen.
 *
 * @param {Object} props - An object containing a list of categories and a function to set the selected category.
 * @param {Category[]} [props.parentCategories] - A list of categories to display as chips.
 * @param {function} props.setSelectedUserCategory - A function that takes a category as an argument and sets it as the selected category.
 * @return {JSX.Element} A JSX element representing the list of sub category chips.
 */
function CategoryListForSelect({
  parentCategories, setSelectedUserCategory
}: { parentCategories?: Category[], setSelectedUserCategory: (userCat: UserCategory) => void }) {

  const { t } = useTranslation();
  const {
    isLoading, categories, displayCategoriesNames, setCategorySelected, resetCategorySelected, loadCategoryId, selectedUserCategory
  } = useCategoryListForSelectHook();

  const translatedCategoriesNames = displayCategoriesNames.map(name => t(`userCategories.${name}`));
  useEffect(() => {
    if (selectedUserCategory) {
      setSelectedUserCategory(selectedUserCategory);
    }
  }, [selectedUserCategory]);

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="flex flex-col gap-3">
      {displayCategoriesNames.length > 0 && (
        <div data-testid={`displayCategory_${translatedCategoriesNames}`}>{translatedCategoriesNames.join('> ')} {' >'}</div>
      )}
      {parentCategories && !loadCategoryId && (
        <SubCategoryDisplay categories={parentCategories} setSelected={setCategorySelected} />
      )}
      {categories && loadCategoryId && (
        <>
          <div className="flex flex-row">
            <SubCategoryDisplay categories={categories} setSelected={setCategorySelected} />
            <span className="ml-2 cursor-pointer">
              <img src={CloseIcon} alt="close" width={20} height={20} onClick={() => resetCategorySelected()} />
            </span>
          </div>
        </>
      )}
    </div>
  )
}


/**
 * A component that displays a list of user categories as chips and allows the user to select categories.
 * It will recursively show sub categories until a selected category is chosen.
 *
 * @return {JSX.Element} A JSX element representing the list of user category chips and category selection.
 */
function UpdateCategory() {
  const { isLoading, currentUser, parentCategories, removeUserCategory, setSelectedUserCategory } = useUpdateCategoryHook();

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <div className="flex flex-col gap-8">
        {currentUser?.userCategory && <DisplaySelectedCategories removeCategory={removeUserCategory} />}
        <CategoryListForSelect parentCategories={parentCategories} setSelectedUserCategory={setSelectedUserCategory} />
      </div>
    </>
  )
}

export default UpdateCategory;
