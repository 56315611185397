/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable prettier/prettier */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "camera": "Camera",
  "cameraOn": "Camera On",
  "cameraOff": "Camera Off",
  "characters": "characters",
  "close": "Close",
  "coHost": "Co-host",
  "doYouNeedToEndTheMeeting": "Do you need to end the meeting?",
  "doYouWantToLeaveTheMeeting": "Do you want to leave the meeting?",
  "end": "End",
  "groupChat": "Group Chat",
  "gridView": "Grid View",
  "handRaise": "Hand Raise",
  "host": "Host",
  "hostGrantedPermissionToTalk": "Host granted permission to talk",
  "hostRemovedPermissionToTalk": "Host removed permission to talk",
  "hostView": "Host View",
  "language": "Language",
  "leave": "Leave",
  "meetingWillEndIn": "Meeting will end in",
  "message": "Message",
  "messageLengthExceeds": "Message length exceeds",
  "mic": "Mic",
  "microphoneOn": "Microphone On",
  "microphoneOff": "Microphone Off",
  "minutes": "minutes",
  "multiSessionDoYouWantToLeave": "Multi-session. Do you want to leave?",
  "muteAll": "Mute All",
  "noResultsFound": "No results found",
  "others": "Others",
  "participants": "Participants",
  "pin": "Pin",
  "pip": "PIP",
  "privateChat": "Private Chat",
  "raisedTheirHand": "raised their hand",
  "screenShare": "Screenshare",
  "screenShareOn": "Screenshare On",
  "screenShareOff": "Screenshare Off",
  "searchParticipants": "Search participants",
  "send": "Send",
  "settings": "Settings",
  "staged": "Staged",
  "toSpeakRaiseYourHandAndWaitForTheHostToUnmuteYou": "To speak, raise your hand, and wait for the host to unmute you",
  "toShareYourScreenKindlyRequestTheHostToUnmuteYou": "To share your screen, kindly request the host to unmute you",
  "typeAMessage": "Type a message",
  "unmute": "Unmute",
  "unmuteAll": "Unmute All",
  "unpin": "Unpin",
}
