/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import Chip from '../../Components/Chip'
import { User } from '../../models/user.model'
import { UserCategory } from '../../models/userCategory.model'
import { sortingCategories } from '../../Hooks/SelectCategoriesHook'

/**
 * A component that displays a list of user categories as chips.
 * It will be rendered when the user has at least one category selected.
 *
 * @param {Object} props - An object containing a user object.
 * @param {User} props.user - An object containing the user's information.
 * @return {JSX.Element} A JSX element representing the list of user category chips.
 */
const UserInterests = ({ user }: { user: User }) => {
  const { t } = useTranslation()
  const sortedCategories = sortingCategories(user?.userCategory)

  return (
    <div>
      {sortedCategories?.length && <div className="flex flex-col gap-y-4 justify-center items-center w-full">
        <div id="txt_interest" className="font-medium text-xl leading-none">{t('interests')}</div>
        <div
          data-testid={`categoryLength-${user?.userCategory?.length}`}
          className="flex flex-wrap justify-center items-center gap-1">
          {sortedCategories?.map((category: UserCategory, index: number) => {
            if (category && category.category?.name) {
              return (
                <Chip
                  value={t(`userCategories.${category.category.name}`)}
                  key={category.id}
                  data-testid={`categoryName-${index}`}
                  addCatId={category?.category?.name.split(' ')[0]}
                />
              )
            }
            return null;
          })}
        </div>
      </div>}
    </div>
  )
}

export default UserInterests;
