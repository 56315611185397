/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import InterestedAndSuggestions from './InterestedAndSuggestions'
import Loading from '../Components/base/loading/loading';
import NoSession from '../Components/NoSession';
import { SuggestionsHooks } from '../Hooks/SuggestionsHook';

/**
 * A React component that displays a list of meeting suggestions
 * based on the current user's preferences.
 *
 * If the loading state is true, it displays a loading component.
 *
 * If no meeting suggestions are available, it displays a NoSession
 * component with a message indicating that no events are available.
 *
 * Otherwise, it displays a list of meeting suggestions using the
 * InterestedAndSuggestions component.
 *
 * @return {JSX.Element} The meeting suggestions list component.
 */
function Suggestions() {

  const { meetingMaybeFiltered, meetingMaybe, maybeLoading } = SuggestionsHooks()

  if (maybeLoading) {
    return <Loading />
  }

  if (!meetingMaybe) {
    return <NoSession type="events" className="h-[60vh] items-center justify-center" />
  }

  return <>
    {meetingMaybeFiltered && <InterestedAndSuggestions meetings={meetingMaybeFiltered} />}
  </>
}

export default Suggestions
