/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import NoSession from '../Components/NoSession'
import { Accordion } from '../Components/base/accordion/accordion'
import Loading from '../Components/base/loading/loading'
import { useTransactionHooks } from '../Hooks/TransactionHook'
import { getPreference } from '../Services/userReducer'
import { Ledger, TransactionStatus } from '../models/ledger.model'
import CreditedCard from './Transactions/CreditedCard'
import DebitedCard from './Transactions/DebitedCard'
import FailedCard from './Transactions/FailedCard'
import InProgressCard from './Transactions/InProgressCard'
import TotalCreditedCard from './Transactions/TotalCreditedCard'
import TotalDebitedCard from './Transactions/TotalDebitedCard'
import TotalEarningsCard from './Transactions/TotalEarningsCard'

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'
import { Bar } from "react-chartjs-2"
import { useBarChatHook } from '../Hooks/BarChatHook'
import { DelayDisplay } from '../Components/DelayDisplay'
import Home from './Home'
import HostCreditCard from './Transactions/HostCreditCard'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
};

/**
 * Component for displaying transaction list and statistics.
 *
 * The component renders a list of transaction records, and a statistics chart
 * that displays the earnings for the selected range.
 *
 * The component uses the `useTransactionHooks` hook to fetch the transaction data,
 * and the `useBarChatHook` hook to group the transaction data by date.
 *
 * The component also uses the `useLocation` hook to determine which tab to render,
 * and the `useSelector` hook to fetch the user preference for the date range.
 *
 * The component renders a loading indicator if the transaction data is loading,
 * and a "no session" message if no transaction data is found.
 *
 * @returns {JSX.Element} The TransactionList component.
 */
function TransactionList() {
  const { ledgerData, ledgerDataLoading, creditData, creditDataLoading, payToHostData, payToHostLoading } = useTransactionHooks()
  const { groupByDates } = useBarChatHook()
  const { t } = useTranslation()
  const location = useLocation();
  const { transactionType } = useParams()
  const preference = useSelector(getPreference)
  const [finalData, setFinalData] = useState<any>()
  const [range, setRange] = useState<'thisWeek' | 'thisMonth'>('thisWeek')
  const [allTransactionData, setAllTransactionData] = useState<any>()
  const [hostCreditComponents,setHostCreditComponents] = useState<any>()
  const [hostCreditData, setHostCreditData] = useState<any>()

  const [creditedData, setCreditedData] = useState<number>()
  const [debitedData, setDebitedData] = useState<number>()
  



  useEffect(() => {
    if (ledgerData) {
      setFinalData(groupByDates(ledgerData, range))
    }
  }, [ledgerData, range])

  useEffect(() => {
    if(payToHostData){
      setHostCreditData(payToHostData)
    }
  }, [payToHostData])

  const data = {

    labels: finalData?.dates,
    datasets: [
      {
        label: 'Earning statistic',
        data: finalData?.credits,
        backgroundColor: '#FFC200',
      },
    ],
  };

  const selectedTab = {
    active: `bg-primary text-black py-1.5 flex flex-1 justify-center items-center rounded-lg`,
    inActive: `text-black flex flex-1 justify-center items-center text-gray-500`
  }


 useEffect(() => {
    if (ledgerData) {
      let creditLength = 0
      let debitLength = 0
      const TransComp = ledgerData?.map((transaction: Ledger) => {
        if (transaction) {
          if (transaction.transactionType === 'Debit' && transaction.transactionStatus === 'Completed') {
            debitLength++
            return (<DebitedCard key={'debitCard-' + transaction.id} transaction={transaction} />)
          } else if (transaction.transactionType === 'Credit' && (transaction.transactionStatus === TransactionStatus.Completed || transaction.transactionStatus === TransactionStatus.Processing || transaction.transactionStatus === TransactionStatus.Submitted)) {
            creditLength++
            return (<CreditedCard key={'creditCard-' + transaction.id} transaction={transaction} />)
          } else if (transaction.transactionType === 'Debit' && transaction.transactionStatus === 'Failed') {
            return (<FailedCard key={'failedCard-' + transaction.id} transaction={transaction} />)
          } else if (transaction.transactionType === 'Debit' && transaction.transactionStatus === 'Submitted') {
            return (<InProgressCard key={'inProgressCard-' + transaction.id} transaction={transaction} />)
          } 
          return <></>
        }
        return <></>
      })

      setAllTransactionData(TransComp)
      setCreditedData(creditLength)
      setDebitedData(debitLength)
    }
    if (hostCreditData) {
      const hostComp = hostCreditData.map((hostCreditDataa: any) => {
        if (hostCreditDataa) {
          return <HostCreditCard key={'hostCreditCard-' + hostCreditDataa.meeting_id} hostCreditData={hostCreditDataa} />;
        }
        return null;
      });
      setHostCreditComponents(hostComp); // Store the result in state
    }
  }, [ledgerData, creditedData, debitedData,hostCreditData])

  if (ledgerDataLoading && creditDataLoading) {
    return <Loading />
  }

  if (!ledgerData && !creditData && !ledgerDataLoading && !creditDataLoading) {
    return (
      <DelayDisplay delayTime={2000}>
        <NoSession type="transactions" />
      </DelayDisplay>
    )
  }

  return (
    <>
      <div className='flex md:flex-row w-full sm:flex-col sm:my-2 px-4 lg:px-[4%] mt-10 min-w-[65%]'>
        <div style={{ flex: 3 }} className='border-r-2 '>
          <div className='flex flex-row justify-between items-center '>
            <span id="title_transactions" className='text-xl md:text-2xl font-bold'>{t('transactionHistory')}</span>
          </div>
          <div className='flex justify-between rounded-lg bg-gray-200 mb-4 max-w-[20rem] text-sm font-normal text-black my-4'>
            <NavLink
              to={'/s/transaction-list'}
              className={`${location.pathname === '/s/transaction-list' ? selectedTab.active : selectedTab.inActive}`}><div id="btn-all" data-testid="btn-all" >{t('all')}</div></NavLink>
            <NavLink
              to={'/s/transaction-list/debit'}
              className={`${location.pathname === '/s/transaction-list/debit' ? selectedTab.active : selectedTab.inActive}`}><div id="btn_debit" data-testid="btn_debit">{t('debit')}</div></NavLink>
            <NavLink
              to={'/s/transaction-list/credit'}
              className={`${location.pathname === '/s/transaction-list/credit' ? selectedTab.active : selectedTab.inActive}`}><div id="btn_credit" data-testid="btn_credit">{t('credit')}</div ></NavLink>
          </div>
          <hr />
          <div className={'overflow-y-scroll h-[42rem] sm:pb-2'}>
          {ledgerData && <Accordion>{allTransactionData}</Accordion>}
          {hostCreditComponents && <Accordion>{hostCreditComponents}</Accordion>}
          {ledgerData && ledgerData.length === 0 && transactionType != 'credit' && transactionType != 'debit' && <NoSession type='transactions' />}
          {transactionType === 'credit' && !creditedData && <NoSession type='noCredit' />}
          {transactionType === 'debit' && !debitedData && <NoSession type='noDebit' />}
          </div>
        </div >
        <div style={{ flex: 1 }} className='ml-3 sm:border-t-2 sm:py-2 md:border-none min-w-[35%]'>
          <TotalEarningsCard preference={preference} creditData={creditData} />
          <TotalCreditedCard preference={preference} creditData={creditData} />
          <TotalDebitedCard preference={preference} creditData={creditData} />

          <div className='flex pt-4 px-4 flex-col'>
            <div className='flex flex-row justify-end items-center'>
              <select
                onChange={(e) => {
                  const selectedRange = e.target.value;
                  if (selectedRange === 'thisWeek') {
                    setRange('thisWeek');
                  } else if (selectedRange === 'thisMonth') {
                    setRange('thisMonth');
                  }
                }}
                id="statistic" name="statistic"
                className='relative w-1/2 rounded-lg p-input text-gray-700 md:text-base placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-8'
              >
                <option value="thisWeek">{t('thisWeek')}</option>

                <option value="thisMonth" >{t('thisMonth')}</option>
              </select>
            </div>
            <div className='h-4' />
            <Bar options={options} data={data} height={'200px'} />
          </div>
        </div>
      </div >
    </>
  )
}

export default TransactionList
