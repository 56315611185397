/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { Preference } from '../../models/preference.model'
import { useTranslation } from 'react-i18next'
import { useCurrencyHook } from '../../Hooks/CurrencyHooks'

interface _TotalEarningsCardProps {
    preference: Preference
    creditData: any
}

/**
 * A React functional component that displays a user's total earnings in a card.
 *
 * @param {Preference} preference - The user's preference data.
 * @param {any} creditData - The user's credit data, including their total earnings.
 * @return {JSX.Element} The JSX element representing the total earnings card.
 */
export default function TotalEarningsCard(props: _TotalEarningsCardProps) {
    const { preference, creditData } = props
    const { t } = useTranslation()
    const { formatCurrency } = useCurrencyHook();
    return (
      <div>
        <div
          className="flex  rounded-xl pl-12 p-8"
          style={{backgroundColor: '#E4F2B5'}}>
          <div className="flex flex-row items-center justify-between w-full gap-x-4">
            <div className="flex flex-col">
              <div className="text-base font-medium sm:flex">
                {t('YourTotalEarnings')}
              </div>
              <div className="text-2xl font-semibold">
                {formatCurrency(creditData?.totalEarning, preference.currency)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
