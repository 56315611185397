/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react/prop-types */

import * as React from 'react'

/**
 * A single slide in a Whirligig.
 *
 * @param {string} [basis=100%] - The flex basis of the slide.
 * @param {string} [gutter=1em] - The margin to the left of the slide.
 * @param {string} [className=''] - Additional CSS classnames to apply to the slide.
 * @param {*} children - The content of the slide.
 * @param {*} props - Additional props to pass to the slide's `div` element.
 *
 * @example
 * import Whirligig from '@himitsu/whirligig'
 *
 * const MyWhirligig = () => (
 *   <Whirligig style={{ width: '300px' }}>
 *     <Whirligig.Slide basis="50%">
 *       <img src="https://placekitten.com/g/300/200" alt="Kitty" />
 *     </Whirligig.Slide>
 *     <Whirligig.Slide basis="50%">
 *       <img src="https://placekitten.com/g/300/200" alt="Kitty" />
 *     </Whirligig.Slide>
 *   </Whirligig>
 * )
 */
const Slide = ({
  basis = '100%',
  gutter = '1em',
  className = '',
  children,
  ...props
}) => (
  <div
    className={className}
    style={{
      flex: '0 0 auto',
      width: basis,
      marginLeft: gutter,
    }}
    {...props}>
    {children}
  </div>
)

export default Slide
