/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { format, isEqual } from 'date-fns';
import { isSameSecond, isAfter, isBefore } from 'date-fns'

/**
 * Check if date `d1` is the same or before date `d2`
 * @param {Date} [d1=new Date()] - The first date to compare
 * @param {Date} [d2=new Date()] - The second date to compare
 * @returns {boolean} - True if `d1` is the same or before `d2`
 */
export const sameOrBefore = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : isBefore(d1, d2) ? true : false
}

/**
 * Check if date `d1` is the same or after date `d2`
 * @param {Date} [d1=new Date()] - The first date to compare
 * @param {Date} [d2=new Date()] - The second date to compare
 * @returns {boolean} - True if `d1` is the same or after `d2`
 */
export const sameOrAfter = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : isAfter(d1, d2) ? true : false
}
/**
 * Check if two date ranges overlap.
 *
 * @param {Date[]} [r1=[new Date(), new Date()]] - The first date range to compare
 * @param {Date[]} [r2=[new Date(), new Date()]] - The second date range to compare
 * @param {boolean} [equalCounts=true] - If true, the range is considered to overlap
 *   if the start date of the first range is the same as the start date of the second
 *   range, or if the end date of the first range is the same as the end date of the
 *   second range. If false, the range is considered to overlap if the start date of
 *   the first range is before the start date of the second range and the end date of
 *   the first range is after the end date of the second range.
 * @returns {boolean} - True if the two ranges overlap
 */
export const isRangeBetween = (
  [r1a, r1b] = [new Date(), new Date()],
  [r2a, r2b] = [new Date(), new Date()],
  equalCounts = true,
) => {
  return equalCounts
    ? sameOrBefore(r2a, r1a) && sameOrAfter(r2b, r1b)
    : isBefore(r2a, r1a) && isAfter(r2b, r1b)
}
/**
 * Check if `date` is between `from` and `to` (inclusive).
 * @param {Date} date - The date to check
 * @param {Date} from - The start of the range
 * @param {Date} to - The end of the range
 * @param {boolean} [isBeforeEqual=false] - If true, include `from` in the range
 * @param {boolean} [isAfterEqual=false] - If true, include `to` in the range
 * @returns {boolean} - True if `date` is between `from` and `to`
 */
export const isBetween = (date: Date, from: Date, to: Date, isBeforeEqual = false, isAfterEqual = false) => {
  return (isBeforeEqual ? (isEqual(from, date) || isBefore(from, date)) : isBefore(from, date)) &&
    (isAfterEqual ? (isEqual(to, date) || isAfter(to, date)) : isAfter(to, date));
};

/// converting to toISOString
export function getUTCDateTime(date: Date | string) {
  if (typeof date === 'string') {
    return new Date(date).toISOString();
  }

  return date.toISOString();
}
/**
 * Formats a date string to be used in getUTCDateTime.
 *
 * Given a date string in the format "HH:MM", formats it as "HH MM" and
 * appends it to "27 july 2022" so that the resulting string can be passed
 * to getUTCDateTime to get a UTC date string.
 *
 * If the date string is not provided, the current date is used.
 *
 * @param {string} [date] - The date string to format
 * @returs {string} - The formatted date string
 */
export function getUTCDateTimeFormatted(date?: string) {
  let formatDate = '';
  if (date) {
    formatDate = date.slice(0, 5) + ' ' + date.slice(6, 8);
  }

  const dateTime = new Date('27 july 2022 ' + formatDate);
  return dateTime.toUTCString().split(' ')[4];
}
/**
 * 
 * @param dateTimeUTC  example "2021-12-08T02:59:55.333Z"
 * @returns formattedDate Dec 08, 2021
 * 
 * https://github.com/prantlf/date-fns-timezone/blob/HEAD/docs/API.md#api-reference
 */
/**
 * Formats a date string from UTC to a timezone string in the format "MMM d, yyyy"
 * @param dateTimeUTC The date string in UTC format
 * @returns The formatted date string
 */
export function formatDateToTimezone(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "MMM d, yyyy";
  return format(new Date(dateTimeUTC), pattern);
}
/**
 * Formats a date string from UTC to a timezone string in the format "MMM d yyyy"
 * @param dateTimeUTC The date string in UTC format
 * @returns The formatted date string
 */
export function formatDateToTimezone2(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "MMM d yyyy";
  return format(new Date(dateTimeUTC), pattern);
}
/**
 * Formats a date string from UTC to a timezone string in the format "MMM d"
 * @param {string} [dateTimeUTC] - The date string in UTC format
 * @returns {string} - The formatted date string
 */
export function formatMonthDateToTimezone(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "MMM d";
  return format(new Date(dateTimeUTC), pattern);
}

/**
 * Formats a date string from UTC to a timezone string in the format "h:mm a"
 * @param {string} [dateTimeUTC] - The date string in UTC format
 * @returns {string} - The formatted time string
 */

export function formatTimeToTimezone(dateTimeUTC?: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "h:mm a"
  return format(new Date(dateTimeUTC), pattern);
}

/**
 * Formats a date string from UTC to a timezone string in the format "dd/MM/yyyy 'at' h:mm a"
 * @param {string} dateTimeUTC - The date string in UTC format
 * @returns {string} - The formatted date and time string
 */

export function formatDateTimeToTimezone(dateTimeUTC: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "dd/MM/yyyy 'at' h:mm a";
  return format(new Date(dateTimeUTC), pattern);
}

/**
 * 
 * @param dateTimeUTC  example "2021-12-08T02:59:55.333Z"
 * @param timezone  "Asia/Tokyo"
 * @returns formattedDateTime 08/12/21
 */

export function formatDateTimeToTimezoneMobile(dateTimeUTC: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "dd/MM/yy";
  return format(new Date(dateTimeUTC), pattern);
}
/**
 * Formats a date string from UTC to a timezone string in the format "HH:mm"
 * @param {string} dateTimeUTC - The date string in UTC format
 * @returns {string} - The formatted time string
 */
export function formatTimeToTimezoneMobile(dateTimeUTC: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "HH:mm";
  return format(new Date(dateTimeUTC), pattern);
}
/**
 * Formats a date string from UTC to a timezone string in the format "yyyy-MM-dd HH:mm:ss.SSS"
 * @param {string} dateTimeUTC - The date string in UTC format
 * @returns {string} - The formatted date and time string
 */
export function formatDateTimeFullToTimezone(dateTimeUTC: string) {
  if (!dateTimeUTC) {
    return '';
  }
  const pattern = "yyyy-MM-dd HH:mm:ss.SSS";
  return format(new Date(dateTimeUTC), pattern);
}
/**
 * Converts a number of seconds to a time object with days, hours, minutes and seconds
 * @param secs The number of seconds to convert
 * @returns An object with the keys days, hours, minutes and seconds
 */
export function secondsToTime(secs: number): { days: number, hours: number, minutes: number, seconds: number } {
  const days = Math.floor(secs / (3600 * 24))

  const divisor_for_hours = secs % (3600 * 24)
  const hours = Math.floor(divisor_for_hours / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  return {
    days,
    hours,
    minutes,
    seconds
  };
}
/**
 * Calculates the difference in minutes between two dates
 * @param startDateTime The start date time
 * @param endDateTime The end date time
 * @returns The difference in minutes
 */
export function getTimeDifference(startDateTime: any, endDateTime: any) {
  const timeDiff = new Date(endDateTime).getTime() - new Date(startDateTime).getTime()
  return Math.round(timeDiff / 60000)
}
