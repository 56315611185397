/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { Pagination } from "./paginationReact.model";

export enum Status {
    InActive,
    Active,
}

export interface CommonEntity extends Pagination {
    id: string;
    version: number;
    status: Status;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: string;
}