/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useRef, useEffect } from "react";
import { getMeetingChats } from "../../Services/livekitReducer";
import { getMessages } from "../../Store/Chat/chat";
import { useAppSelector } from "../../Store/hooks";

/**
 * AlwaysScrollToBottom
 *
 * A component that always scrolls to the bottom of its container.
 * It uses the `useRef` hook to create a reference to the bottom of the container,
 * and the `useEffect` hook to scroll to that reference when the component mounts,
 * and whenever the `chatMessages` or `liveKitChats` state changes.
 *
 * The component is also designed to handle the case where the container is
 * initially empty, by scrolling to the bottom after a short delay.
 *
 * @returns {JSX.Element} The JSX element that represents the component
 */
const AlwaysScrollToBottom = () => {
    const messagesEndRef = useRef(null) as any;
    const liveKitChats = useAppSelector(getMeetingChats)
    const chatMessages = useAppSelector(getMessages)

    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ block: "end" });
    };

    useEffect(() => {
        scrollToBottom();
        setTimeout(() => {
            scrollToBottom();
        }, 50);
        return () => {
            scrollToBottom();
            setTimeout(() => {
                scrollToBottom();
            }, 50);
        };
    }, [chatMessages, liveKitChats]);

    return <div ref={messagesEndRef} />;
};

export default AlwaysScrollToBottom;