/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import NoSession from "../Components/NoSession"
import { Accordion } from "../Components/base/accordion/accordion"
import Icon from "../Components/base/icon/icon"
import Loading from "../Components/base/loading/loading"
import { useAttendingHooks } from "../Hooks/AttendingHooks"
import { Accept } from "../models/meetingRequest.model"
import { EventInvitation } from "./AttendingEvents/AttendingEventsEventInvitation"
import { FreeEvent } from "./AttendingEvents/AttendingEventsFreeEvent"
import { ReceivedRequest } from "./AttendingEvents/AttendingEventsReceivedRequest"
import { RequestAccepted } from "./AttendingEvents/AttendingEventsRequestAccepted"
import { RequestPending } from "./AttendingEvents/AttendingEventsRequestPending"
import { Schedule } from "./AttendingEvents/AttendingEventsSchedule"
import { ScheduleWithGuests } from "./AttendingEvents/AttendingEventsScheduleWithGuests"
import { AttendedEvents } from "./AttendingEvents/AttendedPastEvents"
import { Menu, Transition } from '@headlessui/react'
import NewIcon from "../Components/base/icon/newIcons"
import FilterIcon  from '../Assets/NewIcons/beeMG-icons-filter-24.svg'

/**
 * A component that displays a list of events that the user is attending.
 *
 * It also displays a filter button to filter by latest or scheduled events.
 *
 * @returns A JSX element representing the list of events.
 */
function AttendingEvents() {

  const { t } = useTranslation()
  const [currentFilter, setCurrentFilter] = useState<string>()

  const { scheduledData, scheduledLoading, scheduledError, checkFilter, scheduledDataEvents, setScheduledDataEvents, attendedData } = useAttendingHooks()
  const style = {
    events: `block w-full mb-1 rounded-md text-base font-normal whitespace-nowrap  hover:bg-gray-200 cursor-pointer`,
    menu: `z-50 -mt-5 absolute flex-1 bg-white block w-[12.6rem] flex flex-col p-2 py-3 gap-1 rounded-lg border border-gray-200 `,
    sortItem: "block rounded-md px-2 py-1 w-full text-left text-base font-normal whitespace-nowrap  hover:bg-gray-200 cursor-pointer",
    item: `flex flex-row w-full flex-nowrap gap-1 text-left`,
  }

  useEffect(() => {
    if (scheduledData && !scheduledError) {
      setScheduledDataEvents(scheduledData);
    } else {
      setScheduledDataEvents([]);
    }
  }, [scheduledData, scheduledError]);

  if (scheduledLoading) {
    return <Loading />
  }

  if (scheduledDataEvents.length === 0) {
    return <NoSession type="events" className="h-[60vh] items-center justify-center" />
  }

  return (
    <div className="block lg:w-11/12 w-full px-3 mb-48 md:mb-36 lg:mb-28">
      <div className="flex items-center lg:pl-10 pl-2 pb-2 relative">
        {scheduledDataEvents.length > 0 &&
          <Menu>
            <div>
              <Menu.Button
                key={'attendingEvents'}
                className=" text-gray-400 font-normal flex items-center cursor-pointer">
                <p className="px-2 flex-1 absolute bottom-0 right-10">{t(currentFilter ?? '')}</p>
                <div id="dropMenu_lang_attendingEvents" data-testid='iconUpcomingFilter' className='absolute bottom-0 right-[20px] mb-6'>
                <img src={FilterIcon} className="w-6 h-6" alt="Filter Icon"/>
                </div>
              </Menu.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              ></Transition>
             <div className='absolute top-0 bottom-0 right-2'>
             <Menu.Items className={style.menu}>
                <Menu.Item>
                  <div
                    className={style.sortItem}
                    onClick={() => {
                      checkFilter('latest')
                      setCurrentFilter('latestEvents')
                    }}>
                    <span id="open_latestEvents" className={style.item}>
                      {currentFilter === 'latestEvents' && (
                        <Icon icon="TICK_GREEN" />
                      )}
                      {t('latestEvents')}
                    </span>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    className={style.sortItem}
                    onClick={() => {
                      checkFilter('scheduled')
                      setCurrentFilter('scheduledEvents')
                    }}>
                    <span id="open_scheduledEvents" className={style.item}>
                      {currentFilter === 'scheduledEvents' && (
                        <Icon icon="TICK_GREEN" />
                      )}
                      {t('scheduledEvents')}
                    </span>
                  </div>
                </Menu.Item>
              </Menu.Items>
              </div>
            </div>
          </Menu>}
      </div>
      <Accordion>
        {meetingComp(scheduledDataEvents)}
      </Accordion>
    </div>
  );
}

export default AttendingEvents


/**
 * Maps through an array of meetings and returns a React component based on the meeting status.
 * If the meeting is free and has no guests, it returns a FreeEvent component.
 * If the meeting is a scheduled event and has guests, it returns a ScheduleWithGuests component.
 * If the meeting is a scheduled event and has no guests, it returns a Schedule component.
 * If the meeting is a received request, it returns a ReceivedRequest component.
 * If the meeting is a send request and has not been accepted, it returns a RequestPending component.
 * If the meeting is a send request and has been accepted, it returns a RequestAccepted component.
 * If the meeting is an attended event, it returns an AttendedEvents component.
 * @param {any[]} events - An array of meeting objects.
 * @param {boolean} [pastEvents] - A boolean indicating if the meetings are past events.
 * @returns {React.ReactElement} A React component based on the meeting status.
 */
export const meetingComp = (events: any[], pastEvents?: boolean) => events?.map((meeting: any) => {

  if (
    meeting?.request === 'Scheduled events' &&
    meeting.type === 'Type 1' &&
    meeting?.meetingParticipant[0]?.inviteStatus === null &&
    (meeting?.meetingParticipant[0].transaction?.guestCount >= 1) === false &&
    meeting?.meetingDetails?.isFreeAudioMeeting === true && !pastEvents
  ) {
    return <FreeEvent key={meeting.id} meeting={meeting} />

  } else if (
    meeting?.request === 'Send requests' &&
    meeting?.accept === Accept.No && !pastEvents
  ) {
    return <RequestPending key={meeting.id} meeting={meeting} />

  } else if (
    meeting?.request === 'Send requests' &&
    meeting?.accept === Accept.Yes && !pastEvents
  ) {
    return <RequestAccepted key={meeting.id} meetingRequest={meeting} />

  } else if (meeting?.request === 'Received requests' && !pastEvents) {
    return <ReceivedRequest key={meeting.id} meetingRequest={meeting} />

  } else if (
    meeting?.request === 'Scheduled events' &&
    (meeting.type === 'Type 1' || 'Type 2') &&
    meeting?.meetingParticipant[0]?.inviteStatus === null &&
    meeting?.meetingParticipant[0]?.isCohost === false &&
    meeting?.meetingDetails?.isFreeAudioMeeting === false &&
    meeting?.meetingParticipant[0]?.transaction?.guestCount > 1 && !pastEvents

  ) {
    return <ScheduleWithGuests key={meeting.id} meeting={meeting} />
  } else if (
    meeting?.request === 'Scheduled events' &&
    (meeting.type === 'Type 1' || 'Type 2') &&
    meeting?.meetingParticipant[0]?.inviteStatus === null &&
    meeting?.meetingDetails?.isFreeAudioMeeting === false && !pastEvents

  ) {
    return <Schedule key={meeting.id} meeting={meeting} />
  } else if (
    meeting?.meetingParticipant[0]?.inviteStatus !== null && !pastEvents && !meeting.meetingParticipant[0].cancelationRequest 
  ) {
    return <EventInvitation key={meeting.id} meeting={meeting} />
  } else if (pastEvents) {
    return <AttendedEvents key={meeting.id} meeting={meeting} />
  }
  return <></>
})
