/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useGetPastScheduledMeetingsMeetingsDetailIdQuery} from '../../Services/meetingApi';

/**
 * Retrieves past scheduled meetings based on the provided meeting detail ID.
 *
 * @param {string} meetingDetailId - The ID of the meeting details to fetch past scheduled meetings for.
 * @return {{meetingSuccess: boolean, meetings: any, meetingError: any}} An object containing the success status, past scheduled meetings data, and any error that occurred.
 */

function usePastEventsHook(meetingDetailId?: string) {
  const {
    data: meetings,
    isSuccess: meetingSuccess,
    isError: meetingError,
  } = useGetPastScheduledMeetingsMeetingsDetailIdQuery(meetingDetailId ?? '', {
    skip: meetingDetailId === undefined,
  });

  return {meetingSuccess, meetings, meetingError};
}

export default usePastEventsHook;
