/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { useSignUpHook } from "../../Hooks/SignUp"
import { getCognitoObject, getIsSignUpTermsAccepted, getSignUpToken } from "../../Services/signUpReducer"
import { useCheckUserTermsPolicyQuery } from "../../Services/userPolicyAgreementsApi"
import { getCurrentUser } from "../../Services/userReducer"
import { useAppSelector } from "../../Store/hooks"
import { User, Verified } from "../../models/user.model"

  /**
   * This hook is used to manage the routing of the application.
   * When a user logs in, the hook checks if the user has accepted the terms and conditions,
   * if the user has verified their email, if the user has verified their phone,
   * if the user has completed their face verification, and if the user has completed their profile.
   * If any of these conditions are not met, the user is redirected to the appropriate page.
   * If the user is not logged in, the hook checks if the user has an invite token,
   * and if the user has accepted the terms and conditions. If the user has an invite token,
   * the user is redirected to the sign up page. If the user has not accepted the terms and conditions,
   * the user is redirected to the terms and conditions page.
   * If the user does not have an invite token, the user is redirected to the sign in page.
   * @return {object} An empty object.
   */
export const useAppRouting = () => {

  const currentUser: User = useSelector(getCurrentUser)
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const token = useAppSelector(getSignUpToken);
  const termsStatus = useAppSelector(getIsSignUpTermsAccepted);
  const cognitoModel = useAppSelector(getCognitoObject) ?? JSON.parse(localStorage.getItem('SIGNIN_HELPER_COGNITOMODEL') as any);
  const { data: policyAccepted } = useCheckUserTermsPolicyQuery();
  // const { invite } = useSignUpHook(token);

  useEffect(() => {
    if (currentUser) {    
      if (!currentUser.isUserTermsPolicyStatus) {  
        if (pathname !== '/s/termsAndConditions') {    
          navigate('/s/termsAndConditions')
          return;
        }
      }

      if (currentUser.emailVerified === Verified.NotComplete) {
        if (pathname !== '/verifyEmail') {
         navigate('/verifyEmail')
          return
        }
      } else if ( ((currentUser.phoneVerified === Verified.Complete && currentUser.emailVerified === Verified.Complete) && (pathname === '/verifyEmail')) || ((currentUser.emailVerified === Verified.Complete && currentUser.phoneVerified === Verified.Complete) && (pathname === '/verifyPhone'))) {
        navigate('/s/home')
        return
      }
      else if (currentUser.phoneVerified === Verified.NotComplete && pathname !== '/verifyPhone') {
        navigate('/verifyPhone')
        return
      }
      else if (currentUser.faceVerified === Verified.NotComplete && pathname !== '/s/faceVerify' && currentUser.phoneVerified === Verified.Complete) {
        navigate('/s/faceVerify')
        return
      }
      else if (currentUser.userCategory?.length === 0 && currentUser.faceVerified === Verified.Complete) {
        if (pathname !== '/s/chooseInterests') {
          navigate('/s/chooseInterests')
          return
        }
      }
      
    } else if (!policyAccepted && token && !termsStatus ) {
      if (pathname !== '/termsAndConditions') {
        navigate('/termsAndConditions')
        return;
      }
    } else if (token && termsStatus && !cognitoModel) {
      if (!pathname.startsWith('/signUp/') && pathname !== '/signIn') {
        navigate(`/signUp/${token}`)
        return
      }

    } else if (cognitoModel) {
      if (pathname !== '/verifyPhone') {
        navigate('/verifyPhone')
        return

      }
    }

  }, [currentUser, token, termsStatus, navigate, cognitoModel])

  return {}
}
