/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {isAfter} from 'date-fns';
import {useEffect, useState} from 'react';
import {
  useGetAllCreditsQuery,
  useGetAllTransactionsQuery,
  useGetPayToHostQuery,
} from '../Services/ledgerApi';
import {meetingParticipantApi} from '../Services/meetingParticipantApi';
import {useAppDispatch} from '../Store/hooks';

/**
 * Custom React hook for managing transaction-related data and functionality.
 *
 * @return {object} An object containing transaction data, loading states, and functions for handling guest data.
 */

export const useTransactionHooks = () => {
  const {
    data: ledgerData,
    isLoading: ledgerDataLoading,
    isFetching: ledgerDataFetching,
    refetch: ledgerDataRefetch,
  } = useGetAllTransactionsQuery();
  const dispatch = useAppDispatch();

  const {data: creditData, isLoading: creditDataLoading} =
    useGetAllCreditsQuery();

  const {data: payToHostData, isLoading: payToHostLoading} = useGetPayToHostQuery();

  console.log("payToHostData", payToHostData)

  const [invitedguest, setinvitedguest] = useState<any>();
  const [viewGuest, setViewGuest] = useState<boolean>(false);

  const handleGuests = (id: string) => {
    setViewGuest(!viewGuest);
    const res = dispatch(
      meetingParticipantApi.endpoints.getAllInvitedGuests.initiate(id),
    );

    res.then(val => {
      const InviteData = val.data;
      setinvitedguest(InviteData);
    });
  };

  return {
    ledgerData,
    ledgerDataLoading,
    invitedguest,
    viewGuest,
    handleGuests,
    ledgerDataFetching,
    ledgerDataRefetch,
    creditData,
    creditDataLoading,
    payToHostData,
    payToHostLoading,
  };
};

/**
 * Custom React hook for managing transaction check-related data and functionality.
 *
 * @param {any} transaction - The transaction object containing relevant data.
 * @param {any} [invitedguest] - Optional invited guest data.
 * @return {object} An object containing various transaction check details, including credit information, invite paid guest status, amount paid, meeting type refund, and view guests status.
 */

export const useTransactionCheckHooks = (
  transaction: any,
  invitedguest?: any,
) => {
  const [creditDetail, setCreditDetail] = useState<boolean>(false);
  const [creditCostDetail, setCreditCostDetail] = useState<String>();
  const [creditCost, setCreditCost] = useState<String>();
  const [invitePaidGuest, setInvitePaidGuest] = useState<boolean>(false);
  const [amountPaid, setAmountPaid] = useState<String>();
  const [meetingTypeRefund, setMeetingTypeRefund] = useState<
    'TYPE 1' | 'TYPE 2'
  >();
  const [viewGuests, setViewGuests] = useState<'VIEW' | 'HIDE'>();

  useEffect(() => {
    if (
      transaction?.meeting?.meetingDetails?.cancellationPolicy
        ?.deductionPercentage !== '0' &&
      transaction.isDeduction === 1
    ) {
      setCreditDetail(true);
    }

    if (creditDetail) {
      setCreditCostDetail(
        (
          (transaction?.meeting?.meetingDetails?.cancellationPolicy
            ?.deductionPercentage /
            100) *
          (transaction.originalTransactionAmount /
            transaction.previousTransactions.originalGuestCount)
        ).toFixed(2),
      );
    }

    if (creditDetail) {
      setCreditCost(
        (
          transaction.originalTransactionAmount /
            transaction.previousTransactions.originalGuestCount -
          (transaction?.meeting?.meetingDetails?.cancellationPolicy
            ?.deductionPercentage /
            100) *
            (transaction.originalTransactionAmount /
              transaction.previousTransactions.originalGuestCount)
        ).toFixed(2),
      );
    }

    if (
      transaction?.guestCount > 1 &&
      invitedguest?.length < transaction?.guestCount - 1
    ) {
      setInvitePaidGuest(true);
    }

    if (creditDetail) {
      setAmountPaid(
        (
          transaction.originalTransactionAmount /
          transaction.previousTransactions.originalGuestCount
        ).toFixed(2),
      );
    }

    if (
      transaction?.transactionType === 'Credit' &&
      transaction?.meeting?.type === 'Type 1' &&
      transaction?.transactionDescription === 'Refund for cancellation'
    ) {
      setMeetingTypeRefund('TYPE 1');
    } else if (
      transaction?.transactionType === 'Credit' &&
      transaction?.meeting?.type === 'Type 2' &&
      transaction?.transactionDescription === 'Refund for cancellation'
    ) {
      setMeetingTypeRefund('TYPE 2');
    }

    if (
      transaction.guestCount > 1 &&
      isAfter(new Date(transaction.meeting.fromDateTime), new Date()) === false
    ) {
      setViewGuests('HIDE');
    } else if (
      transaction.guestCount > 1 &&
      isAfter(new Date(transaction.meeting.fromDateTime), new Date()) === true
    ) {
      setViewGuests('VIEW');
    }
  }, [transaction, creditDetail, invitedguest]);

  return {
    creditDetail,
    creditCostDetail,
    creditCost,
    invitePaidGuest,
    amountPaid,
    meetingTypeRefund,
    viewGuests,
  };
};
