/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { CommonEntity } from './commonEntity.model';
import { Ledger } from './ledger.model';
import { Meeting } from './meeting.model';
import { MeetingParticipant } from './meetingParticipant.model';
import { User } from './user.model';

export enum Processed {
    Yes = 'Yes',
    No = 'No'
}
  
export enum RefundType {
    Full = 'Full',
    Partial = 'Partial'
}

export interface CancellationRequest extends CommonEntity {
    ledger: Ledger;
    user: User;
    meeting: Meeting;
    processed: Processed;
    refundType: RefundType;
    refundLedger: Ledger;
    meetingParticipant: MeetingParticipant;
    cancellationReason?: string;
    chosenCancellationPercent: number;
}