/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable prettier/prettier */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "camera": "カメラ",
  "cameraOn": "カメラオン",
  "cameraOff": "カメラオフ",
  "characters": "キャラクター",
  "close": "近い",
  "coHost": "共同ホスト",
  "doYouNeedToEndTheMeeting": "会議を終了する必要がありますか？",
  "doYouWantToLeaveTheMeeting": "会議から退出しますか?",
  "end": "終わり",
  "groupChat": "グループチャット",
  "gridView": "グリッドビュー",
  "handRaise": "挙手",
  "host": "ホスト",
  "hostGrantedPermissionToTalk": "ホストは話す許可を与えました",
  "hostRemovedPermissionToTalk": "ホストが通話許可を削除しました",
  "hostView": "ホストビュー",
  "language": "言語",
  "leave": "離れる",
  "meetingWillEndIn": "会議は に終了します",
  "message": "メッセージ",
  "messageLengthExceeds": "メッセージの長さが超過しています",
  "mic": "マイク",
  "microphoneOn": "マイクオン",
  "microphoneOff": "マイクオフ",
  "minutes": "分",
  "multiSessionDoYouWantToLeave": "マルチセッション。帰りたい？",
  "muteAll": "すべてミュート",
  "noResultsFound": "結果が見つかりません",
  "others": "その他",
  "participants": "参加者",
  "pin": "ピン",
  "pip": "ピップ",
  "privateChat": "プライベートチャット",
  "raisedTheirHand": "手を挙げた",
  "screenShare": "画面共有",
  "screenShareOn": "画面共有オン",
  "screenShareOff": "画面共有オフ",
  "searchParticipants": "参加者を検索する",
  "send": "送信",
  "settings": "設定",
  "staged": "段階的",
  "toSpeakRaiseYourHandAndWaitForTheHostToUnmuteYou": "話す場合は手を挙げて、主催者がミュートを解除するのを待ちます",
  "toShareYourScreenKindlyRequestTheHostToUnmuteYou": "画面を共有するには、ホストにミュート解除を依頼してください。",
  "typeAMessage": "メッセージを入力してください",
  "unmute": "ミュートを解除する",
  "unmuteAll": "すべてのミュートを解除",
  "unpin": "ピン留めを外す",
}
